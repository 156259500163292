// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ChangeBfCustAccountEmailSubmit$Thick = require("./mutations/ChangeBfCustAccountEmailSubmit.bs.js");

function ChangeEmailForm(props) {
  var fullname = props.fullname;
  var onClose = props.onClose;
  var match = ChangeBfCustAccountEmailSubmit$Thick.useMutation(props.refresh, onClose, props._accountId);
  var onSubmit = match.onSubmit;
  var setError = match.setError;
  var error = match.error;
  var setEmail = match.setEmail;
  var email = match.email;
  var m = CS_Emails$Util.Email.fromPrimitive(email);
  var tmp;
  tmp = m.TAG === "Ok" ? null : m._0;
  var match$1 = Belt_Result.isOk(CS_Emails$Util.Email.fromPrimitive(email));
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: "Change Account Email Address: " + fullname,
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsxs(Typography, {
                                        textColor: "text.tertiary",
                                        children: [
                                          "Change this email address for " + fullname + "'s account, sending standard verification notifications to the new and old addresses.",
                                          JsxRuntime.jsx("br", {}),
                                          JsxRuntime.jsx("br", {}),
                                          "Old email: " + props.oldEmail
                                        ]
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        component: "h3",
                                        children: "User Information"
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        component: "form",
                                        spacing: 1,
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(CS_Emails$Util.Email.fromPrimitive(email)),
                                                children: [
                                                  JsxRuntime.jsx(FormLabel, {
                                                        children: "New Email Address"
                                                      }),
                                                  JsxRuntime.jsx(Joy$Util.Input.make, {
                                                        type_: "email",
                                                        required: true,
                                                        value: email,
                                                        onChange: (function (e) {
                                                            var val = e.currentTarget.value;
                                                            setEmail(function (param) {
                                                                  return val;
                                                                });
                                                          })
                                                      }),
                                                  JsxRuntime.jsx(FormHelperText, {
                                                        children: Caml_option.some(tmp)
                                                      })
                                                ]
                                              }),
                                          error !== undefined ? JsxRuntime.jsx(Typography, {
                                                  color: "danger",
                                                  children: Caml_option.some(error)
                                                }) : null
                                        ]
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match.isMutating,
                                          type: "submit",
                                          disabled: match$1 ? false : true,
                                          children: "Change Email Address"
                                        }))
                              }))
                    })
              ]
            });
}

var make = ChangeEmailForm;

exports.make = make;
/* Joy-Util Not a pure module */
