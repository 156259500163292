// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick = require("../../../__generated__/ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql.bs.js");

var convertVariables = ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ChangeBfCustAccountEmailMutation_changeBfcustEmailErr_decode = ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.Utils.changeBfcustEmailErr_decode;

var ChangeBfCustAccountEmailMutation_changeBfcustEmailErr_fromString = ChangeBfCustAccountEmailSubmit_ChangeBfCustAccountEmailMutation_graphql$Thick.Utils.changeBfcustEmailErr_fromString;

var ChangeBfCustAccountEmailMutation = {
  changeBfcustEmailErr_decode: ChangeBfCustAccountEmailMutation_changeBfcustEmailErr_decode,
  changeBfcustEmailErr_fromString: ChangeBfCustAccountEmailMutation_changeBfcustEmailErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh, onClose, _accountId) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return "";
      });
  var email = match$2[0];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var _newEmail = CS_Emails$Util.Email.fromPrimitive(email);
    if (_newEmail.TAG === "Ok") {
      mutate({
            input: {
              _accountId: _accountId,
              _newEmail: _newEmail._0
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err === undefined) {
                var match = resp.changeBfcustAccountEmail;
                if (match === undefined) {
                  return setError(function (param) {
                              return "Something went wrong, please try again.";
                            });
                }
                var changeBfcustEmailErr = match.changeBfcustEmailErr;
                if (changeBfcustEmailErr !== undefined) {
                  var err$1;
                  if (changeBfcustEmailErr === "UNAUTHORIZED" || changeBfcustEmailErr === "EMAIL_ALREADY_IN_USE" || changeBfcustEmailErr === "NO_VALID_ACCOUNT_TARGET") {
                    switch (changeBfcustEmailErr) {
                      case "EMAIL_ALREADY_IN_USE" :
                          err$1 = "Email already in use";
                          break;
                      case "UNAUTHORIZED" :
                          err$1 = "You are not authorized to perform this action";
                          break;
                      case "NO_VALID_ACCOUNT_TARGET" :
                          err$1 = "Couldn't find such a user to modify";
                          break;
                      
                    }
                  } else {
                    err$1 = "Something went wrong, please try again.";
                  }
                  setError(function (param) {
                        return err$1;
                      });
                  return ;
                }
                console.log("Success");
                refresh();
                onClose();
                return ;
              }
              setError(function (param) {
                    return "Something went wrong, please try again.";
                  });
            }), (function (err) {
              console.log(err);
              setError(function (param) {
                    return "Something went wrong, please try again.";
                  });
            }), undefined);
      return ;
    }
    setError(function (param) {
          return "Please enter a valid email address.";
        });
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$2[1],
          error: match$1[0],
          setError: setError,
          onSubmit: onSubmit
        };
}

exports.ChangeBfCustAccountEmailMutation = ChangeBfCustAccountEmailMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
