// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick = require("../../../__generated__/ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql.bs.js");

var convertVariables = ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ChangeCabinNotesSubmit_ChangeCabinNotesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ChangeCabinNotesMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, onVoyage, forCabinNumbers, refetch, setSelectedCabins, onClose, setError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError$1 = match$1[1];
  var changeCabinNotes = function (param) {
    mutate({
          input: {
            onBrandFamily: onBrandFamily,
            onVoyage: onVoyage,
            forCabinNumbers: forCabinNumbers,
            maybeNewReservationNote: param.maybeNewReservationNote,
            maybeNewConfigurationNote: param.maybeNewConfigurationNote
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.changeCabinNotes;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully changed cabin notes");
                  refetch();
                  onClose();
                  setSelectedCabins(function (param) {
                        return Belt_Set.make(Common$Thick.CabinCmp);
                      });
                  setError$1(function (param) {
                        
                      });
                  return ;
                }
                
              }
              console.log("Failed to change cabin notes");
              setError$1(function (param) {
                    return "Failed to change cabin notes";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError$1(function (param) {
                    return "Error changing cabin notes";
                  });
              return ;
            }
            console.log("Unknown error changing cabin notes");
          }), (function (err) {
            console.log(err);
            setError$1(function (param) {
                  return "Error changing cabin notes";
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError$1,
          isMutating: match[1],
          changeCabinNotes: changeCabinNotes
        };
}

function notesIntermToCabinNotes(i) {
  var str = i.noteReservation;
  var str$1 = i.noteConfig;
  return {
          TAG: "Ok",
          _0: {
            maybeNewReservationNote: str !== undefined ? Belt_Result.mapWithDefault(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(str), undefined, (function (v) {
                      return Caml_option.some(v);
                    })) : undefined,
            maybeNewConfigurationNote: str$1 !== undefined ? Belt_Result.mapWithDefault(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(str$1), undefined, (function (v) {
                      return Caml_option.some(v);
                    })) : undefined
          }
        };
}

var Utils_empty = {
  noteReservation: undefined,
  noteConfig: undefined
};

var Utils = {
  empty: Utils_empty,
  notesIntermToCabinNotes: notesIntermToCabinNotes
};

exports.ChangeCabinNotesMutation = ChangeCabinNotesMutation;
exports.useMutation = useMutation;
exports.Utils = Utils;
/* commitMutation Not a pure module */
