// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var $$Option = require("@mui/joy/Option").default;
var Switch = require("@mui/joy/Switch").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CountryInput$Thick = require("../common/molecules/CountryInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;

function BSPInputs$Impl(props) {
  var __tooltip = props.tooltip;
  var __noErrors = props.noErrors;
  var overrideErrorText = props.overrideErrorText;
  var width = props.width;
  var __withLabels = props.withLabels;
  var children = props.children;
  var __disabled = props.disabled;
  var __required = props.required;
  var helperText = props.helperText;
  var label = props.label;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var withLabels = __withLabels !== undefined ? __withLabels : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsx(HopperState$Util.Observable.FormField.Component.WithMapping.make, {
              view: props.view,
              input: props.input,
              map: props.map,
              children: (function (cargs) {
                  var result = cargs.result;
                  var wasTouched = cargs.wasTouched;
                  var match;
                  match = result.TAG === "Ok" ? [
                      false,
                      undefined
                    ] : (
                      overrideErrorText !== undefined ? (
                          !disabled && wasTouched ? [
                              true,
                              overrideErrorText
                            ] : [
                              false,
                              undefined
                            ]
                        ) : (
                          !disabled && wasTouched ? [
                              true,
                              result._0
                            ] : [
                              false,
                              undefined
                            ]
                        )
                    );
                  var error = match[0];
                  var helperText$1 = error ? undefined : helperText;
                  var errorText = error ? match[1] : undefined;
                  return JsxRuntime.jsxs(FormControl, {
                              error: error && !noErrors && wasTouched && !disabled && Belt_Option.isSome(errorText),
                              required: required,
                              children: [
                                JsxRuntime.jsx(FormLabel, {
                                      sx: {
                                        display: withLabels ? "flex" : "none"
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                endDecorator: Caml_option.some(tooltip),
                                                level: "inherit",
                                                children: Caml_option.some(label)
                                              }))
                                    }),
                                children(cargs),
                                withLabels ? Belt_Option.mapWithDefault(helperText$1, null, (function (helperText) {
                                          return JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                children: Caml_option.some(helperText)
                                                              })),
                                                      sx: {
                                                        width: Belt_Option.getWithDefault(width, "auto")
                                                      }
                                                    });
                                        })) : null,
                                withLabels ? Belt_Option.mapWithDefault(errorText, null, (function (helperText) {
                                          return JsxRuntime.jsx(FormHelperText, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-sm",
                                                                sx: {
                                                                  color: "red",
                                                                  width: Belt_Option.getWithDefault(width, "auto")
                                                                },
                                                                children: Caml_option.some(helperText)
                                                              }))
                                                    });
                                        })) : null
                              ]
                            });
                })
            });
}

var Impl = {
  make: BSPInputs$Impl
};

function BSPInputs$Bool(props) {
  var __withLabels = props.withLabels;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var size = __size !== undefined ? __size : "lg";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var withLabels = __withLabels !== undefined ? __withLabels : true;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Switch, {
                              checked: param.value,
                              size: size,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.checked;
                                      });
                                }),
                              sx: {
                                alignSelf: "flex-start"
                              }
                            });
                }),
              withLabels: withLabels
            });
}

var Bool = {
  make: BSPInputs$Bool
};

function BSPInputs$Select(props) {
  var __noErrors = props.noErrors;
  var afterSelect = props.afterSelect;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __size = props.size;
  var options = props.options;
  var placeholder = props.placeholder;
  var size = __size !== undefined ? __size : "md";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  var keyToPrimitive = function (search) {
                    return Belt_Array.get(Belt_Array.keepMap(options, (function (param) {
                                      if (search === param.key) {
                                        return Caml_option.some(param.primitive);
                                      }
                                      
                                    })), 0);
                  };
                  var primitiveToKey = function (search) {
                    return Belt_Array.get(Belt_Array.keepMap(options, (function (param) {
                                      if (Caml_obj.equal(search, param.primitive)) {
                                        return param.key;
                                      }
                                      
                                    })), 0);
                  };
                  return JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                              children: Caml_option.some(Belt_Array.map(options, (function (param) {
                                          var key = param.key;
                                          return JsxRuntime.jsx($$Option, {
                                                      value: key,
                                                      children: Caml_option.some(param.niceName)
                                                    }, key);
                                        }))),
                              disabled: disabled,
                              onChange: (function (param, val) {
                                  if (val === undefined) {
                                    return ;
                                  }
                                  var v = Caml_option.valFromOption(val);
                                  if (v !== undefined) {
                                    Belt_Option.mapWithDefault(keyToPrimitive(v), undefined, (function (v) {
                                            set(function (param) {
                                                  return v;
                                                });
                                          }));
                                    Belt_Option.map(afterSelect, (function (f) {
                                            return f();
                                          }));
                                    return ;
                                  }
                                  
                                }),
                              placeholder: placeholder,
                              size: size,
                              slotProps: {
                                listbox: {
                                  sx: {
                                    zIndex: "modal"
                                  }
                                }
                              },
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: primitiveToKey(param.value)
                            });
                }),
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors
            });
}

var Select = {
  make: BSPInputs$Select
};

function BSPInputs$Date(props) {
  var __noErrors = props.noErrors;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __variant = props.variant;
  var placeholder = props.placeholder;
  var variant = __variant !== undefined ? __variant : "outlined";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a.trim() === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  var value = param.value;
                  var match = React.useState(function () {
                        return false;
                      });
                  var setDateFocused = match[1];
                  var tmp;
                  tmp = match[0] || value !== "" ? "date" : "text";
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: tmp,
                              placeholder: placeholder,
                              variant: variant,
                              value: value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                }),
                              onFocus: (function (param) {
                                  setDateFocused(function (param) {
                                        return true;
                                      });
                                }),
                              onBlur: (function (param) {
                                  setDateFocused(function (param) {
                                        return false;
                                      });
                                })
                            });
                }),
              noErrors: noErrors
            });
}

var $$Date = {
  make: BSPInputs$Date
};

function BSPInputs$Country(props) {
  var __noErrors = props.noErrors;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var __variant = props.variant;
  var __size = props.size;
  var placeholder = props.placeholder;
  var countryFragment = props.countryFragment;
  var size = __size !== undefined ? __size : "md";
  var variant = __variant !== undefined ? __variant : "outlined";
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(CountryInput$Thick.make, {
                              countryFragment: countryFragment,
                              variant: variant,
                              placeholder: placeholder,
                              size: size,
                              value: param.value,
                              onChange: (function (param, val) {
                                  set(function (param) {
                                        if (val === undefined) {
                                          return ;
                                        }
                                        var v = Caml_option.valFromOption(val);
                                        if (v !== undefined) {
                                          return Caml_option.some(Caml_option.valFromOption(v));
                                        }
                                        
                                      });
                                })
                            });
                }),
              noErrors: noErrors
            });
}

var Country = {
  make: BSPInputs$Country
};

function BSPInputs$FormControlMod$HelperText(props) {
  var wasTouched = props.wasTouched;
  var errorText = props.errorText;
  var helperText = props.helperText;
  if (props.restrictedEdit) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          children: "Cannot update"
                        }))
              });
  }
  var exit = 0;
  if (helperText !== undefined) {
    exit = 2;
  } else {
    if (errorText === undefined) {
      return null;
    }
    exit = 2;
  }
  if (exit === 2 && errorText !== undefined && wasTouched) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          sx: {
                            color: props.error ? "red" : "inherit"
                          },
                          children: Caml_option.some(errorText)
                        }))
              });
  }
  if (helperText !== undefined) {
    return JsxRuntime.jsx(FormHelperText, {
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          children: Caml_option.some(helperText)
                        }))
              });
  } else {
    return null;
  }
}

var HelperText = {
  make: BSPInputs$FormControlMod$HelperText
};

function BSPInputs$FormControlMod(props) {
  var __tooltip = props.tooltip;
  var wasTouched = props.wasTouched;
  var __restrictedEdit = props.restrictedEdit;
  var __required = props.required;
  var __disabled = props.disabled;
  var error = props.error;
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var restrictedEdit = __restrictedEdit !== undefined ? __restrictedEdit : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsxs(FormControl, {
              error: wasTouched && error && !disabled,
              required: required,
              children: [
                JsxRuntime.jsx(FormLabel, {
                      required: required,
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                endDecorator: Caml_option.some(tooltip),
                                level: "inherit",
                                children: Caml_option.some(props.label)
                              }))
                    }),
                props.children,
                JsxRuntime.jsx(BSPInputs$FormControlMod$HelperText, {
                      helperText: props.helperText,
                      errorText: props.errorText,
                      wasTouched: wasTouched,
                      error: error,
                      restrictedEdit: restrictedEdit
                    })
              ]
            });
}

var FormControlMod = {
  HelperText: HelperText,
  make: BSPInputs$FormControlMod
};

function BSPInputs$PhoneInput(props) {
  var __required = props.required;
  var __disabled = props.disabled;
  var view = props.view;
  var disabled = __disabled !== undefined ? __disabled : false;
  var required = __required !== undefined ? __required : false;
  var match = HopperState$Util.Observable.FormField.use(view, props.phone, undefined);
  var setPhoneNumber = match[1];
  var match$1 = match[0];
  var wasTouched = match$1.wasTouched;
  var match$2 = HopperState$Util.Observable.FormField.use(view, props.phoneCountryCode, undefined);
  var setCountryCode = match$2[1];
  var match$3 = HopperState$Util.Observable.useComputed(view, props.validatePhone, undefined, undefined);
  var error;
  error = match$3.TAG === "Ok" ? false : true;
  return JsxRuntime.jsx(BSPInputs$FormControlMod, {
              label: props.label,
              helperText: props.helperText,
              error: error,
              children: JsxRuntime.jsx(CountryInput$Thick.PhoneInput.make, {
                    countryFragment: props.countryFragment,
                    countryCode: match$2[0].value,
                    phoneNumber: match$1.value,
                    onUpdateCountryCode: (function (cc) {
                        setCountryCode(function (param) {
                              return cc;
                            });
                      }),
                    onUpdatePhoneNumber: (function (ph) {
                        setPhoneNumber(function (param) {
                              return ph;
                            });
                      }),
                    disabled: disabled,
                    error: error && wasTouched,
                    required: required
                  }),
              required: required,
              wasTouched: wasTouched
            });
}

var PhoneInput = {
  make: BSPInputs$PhoneInput
};

function BSPInputs$Text(props) {
  var __tooltip = props.tooltip;
  var __noErrors = props.noErrors;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var tooltip = __tooltip !== undefined ? Caml_option.valFromOption(__tooltip) : null;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "text",
                              fullWidth: fullWidth,
                              disabled: disabled,
                              required: required,
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                })
                            });
                }),
              width: width,
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors,
              tooltip: Caml_option.some(tooltip)
            });
}

function BSPInputs$Text$Optional(props) {
  var __noErrors = props.noErrors;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  var set = param.set;
                  return JsxRuntime.jsx(Joy$Util.Input.make, {
                              type_: "text",
                              fullWidth: fullWidth,
                              disabled: disabled,
                              required: required,
                              sx: {
                                width: Belt_Option.getWithDefault(width, "auto")
                              },
                              value: param.value,
                              onChange: (function (e) {
                                  set(function (param) {
                                        return e.currentTarget.value;
                                      });
                                })
                            });
                }),
              width: width,
              overrideErrorText: props.overrideErrorText,
              noErrors: noErrors
            });
}

var Optional = {
  make: BSPInputs$Text$Optional
};

var $$Text = {
  make: BSPInputs$Text,
  Optional: Optional
};

function BSPInputs$SelectSearch$SSImpl(props) {
  var search = props.search;
  var clearOptions = props.clearOptions;
  var setLoading = props.setLoading;
  var options = props.options;
  var set = props.set;
  var value = props.value;
  var match = React.useState(function () {
        return false;
      });
  var setListboxOpen = match[1];
  var options$1;
  options$1 = options.TAG === "Optional" ? Belt_Array.map(options._0, (function (v) {
            return {
                    primitive: Belt_Option.getWithDefault(v.primitive, ""),
                    key: v.key,
                    niceName: v.niceName
                  };
          })) : options._0;
  var idArray = Belt_Array.map(options$1, (function (param) {
          return param.key;
        }));
  var getOptionLabel = function (str) {
    return Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keepMap(options$1, (function (param) {
                          if (str === param.key) {
                            return param.niceName;
                          }
                          
                        })), 0), "");
  };
  var keyToPrimitive = function (value) {
    return Belt_Array.get(Belt_Array.keepMap(options$1, (function (param) {
                      if (value === param.key) {
                        return param.primitive;
                      }
                      
                    })), 0);
  };
  return JsxRuntime.jsx(Joy$Util.JoyAuto.Single.make, {
              options: idArray,
              disabled: props.disabled,
              getOptionLabel: getOptionLabel,
              loading: props.loading,
              onChange: (function (param, val) {
                  var match = Belt_Option.map(val, keyToPrimitive);
                  if (match === undefined) {
                    return ;
                  }
                  var v = Caml_option.valFromOption(match);
                  if (v !== undefined) {
                    set(function (param) {
                          return v;
                        });
                    setListboxOpen(function (param) {
                          return false;
                        });
                    return ;
                  }
                  
                }),
              onClose: (function () {
                  setListboxOpen(function (param) {
                        return false;
                      });
                }),
              onInputChange: (function (e, v, param) {
                  var isTypeEvent = (e == null) ? false : e.nativeEvent.type === "input";
                  if (!isTypeEvent) {
                    return ;
                  }
                  if (v !== "") {
                    if (getOptionLabel(value) !== v) {
                      search(v, (function () {
                              setListboxOpen(function (param) {
                                    return false;
                                  });
                            }), (function () {
                              setListboxOpen(function (param) {
                                    return true;
                                  });
                            }));
                      return ;
                    } else {
                      return ;
                    }
                  }
                  clearOptions();
                  setListboxOpen(function (param) {
                        return false;
                      });
                  setLoading(function (param) {
                        return false;
                      });
                }),
              onOpen: (function () {
                  setListboxOpen(function (param) {
                        return true;
                      });
                }),
              open: match[0],
              placeholder: props.placeholder,
              slotProps: {
                listbox: {
                  sx: {
                    zIndex: "modal"
                  }
                }
              },
              sx: {
                width: Belt_Option.getWithDefault(props.width, "auto")
              },
              value: value
            });
}

var SSImpl = {
  make: BSPInputs$SelectSearch$SSImpl
};

function BSPInputs$SelectSearch(props) {
  var search = props.search;
  var clearOptions = props.clearOptions;
  var options = props.options;
  var setLoading = props.setLoading;
  var __loading = props.loading;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var placeholder = props.placeholder;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var loading = __loading !== undefined ? __loading : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return a;
                  }),
                (function (a) {
                    return a;
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  return JsxRuntime.jsx(BSPInputs$SelectSearch$SSImpl, {
                              placeholder: placeholder,
                              disabled: disabled,
                              loading: loading,
                              value: param.value,
                              set: param.set,
                              options: {
                                TAG: "Required",
                                _0: options
                              },
                              width: width,
                              setLoading: setLoading,
                              clearOptions: clearOptions,
                              search: search
                            });
                }),
              overrideErrorText: props.overrideErrorText
            });
}

function BSPInputs$SelectSearch$Optional(props) {
  var search = props.search;
  var clearOptions = props.clearOptions;
  var options = props.options;
  var setLoading = props.setLoading;
  var __loading = props.loading;
  var width = props.width;
  var __fullWidth = props.fullWidth;
  var __disabled = props.disabled;
  var __required = props.required;
  var placeholder = props.placeholder;
  var required = __required !== undefined ? __required : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var fullWidth = __fullWidth !== undefined ? __fullWidth : false;
  var loading = __loading !== undefined ? __loading : false;
  return JsxRuntime.jsx(BSPInputs$Impl, {
              view: props.view,
              label: props.label,
              helperText: props.helperText,
              input: props.input,
              map: [
                (function (a) {
                    return Belt_Option.getWithDefault(a, "");
                  }),
                (function (a) {
                    if (a === "") {
                      return ;
                    } else {
                      return a;
                    }
                  })
              ],
              required: required,
              disabled: disabled,
              fullWidth: fullWidth,
              children: (function (param) {
                  return JsxRuntime.jsx(BSPInputs$SelectSearch$SSImpl, {
                              placeholder: placeholder,
                              disabled: disabled,
                              loading: loading,
                              value: param.value,
                              set: param.set,
                              options: {
                                TAG: "Optional",
                                _0: options
                              },
                              width: width,
                              setLoading: setLoading,
                              clearOptions: clearOptions,
                              search: search
                            });
                }),
              overrideErrorText: props.overrideErrorText
            });
}

var Optional$1 = {
  make: BSPInputs$SelectSearch$Optional
};

var SelectSearch = {
  SSImpl: SSImpl,
  make: BSPInputs$SelectSearch,
  Optional: Optional$1
};

exports.Impl = Impl;
exports.Bool = Bool;
exports.Select = Select;
exports.$$Date = $$Date;
exports.Country = Country;
exports.FormControlMod = FormControlMod;
exports.PhoneInput = PhoneInput;
exports.$$Text = $$Text;
exports.SelectSearch = SelectSearch;
/* react Not a pure module */
