// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DeleteOrBookModal_BookMutation_graphql$Thick = require("../../__generated__/DeleteOrBookModal_BookMutation_graphql.bs.js");
var DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick = require("../../__generated__/DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql.bs.js");

var convertVariables = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, DeleteOrBookModal_AlterBookingSessionDeleteMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var DeleteBookingSessionMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

var convertVariables$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = DeleteOrBookModal_BookMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation$1 = RescriptRelay_Mutation.commitMutation(convertVariables$1, DeleteOrBookModal_BookMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, DeleteOrBookModal_BookMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var BookMutation_bookingSessionError_decode = DeleteOrBookModal_BookMutation_graphql$Thick.Utils.bookingSessionError_decode;

var BookMutation_bookingSessionError_fromString = DeleteOrBookModal_BookMutation_graphql$Thick.Utils.bookingSessionError_fromString;

var BookMutation = {
  bookingSessionError_decode: BookMutation_bookingSessionError_decode,
  bookingSessionError_fromString: BookMutation_bookingSessionError_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation$1,
  use: use$1
};

function DeleteOrBookModal(props) {
  var refetchList = props.refetchList;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var focusedSession = props.focusedSession;
  var onClose = props.onClose;
  var match = focusedSession !== undefined ? (
      focusedSession.TAG === "Delete" ? [
          "Delete Booking Session",
          "Are you sure you want to delete this booking session?",
          "Delete"
        ] : [
          "Attempt Booking",
          "You are about to attempt to book this session. Are you sure?",
          "Attempt Booking"
        ]
    ) : [
      "No session selected",
      "Please select a booking session in order to delete or book!",
      "Close"
    ];
  var match$1 = use();
  var $$delete = match$1[0];
  var match$2 = use$1();
  var book = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var error = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setSuccess = match$4[1];
  var success = match$4[0];
  var match$5 = React.useState(function () {
        return "";
      });
  var setProposedBookingId = match$5[1];
  var proposedBookingId = match$5[0];
  var onClose$1 = function () {
    onClose();
    setError(function (param) {
          
        });
    setSuccess(function (param) {
          
        });
    setProposedBookingId(function (param) {
          return "";
        });
  };
  var tmp;
  tmp = focusedSession !== undefined && !(focusedSession.TAG === "Delete" || success !== undefined) ? JsxRuntime.jsxs(FormControl, {
          error: proposedBookingId !== "" && Belt_Result.isError(CS_NonemptyStrings$Util.BookingId.fromPrimitive(proposedBookingId)),
          children: [
            JsxRuntime.jsx(FormLabel, {
                  children: "Proposed Booking ID (optional)"
                }),
            JsxRuntime.jsx(Joy$Util.Input.make, {
                  type_: "text",
                  required: false,
                  value: proposedBookingId,
                  onChange: (function (e) {
                      var value = e.currentTarget.value;
                      setProposedBookingId(function (param) {
                            return value;
                          });
                    })
                })
          ]
        }) : null;
  var tmp$1;
  if (success !== undefined) {
    tmp$1 = (function (param) {
        onClose$1();
      });
  } else if (focusedSession !== undefined) {
    if (focusedSession.TAG === "Delete") {
      var sessionId = focusedSession._0;
      tmp$1 = (function (e) {
          e.preventDefault();
          $$delete({
                input: {
                  _bookingSessionId: sessionId,
                  _brandFamilySlug: brandFamily,
                  _voyageSlug: voyage
                }
              }, undefined, undefined, undefined, (function (response, param) {
                  var match = response.deleteBookingSession;
                  if (match !== undefined) {
                    var match$1 = match.boolean;
                    if (match$1 !== undefined && match$1) {
                      onClose$1();
                      return refetchList();
                    }
                    
                  }
                  setError(function (param) {
                        return "An error occurred while deleting the booking session.";
                      });
                }), undefined, undefined);
        });
    } else {
      var sessionId$1 = focusedSession._0;
      tmp$1 = (function (e) {
          e.preventDefault();
          var bookingId = CS_NonemptyStrings$Util.BookingId.fromPrimitive(proposedBookingId);
          var _bookingId;
          _bookingId = bookingId.TAG === "Ok" ? Caml_option.some(bookingId._0) : undefined;
          book({
                input: {
                  _brandFamilySlug: brandFamily,
                  _voyageSlug: voyage,
                  _bookingSessionId: sessionId$1,
                  _bookingId: _bookingId
                }
              }, undefined, undefined, undefined, (function (response, param) {
                  var match = response.book;
                  if (match !== undefined) {
                    var match$1 = match.createBookingResult;
                    if (match$1 !== undefined) {
                      var bookingId = match$1.bookingId;
                      var exit = 0;
                      if (bookingId !== undefined) {
                        var bookingVersionNumber = match$1.bookingVersionNumber;
                        if (bookingVersionNumber !== undefined) {
                          if (match$1.success) {
                            var bookingId$1 = Caml_option.valFromOption(bookingId);
                            setSuccess(function (param) {
                                  return {
                                          bookingId: bookingId$1,
                                          bookingVersionNumber: bookingVersionNumber
                                        };
                                });
                            return refetchList();
                          }
                          exit = 2;
                        } else {
                          exit = 2;
                        }
                      } else {
                        exit = 2;
                      }
                      if (exit === 2) {
                        var error = match$1.nullableError;
                        if (error !== undefined && !match$1.success) {
                          return setError(function (param) {
                                      return error;
                                    });
                        }
                        
                      }
                      
                    }
                    
                  }
                  setError(function (param) {
                        return "An error occurred while booking the session.";
                      });
                }), undefined, undefined);
        });
    }
  } else {
    tmp$1 = (function (param) {
        onClose$1();
      });
  }
  var tmp$2;
  tmp$2 = success !== undefined ? "success" : (
      focusedSession !== undefined && focusedSession.TAG === "Delete" ? "danger" : "primary"
    );
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose$1,
              title: match[0],
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  success !== undefined ? JsxRuntime.jsxs(Stack, {
                                          direction: "column",
                                          spacing: 1,
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  color: "success",
                                                  children: "You have successfully booked this session!"
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  component: "span",
                                                  fontWeight: "bold",
                                                  children: Caml_option.some("Booking Id: " + CS_NonemptyStrings$Util.BookingId.toString(success.bookingId))
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  component: "span",
                                                  fontWeight: "bold",
                                                  children: Caml_option.some("Version: " + String(success.bookingVersionNumber))
                                                })
                                          ]
                                        }) : JsxRuntime.jsx(Typography, {
                                          children: Caml_option.some(match[1])
                                        }),
                                  tmp,
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              })),
                      sx: {
                        maxWidth: "500px"
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Button, {
                                loading: match$2[1] || match$1[1],
                                onClick: tmp$1,
                                color: tmp$2,
                                size: "lg",
                                children: Caml_option.some(success !== undefined ? "Close" : match[2])
                              }))
                    })
              ]
            });
}

var make = DeleteOrBookModal;

exports.DeleteBookingSessionMutation = DeleteBookingSessionMutation;
exports.BookMutation = BookMutation;
exports.make = make;
/* commitMutation Not a pure module */
