// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"nodes_booking_version_voyage_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"nodes_booking_version_voyage_voyageName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"nodes_booking_version_voyage_voyStartDate":{"c":"Util.CustomScalars.SQLDate.Exn"},"nodes_booking_version_voyage_voyEndDate":{"c":"Util.CustomScalars.SQLDate.Exn"}}};

var fragmentConverterMap = {
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoyageDetails_passengersfrag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Passenger",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingVersion",
          "kind": "LinkedField",
          "name": "booking_version",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BrandFamilyVoyage",
              "kind": "LinkedField",
              "name": "voyage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "voyageSlug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "voyageName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "voyStartDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "voyEndDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PassengersConnection",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* CS_Slugs-Util Not a pure module */
