// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var VoyageDetails$Thick = require("./VoyageDetails.bs.js");
var FetchBfCustUserVoyagesQuery_graphql$Thick = require("../../../__generated__/FetchBfCustUserVoyagesQuery_graphql.bs.js");

var convertVariables = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = FetchBfCustUserVoyagesQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, FetchBfCustUserVoyagesQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(FetchBfCustUserVoyagesQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(brandFamily, voyage) {
  var match = React.useState(function () {
        return "NotAsked";
      });
  var setVoyOpt = match[1];
  var voyOpt = match[0];
  var fetchVoyages = function (acctId) {
    setVoyOpt(function (param) {
          return "Loading";
        });
    $$fetch(RelayEnv$Thick.environment, {
          accountId: acctId,
          brandFamily: brandFamily
        }, (function (res) {
            if (res.TAG === "Ok") {
              var match = res._0.bfcustUsers;
              if (match === undefined) {
                return ;
              }
              var match$1 = match.nodes;
              if (match$1.length !== 1) {
                return ;
              }
              var match$2 = match$1[0];
              var nodes = match$2.passenger_record;
              return setVoyOpt(function (param) {
                          return {
                                  TAG: "Success",
                                  _0: nodes.fragmentRefs
                                };
                        });
            }
            console.log(res._0);
            setVoyOpt(function (param) {
                  return {
                          TAG: "Failure",
                          _0: "Couldn't fetch user voyages."
                        };
                });
          }), undefined, undefined);
  };
  var voyages = VoyageDetails$Thick.usePassengersFragment(RemoteData$Util.toOption(voyOpt), undefined, Belt_Option.mapWithDefault(voyage, [], (function (v) {
              return [v];
            })));
  return {
          voyOpt: RemoteData$Util.mapSuccess(voyOpt, (function (param) {
                  return voyages;
                })),
          fetchVoyages: fetchVoyages
        };
}

exports.Query = Query;
exports.useQuery = useQuery;
/* use Not a pure module */
