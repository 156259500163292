// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"holdCabinInput":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_holdDuration":{"r":"intervalInput"},"_forOccupancy":{"b":""},"_fareClassSlug":{"c":"Util.CustomScalars.FareClassSlug.Exn"},"_cabinNumber":{"c":"Util.CustomScalars.CabinNumber.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"}},"intervalInput":{"years":{"b":""},"seconds":{"b":""},"months":{"b":""},"minutes":{"b":""},"hours":{"b":""},"days":{"b":""}},"__root":{"input":{"r":"holdCabinInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.CabinNumber.Exn": CS_NonemptyStrings$Util.CabinNumber.Exn.serialize,
  "Util.CustomScalars.FareClassSlug.Exn": CS_Slugs$Util.FareClassSlug.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"holdCabin_datetime":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"holdCabin_datetime":{"c":"Util.CustomScalars.SQLDateTime.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HoldCabinPayload",
    "kind": "LinkedField",
    "name": "holdCabin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "datetime",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HoldCabinSubmit_HoldCabinMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HoldCabinSubmit_HoldCabinMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a12a82872bfa7464510d553898e82024",
    "id": null,
    "metadata": {},
    "name": "HoldCabinSubmit_HoldCabinMutation",
    "operationKind": "mutation",
    "text": "mutation HoldCabinSubmit_HoldCabinMutation(\n  $input: HoldCabinInput!\n) {\n  holdCabin(input: $input) {\n    datetime\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
