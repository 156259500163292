// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var BSPInputs$Thick = require("../../booking-sessions/BSPInputs.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var CustomersState$Thick = require("../../../utils/CustomersState.bs.js");
var VoyageMiniCard$Thick = require("./VoyageMiniCard.bs.js");
var CopyPasteableText$Thick = require("../atoms/CopyPasteableText.bs.js");
var Share = require("@mui/icons-material/Share").default;
var WarningAmber = require("@mui/icons-material/WarningAmber").default;
var AlternateEmail = require("@mui/icons-material/AlternateEmail").default;
var PersonOutlined = require("@mui/icons-material/PersonOutlined").default;
var ArticleOutlined = require("@mui/icons-material/ArticleOutlined").default;

function CustomerForms$Form$FormWrapper(props) {
  var showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var subtitle = props.subtitle;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var tmp;
  tmp = variant === "Neutral" ? "transparent" : "neutral.200";
  var tmp$1;
  tmp$1 = variant === "Neutral" ? 0 : 4;
  var tmp$2;
  tmp$2 = variant === "Neutral" ? 1 : 2;
  var tmp$3;
  tmp$3 = variant === "Neutral" ? 1 : 2;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Stack, {
                      sx: {
                        mb: showTitleSection ? 2 : 0
                      },
                      children: [
                        showTitleSection ? JsxRuntime.jsx(Typography, {
                                startDecorator: Caml_option.some(props.startDecorator),
                                level: props.titleSize === "md" ? "title-md" : "h4",
                                component: "h2",
                                children: Caml_option.some(props.title)
                              }) : null,
                        subtitle !== undefined ? JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                sx: {
                                  py: 1,
                                  color: "neutral.500"
                                },
                                children: Caml_option.some(subtitle)
                              }) : null
                      ]
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      sx: {
                        backgroundColor: tmp,
                        p: tmp$1,
                        borderRadius: "12px"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Joy$Util.Grid.make, {
                                children: Caml_option.some(props.children),
                                container: true,
                                spacing: {
                                  TAG: "Fixed",
                                  _0: 1
                                },
                                rowSpacing: tmp$2,
                                columnSpacing: tmp$3
                              }))
                    })
              ]
            });
}

var FormWrapper = {
  make: CustomerForms$Form$FormWrapper
};

function CustomerForms$Form$AccountEmail(props) {
  var __noErrors = props.noErrors;
  var __showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Account Email";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsx(CustomerForms$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(AlternateEmail, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: JsxRuntime.jsx(Joy$Util.Grid.make, {
                    children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                              view: {
                                TAG: "Direct",
                                _0: props.custForm
                              },
                              input: CustomersState$Thick.accountEmail,
                              label: "Account Email",
                              helperText: "Prefilled accounts require an email address. This email may receive notifications about bookings.",
                              fullWidth: true,
                              noErrors: noErrors
                            })),
                    xs: 12,
                    md: 9
                  })
            });
}

var AccountEmail = {
  make: CustomerForms$Form$AccountEmail
};

function CustomerForms$Form$PrefillLinkManagement$MutButton(props) {
  var brandFamily = props.brandFamily;
  var custForm = props.custForm;
  var match = HopperState$Util.Observable.useComputed(custForm, (function (param) {
          var match = param.prefillLink;
          return [
                  RemoteData$Util.isLoading(match.manageMutation),
                  Belt_Option.isSome(match.extantLink)
                ];
        }), undefined, undefined);
  var loading = match[0];
  var validatedForm = HopperState$Util.Observable.useComputed(custForm, (function (f) {
          return CustomersState$Thick.validateForPrefillLinkManagement(f, brandFamily, undefined);
        }), undefined, undefined);
  var notifyOfMut = function (mut) {
    HopperState$Util.Observable.notify(custForm, (function (form) {
            var newrecord = Caml_obj.obj_dup(form);
            var init = form.prefillLink;
            newrecord.prefillLink = {
              linkExpiry: init.linkExpiry,
              shouldRegenerate: init.shouldRegenerate,
              extantLink: init.extantLink,
              manageMutation: mut
            };
            newrecord.fetchKey = RemoteData$Util.isSuccess(mut) ? Date.now() : form.fetchKey;
            if (RemoteData$Util.isSuccess(mut)) {
              return CustomersState$Thick.shouldResetBfcustPrefillLink.set(newrecord, false, "Value");
            } else {
              return newrecord;
            }
          }));
  };
  var manage = RemoteData$Util.Relay.useMutationWithNotify(CustomersState$Thick.ManagePrefillMutation.use, (function (res) {
          var match = res.managePrefilledLink;
          if (match === undefined) {
            return {
                    TAG: "Success",
                    _0: new Date()
                  };
          }
          var str = match.nullableErrorMessage;
          if (str !== undefined) {
            return {
                    TAG: "Failure",
                    _0: str
                  };
          } else {
            return {
                    TAG: "Success",
                    _0: new Date()
                  };
          }
        }), notifyOfMut);
  var onClick = function (e) {
    e.preventDefault();
    if (validatedForm.TAG !== "Ok") {
      return notifyOfMut({
                  TAG: "Failure",
                  _0: validatedForm._0
                });
    }
    manage({
          input: validatedForm._0
        }, undefined, undefined, undefined, undefined);
  };
  var formHasErrors = Belt_Result.isError(validatedForm);
  return JsxRuntime.jsx(Button, {
              loading: loading,
              onClick: onClick,
              startDecorator: Caml_option.some(formHasErrors ? JsxRuntime.jsx(WarningAmber, {
                          fontSize: "md"
                        }) : null),
              variant: formHasErrors ? "outlined" : "solid",
              color: formHasErrors ? "danger" : "primary",
              disabled: formHasErrors || loading,
              children: Caml_option.some(match[1] ? "Update Link" : "Create Link")
            });
}

var MutButton = {
  make: CustomerForms$Form$PrefillLinkManagement$MutButton
};

function CustomerForms$Form$PrefillLinkManagement(props) {
  var __noErrors = props.noErrors;
  var __showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var custForm = props.custForm;
  var __subtitle = props.subtitle;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Prefilled Account Links";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var subtitle = __subtitle !== undefined ? __subtitle : "Prefilled accounts exist as full fledged accounts, but the user who they are meant for hasn't claimed them yet. You can invite users to claim these prefilled accounts by sending them a link. This section of the form allows you to manage these links, extend them, and regenerate them as needed.";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  var link = HopperState$Util.Observable.useComputed(custForm, (function (param) {
          return param.prefillLink.extantLink;
        }), undefined, undefined);
  var refreshedAt = HopperState$Util.Observable.useComputed(custForm, (function (param) {
          return RemoteData$Util.toOption(param.prefillLink.manageMutation);
        }), undefined, undefined);
  return JsxRuntime.jsxs(CustomerForms$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(Share, {}),
              title: title,
              titleSize: titleSize,
              subtitle: subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Date.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.prefillBfcustLinkExpiry,
                                label: "Prefill Link Expiry",
                                helperText: "Select a date after which this link will no longer be valid. You may update this date later.",
                                placeholder: "expiration date",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Bool.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.shouldResetBfcustPrefillLink,
                                label: "Regenerate the link",
                                helperText: "Should we regenerate the link, so any previous link will no longer function?",
                                fullWidth: true
                              })),
                      xs: 12,
                      md: 6
                    }),
                link !== undefined ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                        children: Caml_option.some(JsxRuntime.jsx(CopyPasteableText$Thick.make, {
                                  label: "Account Claim Link",
                                  value: link
                                })),
                        xs: 12,
                        md: 12
                      }) : null,
                JsxRuntime.jsxs(Joy$Util.Grid.make, {
                      children: [
                        JsxRuntime.jsx(CustomerForms$Form$PrefillLinkManagement$MutButton, {
                              custForm: custForm,
                              brandFamily: props.brandFamily
                            }),
                        Belt_Option.isSome(refreshedAt) ? JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: Caml_option.some("You updated the link for this user on " + DateFns.format(Belt_Option.getExn(refreshedAt), "MMM do, y @ h:mm:ss bbb"))
                              }) : null
                      ],
                      xs: 12,
                      md: 12
                    })
              ]
            });
}

var PrefillLinkManagement = {
  MutButton: MutButton,
  make: CustomerForms$Form$PrefillLinkManagement
};

function CustomerForms$Form$Travel(props) {
  var __noErrors = props.noErrors;
  var __showTitleSection = props.showTitleSection;
  var __variant = props.variant;
  var custForm = props.custForm;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Travel Document";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var variant = __variant !== undefined ? __variant : "Neutral";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsxs(CustomerForms$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(ArticleOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.docFirstname,
                                label: "Legal Document First Name",
                                helperText: "First name as it appears on guest's travel documents",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.docLastname,
                                label: "Legal Document Last Name",
                                helperText: "Last name as it appears on guest's travel documents",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Select.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                placeholder: "Select...",
                                label: "Legal Document Gender",
                                helperText: "Gender as it appears on guest's travel documents",
                                input: CustomersState$Thick.docGender,
                                options: Belt_Array.map(CustomersState$Thick.allLegalDocGenders, (function (dg) {
                                        return {
                                                primitive: dg,
                                                key: CustomersState$Thick.docGenderToString(dg),
                                                niceName: CustomersState$Thick.docGenderNiceName(dg)
                                              };
                                      })),
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Country.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.docCitizenship,
                                label: "Legal Document Citizenship",
                                helperText: "Citizenship as it appears on guest's travel documents",
                                countryFragment: props.fragmentRefs,
                                placeholder: "Select...",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Date.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.docBirthdate,
                                label: "Legal Document Birthdate",
                                helperText: "Birthdate as it appears on guest's travel documents",
                                placeholder: "Birthdate",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var Travel = {
  make: CustomerForms$Form$Travel
};

function CustomerForms$Form$Personal(props) {
  var __noErrors = props.noErrors;
  var __variant = props.variant;
  var custForm = props.custForm;
  var __showTitleSection = props.showTitleSection;
  var __titleSize = props.titleSize;
  var __title = props.title;
  var fragmentRefs = props.fragmentRefs;
  var title = __title !== undefined ? __title : "User Profile";
  var titleSize = __titleSize !== undefined ? __titleSize : "lg";
  var showTitleSection = __showTitleSection !== undefined ? __showTitleSection : true;
  var variant = __variant !== undefined ? __variant : "Neutral";
  var noErrors = __noErrors !== undefined ? __noErrors : false;
  return JsxRuntime.jsxs(CustomerForms$Form$FormWrapper, {
              startDecorator: JsxRuntime.jsx(PersonOutlined, {}),
              title: title,
              titleSize: titleSize,
              subtitle: props.subtitle,
              variant: variant,
              showTitleSection: showTitleSection,
              children: [
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.preferredFirstName,
                                label: "Preferred First Name",
                                helperText: "Guest's preferred first name",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.preferredLastName,
                                label: "Preferred Last Name",
                                helperText: "Guest's preferred last name",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.PhoneInput.make, {
                                countryFragment: fragmentRefs,
                                view: custForm,
                                phone: CustomersState$Thick.phone,
                                phoneCountryCode: CustomersState$Thick.phoneCountryCode,
                                validatePhone: CustomersState$Thick.validatePhone,
                                required: false,
                                label: "Phone Number",
                                helperText: "Guest's primary phone number"
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.postalCode,
                                label: "Current Postal Code",
                                helperText: "Guests's current residence postal code",
                                required: false,
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.Country.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.residenceCountry,
                                label: "Country of Residence",
                                helperText: "Guest's current country of residence",
                                countryFragment: fragmentRefs,
                                placeholder: "Select..",
                                required: false,
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    }),
                JsxRuntime.jsx(Joy$Util.Grid.make, {
                      children: Caml_option.some(JsxRuntime.jsx(BSPInputs$Thick.$$Text.Optional.make, {
                                view: {
                                  TAG: "Direct",
                                  _0: custForm
                                },
                                input: CustomersState$Thick.halMarinerNumber,
                                label: "HAL Mariner Number",
                                helperText: "Holland America Mariner number (if any)",
                                fullWidth: true,
                                noErrors: noErrors
                              })),
                      xs: 12,
                      md: 6
                    })
              ]
            });
}

var Personal = {
  make: CustomerForms$Form$Personal
};

function CustomerForms$Form$Voyages(props) {
  var custForm = props.custForm;
  var match = HopperState$Util.Observable.useComputed(custForm, (function (param) {
          return [
                  param.showVoyageOptions,
                  param.voyageOptions,
                  param.updateOtherVoyages
                ];
        }), undefined, undefined);
  var selectedVoyages = match[2];
  var voyageOptions = match[1];
  var showVoyageOptions = match[0];
  if (props.display && voyageOptions.length !== 0) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          cursor: "pointer"
                        },
                        onClick: (function (param) {
                            HopperState$Util.Observable.notify(custForm, (function (f) {
                                    var newrecord = Caml_obj.obj_dup(f);
                                    newrecord.showVoyageOptions = !f.showVoyageOptions;
                                    return newrecord;
                                  }));
                          }),
                        children: [
                          JsxRuntime.jsx(Checkbox, {
                                checked: showVoyageOptions,
                                size: "sm"
                              }),
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: "Apply changes to bookings"
                              })
                        ]
                      }),
                  showVoyageOptions ? JsxRuntime.jsx(Joy$Util.Grid.make, {
                          children: Caml_option.some(Belt_Array.mapWithIndex(voyageOptions, (function (i, v) {
                                      return JsxRuntime.jsx(Joy$Util.Grid.make, {
                                                  children: Caml_option.some(JsxRuntime.jsx(VoyageMiniCard$Thick.make, {
                                                            voyage: v,
                                                            onSelect: (function () {
                                                                var s = v.voyageSlug;
                                                                HopperState$Util.Observable.notify(custForm, (function (f) {
                                                                        var updateOtherVoyages = f.updateOtherVoyages;
                                                                        var newrecord = Caml_obj.obj_dup(f);
                                                                        newrecord.updateOtherVoyages = Belt_Set.has(updateOtherVoyages, s) ? Belt_Set.remove(updateOtherVoyages, s) : Belt_Set.add(updateOtherVoyages, s);
                                                                        return newrecord;
                                                                      }));
                                                              }),
                                                            isSelected: Belt_Set.has(selectedVoyages, v.voyageSlug)
                                                          })),
                                                  xs: 12,
                                                  md: 6
                                                }, String(i));
                                    }))),
                          container: true,
                          rowSpacing: 1,
                          columnSpacing: 1
                        }) : null
                ]
              });
  } else {
    return null;
  }
}

var Voyages = {
  make: CustomerForms$Form$Voyages
};

var Form = {
  FormWrapper: FormWrapper,
  AccountEmail: AccountEmail,
  PrefillLinkManagement: PrefillLinkManagement,
  Travel: Travel,
  Personal: Personal,
  Voyages: Voyages
};

exports.Form = Form;
/* Joy-Util Not a pure module */
