// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var BDButton$Thick = require("../atoms/BDButton.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDContentBlock$Thick = require("../molecules/BDContentBlock.bs.js");
var BDBillPayDrawer$Thick = require("./BDBillPayDrawer.bs.js");
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;

function BDBillPaySummary$BillPaySummary(props) {
  var content = props.content;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var match$1 = content.billingLineSubtotal;
  var value = content.billingLineSubtotal;
  var match$2 = content.completedPaymentSubtotal;
  var value$1 = content.completedPaymentSubtotal;
  var match$3 = content.totalAmount;
  var value$2 = content.totalAmount;
  var match$4 = content.arePendingPayments;
  var match$5 = content.pendingPaymentSubtotal;
  var match$6 = content.totalAmountIncludingPending;
  var tmp;
  if (match$4 && match$5 !== undefined && match$6 !== undefined) {
    var match$7 = content.pendingPaymentSubtotal;
    var match$8 = content.pendingPaymentSubtotal;
    tmp = JsxRuntime.jsxs(Stack, {
          direction: "column",
          spacing: 1,
          children: [
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  justifyContent: "space-between",
                  children: [
                    JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          sx: {
                            color: "neutral.500",
                            fontStyle: "italic"
                          },
                          fontWeight: "300",
                          children: "Pending Payment"
                        }),
                    JsxRuntime.jsx(Typography, {
                          level: "body-md",
                          sx: {
                            color: match$7 !== undefined ? "text.primary" : "neutral.500",
                            fontStyle: "italic"
                          },
                          children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(match$5))))
                        })
                  ]
                }),
            JsxRuntime.jsxs(Stack, {
                  direction: "row",
                  justifyContent: "space-between",
                  children: [
                    JsxRuntime.jsx(Typography, {
                          level: "body-sm",
                          sx: {
                            color: "neutral.500",
                            fontStyle: "italic"
                          },
                          fontWeight: "300",
                          children: "Balance After Pending"
                        }),
                    JsxRuntime.jsx(Typography, {
                          level: "body-md",
                          sx: {
                            color: match$8 !== undefined ? "text.primary" : "neutral.500",
                            fontStyle: "italic"
                          },
                          children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(match$6))))
                        })
                  ]
                })
          ]
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(BDContentBlock$Thick.make, {
              title: "Billing & Payments",
              actions: Caml_option.some(JsxRuntime.jsx(BDButton$Thick.make, {
                        onClick: (function (param) {
                            setIsOpen(function (param) {
                                  return true;
                                });
                          }),
                        text: "View Details"
                      })),
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      sx: {
                                        color: "neutral.500"
                                      },
                                      fontWeight: "300",
                                      children: "Total Billed"
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-md",
                                      sx: {
                                        color: match$1 !== undefined ? "text.primary" : "neutral.500"
                                      },
                                      children: Caml_option.some(value !== undefined ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(value))) : "N/A")
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsx(Typography, {
                                      level: "body-sm",
                                      sx: {
                                        color: "neutral.500"
                                      },
                                      fontWeight: "300",
                                      children: "Total Paid"
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-md",
                                      sx: {
                                        color: match$2 !== undefined ? "text.primary" : "neutral.500"
                                      },
                                      children: Caml_option.some(value$1 !== undefined ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(value$1))) : "N/A")
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Divider, {}),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "row",
                                      spacing: 1,
                                      children: [
                                        JsxRuntime.jsx(CheckCircleOutline, {
                                              color: "success"
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-md",
                                              sx: {
                                                color: "neutral.500"
                                              },
                                              fontWeight: "300",
                                              children: "Balance"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-lg",
                                      sx: {
                                        color: match$3 !== undefined ? "text.primary" : "neutral.500"
                                      },
                                      children: Caml_option.some(value$2 !== undefined ? Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(Caml_option.valFromOption(value$2))) : "N/A")
                                    })
                              ]
                            }),
                        tmp
                      ]
                    }),
                JsxRuntime.jsx(BDBillPayDrawer$Thick.make, {
                      open_: match[0],
                      onClose: (function () {
                          setIsOpen(function (param) {
                                return false;
                              });
                        }),
                      isActive: props.isActive,
                      isCanceled: props.isCanceled,
                      snapshot: props.snapshot,
                      canManage: props.canManage,
                      billPayRec: props.billPayRec,
                      versionUrl: props.versionUrl,
                      generatePreviewFromDiff: props.generatePreviewFromDiff,
                      versionTimestamp: props.versionTimestamp,
                      versionSuppressions: props.versionSuppressions,
                      currentUserEmail: props.currentUserEmail,
                      brandFamily: props.brandFamily,
                      refetchBilling: props.refetchBilling,
                      isFetchingData: props.isFetchingData
                    })
              ]
            });
}

var BillPaySummary = {
  make: BDBillPaySummary$BillPaySummary
};

function BDBillPaySummary(props) {
  var billPayRec = props.billPayRec;
  var content_arePendingPayments = billPayRec !== undefined ? billPayRec.arePendingPayments : false;
  var content_billingLineSubtotal = billPayRec !== undefined ? Caml_option.some(billPayRec.billing.billingLineSubtotal) : undefined;
  var content_completedPaymentSubtotal = billPayRec !== undefined ? Caml_option.some(billPayRec.payment.completedPaymentSubtotal) : undefined;
  var content_pendingPaymentSubtotal = billPayRec !== undefined ? Caml_option.some(billPayRec.payment.pendingPaymentSubtotal) : undefined;
  var content_totalAmountIncludingPending = billPayRec !== undefined ? Caml_option.some(billPayRec.totalAmountIncludingPending) : undefined;
  var content_totalAmount = billPayRec !== undefined ? Caml_option.some(billPayRec.totalAmount) : undefined;
  var content = {
    arePendingPayments: content_arePendingPayments,
    billingLineSubtotal: content_billingLineSubtotal,
    completedPaymentSubtotal: content_completedPaymentSubtotal,
    pendingPaymentSubtotal: content_pendingPaymentSubtotal,
    totalAmountIncludingPending: content_totalAmountIncludingPending,
    totalAmount: content_totalAmount
  };
  return JsxRuntime.jsx(BDBillPaySummary$BillPaySummary, {
              content: content,
              snapshot: props.snapshot,
              isActive: props.isActive,
              isCanceled: props.isCanceled,
              canManage: props.canManage,
              fragmentRefs: props.fragmentRefs,
              billPayRec: billPayRec,
              versionUrl: props.versionUrl,
              generatePreviewFromDiff: props.generatePreviewFromDiff,
              versionTimestamp: props.versionTimestamp,
              versionSuppressions: props.versionSuppressions,
              currentUserEmail: props.currentUserEmail,
              brandFamily: props.brandFamily,
              refetchBilling: props.refetchBilling,
              isFetchingData: props.isFetchingData
            });
}

var make = BDBillPaySummary;

exports.BillPaySummary = BillPaySummary;
exports.make = make;
/* react Not a pure module */
