// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Reporting$Util = require("util/src/Reporting.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function VoyageDashboardPage(props) {
  var voyage = props.voyage;
  var url = process.env.GRAPHILE_URL;
  var useDashboardQuery = function (query, rotateOpt) {
    var rotate = rotateOpt !== undefined ? rotateOpt : false;
    return Reporting$Util.Graphql.useTable(url, query, rotate);
  };
  var makeDownloadButton = function (fileName, query) {
    var action = Reporting$Util.Graphql.getDownloadActionForTableAsCSV(url, query, CS_Slugs$Util.VoyageSlug.toString(voyage) + "_" + fileName, undefined, undefined);
    return JsxRuntime.jsx("button", {
                children: fileName,
                onClick: (function (param) {
                    action();
                  })
              });
  };
  var renderTable = function (tab, withHeadersOpt, divAfterOpt, firstColOpt) {
    var withHeaders = withHeadersOpt !== undefined ? withHeadersOpt : false;
    var divAfter = divAfterOpt !== undefined ? divAfterOpt : [];
    var firstCol = firstColOpt !== undefined ? firstColOpt : true;
    if (typeof tab !== "object") {
      return null;
    }
    if (tab.TAG !== "Success") {
      return "There was an error loading the query";
    }
    var tab$1 = tab._0;
    if (tab$1 !== undefined) {
      return JsxRuntime.jsxs("table", {
                  children: [
                    withHeaders ? JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsx("tr", {
                                  children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, v) {
                                          return JsxRuntime.jsx("th", {
                                                      children: v,
                                                      style: ind === 0 && firstCol ? ({
                                                            border: "1px solid #ccc",
                                                            borderRight: "5px double #ccc",
                                                            fontSize: "14px",
                                                            padding: "10px",
                                                            textAlign: "right"
                                                          }) : (
                                                          divAfter.includes(ind) ? ({
                                                                border: "1px solid #ccc",
                                                                borderRight: "5px double #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              }) : ({
                                                                border: "1px solid #ccc",
                                                                fontSize: "14px",
                                                                padding: "10px",
                                                                textAlign: "left"
                                                              })
                                                        )
                                                    });
                                        })),
                                  style: {
                                    background: "#3498db",
                                    color: "white",
                                    fontWeight: "bold"
                                  }
                                })
                          }) : null,
                    JsxRuntime.jsx("tbody", {
                          children: Belt_Array.mapWithIndex(tab$1.rows, (function (rowInd, row) {
                                  return JsxRuntime.jsx("tr", {
                                              children: Belt_Array.mapWithIndex(tab$1.headers, (function (ind, hdr) {
                                                      return JsxRuntime.jsx("td", {
                                                                  children: Reporting$Util.getHeader(row, hdr),
                                                                  style: ind === 0 && firstCol ? ({
                                                                        border: "1px solid #ccc",
                                                                        borderRight: "5px double #777",
                                                                        fontSize: "14px",
                                                                        fontWeight: "800",
                                                                        padding: "10px",
                                                                        textAlign: "right"
                                                                      }) : Object.assign({}, divAfter.includes(ind) ? ({
                                                                              border: "1px solid #ccc",
                                                                              borderRight: "5px double #777",
                                                                              fontSize: "14px",
                                                                              padding: "10px",
                                                                              textAlign: "left"
                                                                            }) : ({
                                                                              border: "1px solid #ccc",
                                                                              fontSize: "14px",
                                                                              padding: "10px",
                                                                              textAlign: "left"
                                                                            }), Reporting$Util.getHeader(row, hdr).startsWith("($") ? ({
                                                                              color: "red"
                                                                            }) : ({}))
                                                                });
                                                    })),
                                              style: rowInd % 2 === 0 ? ({
                                                    background: "white"
                                                  }) : ({
                                                    background: "#eee"
                                                  })
                                            });
                                }))
                        })
                  ],
                  style: {
                    borderCollapse: "collapse"
                  }
                });
    } else {
      return "No Rows to display ";
    }
  };
  var allBookingsByStatus = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: NONE\n    rollupKey: LIFECYCLE_STATUS\n  ) {\n    nodes {\n      status: rollupValue\n      totalBookings: numBookings\n      totalBerths\n    }\n  }\n  }\n}\n", undefined);
  var completedPaymentsByPmtMethod = useDashboardQuery("\n{\n  query {\n      reportVoyPaymentRollup(\n    _voyageSlug: \"2025\"\n    filterKey: COMPLETED_PAYMENTS\n    rollupKey: PMT_METHOD\n  ) {\n    nodes {\n      paymentMethod: rollupValue\n      incomingTotal: netIncoming\n      outgoingTotal: netOutgoing\n      processorFees: netBfFees\n\n    }\n  }\n  }\n}\n", undefined);
  var allPaxByUpperLower = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2025\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: UPPER_LOWER\n  ) {\n    nodes {\n      berthType: rollupValue\n      nonTBAPax: numPax\n\n    }\n  }\n  }\n}\n", undefined);
  var pendingPaymentsByPmtMethod = useDashboardQuery("\n{\n  query {\n      reportVoyPaymentRollup(\n    _voyageSlug: \"2025\"\n    filterKey: PENDING_PAYMENTS\n    rollupKey: PMT_METHOD\n  ) {\n    nodes {\n      paymentMethod: rollupValue\n      incomingTotal: netIncoming\n      outgoingTotal: netOutgoing\n      processorFees: netBfFees\n\n    }\n  }\n  }\n}\n", undefined);
  var netbillings = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: NONE\n    rollupKey: NONE\n  ) {\n    nodes {\n      netBillings: totalBilled\n      netBaseFare: totalBaseFares\n      netDiscounts: totalDynamicPricing\n      netFees: totalChangeFee\n      suppressedFees: totalSuppressed\n      netManualBilling: totalManual\n    }\n  }\n  }\n}\n", undefined);
  var fareClassSales = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: FARE_CLASS_SLUG\n  ) {\n    nodes {\n      fareClass:rollupValue\n      activeBookings:numBookings\n      activeBerths:totalBerths\n      netBillings\n    }\n  }\n  }\n}\n", undefined);
  var fareClassSalesCanceled = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: CANCELED_BOOKINGS\n    rollupKey: FARE_CLASS_SLUG\n  ) {\n    nodes {\n      fareClass:rollupValue\n      canceledBookings:numBookings\n      canceledBerths:totalBerths\n      netBillings\n    }\n  }\n  }\n}\n", undefined);
  var inventoryByClass = useDashboardQuery("\n{\n  query {\n      reportVoyInventoryRollup(\n    _voyageSlug: \"2025\"\n    filterKey: NONE\n    rollupKey: CLASS\n  ) {\n    nodes {\n      class:rollupValue\n      booked:totalBookedCabins\n      available:totalAvailCabins\n      total:totalCabins\n      retailBooked:retailBookedCabins\n      retailAvail:retailAvailCabins\n      waitlist:waitlistTotalCabins\n      accessisbleHold:accessibleHoldTotalCabins\n      privateBooked:privateBookedCabins\n      privateAvail:privateAvailCabins\n    }\n  }\n  }\n}\n", undefined);
  var inventoryByCategory = useDashboardQuery("\n{\n  query {\n      reportVoyInventoryRollup(\n    _voyageSlug: \"2025\"\n    filterKey: NONE\n    rollupKey: CATEGORY\n  ) {\n    nodes {\n      class:rollupValue\n      booked:totalBookedCabins\n      available:totalAvailCabins\n      total:totalCabins\n      retailBooked:retailBookedCabins\n      retailAvail:retailAvailCabins\n      waitlist:waitlistTotalCabins\n      accessisbleHold:accessibleHoldTotalCabins\n      privateBooked:privateBookedCabins\n      privateAvail:privateAvailCabins\n    }\n  }\n  }\n}\n", undefined);
  var discounts = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: DYNAMIC_PRICING\n    rollupKey: FARE_RULE_SLUG\n  ) {\n    nodes {\n      type: rollupValue\n      netBillings: totalBilled\n      count:numBillings\n    }\n  }\n  }\n}\n", undefined);
  var fees = useDashboardQuery("\n{\n  query {\n      reportVoyBillingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: CHANGE_FEES\n    rollupKey: FARE_RULE_SLUG\n  ) {\n    nodes {\n      type: rollupValue\n      netBillings: totalBilled\n      count:numBillings\n    }\n  }\n  }\n}\n", undefined);
  var dinnerSeating = useDashboardQuery("\n{\n  query {\n      reportVoyBookingsRollup(\n    _voyageSlug: \"2025\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: PREFER_EARLY_DINNER\n  ) {\n    nodes {\n      preference: rollupValue\n      bookings: numBookings\n      berths:totalBerths\n    }\n  }\n  }\n}\n", undefined);
  var custAcquisition = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2025\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: ACQUISITION_SLUG\n  ) {\n    nodes {\n      channel: rollupValue\n      count: numPax\n      newPax: numFirstTime\n      returners: numReturning\n\n    }\n  }\n  }\n}\n", undefined);
  var loyalty = useDashboardQuery("\n{\n  query {\n      reportVoyPaxRollup(\n    _voyageSlug: \"2025\"\n    filterKey: ACTIVE_BOOKINGS\n    rollupKey: LOYALTY_TIER_SLUG\n  ) {\n    nodes {\n      channel: rollupValue\n      count: numPax\n\n    }\n  }\n  }\n}\n", undefined);
  return JsxRuntime.jsxs(Stack, {
              spacing: 1,
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h1",
                      children: Caml_option.some("Welcome to " + CS_Slugs$Util.VoyageSlug.toString(voyage) + ": a " + CS_Slugs$Util.BrandFamilySlug.toString(props.brandFamily) + " voyage!")
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 2,
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h3", {
                                      children: "Booking Totals"
                                    }),
                                renderTable(allBookingsByStatus, true, undefined, undefined),
                                JsxRuntime.jsx("br", {}),
                                renderTable(allPaxByUpperLower, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Completed Payments"
                                    }),
                                renderTable(completedPaymentsByPmtMethod, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Pending Payments"
                                    }),
                                renderTable(pendingPaymentsByPmtMethod, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Billings"
                                    }),
                                renderTable(netbillings, true, undefined, false),
                                JsxRuntime.jsx("h3", {
                                      children: "Fare Class Breakdown"
                                    }),
                                renderTable(fareClassSales, true, undefined, undefined),
                                JsxRuntime.jsx("br", {}),
                                renderTable(fareClassSalesCanceled, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Inventory by Class"
                                    }),
                                renderTable(inventoryByClass, true, [
                                      3,
                                      5,
                                      6,
                                      7
                                    ], undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Inventory by Category"
                                    }),
                                renderTable(inventoryByCategory, true, [
                                      3,
                                      5,
                                      6,
                                      7
                                    ], undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Discounts"
                                    }),
                                renderTable(discounts, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Fees"
                                    }),
                                renderTable(fees, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Dinner Seating Preferences"
                                    }),
                                renderTable(dinnerSeating, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Customer Acquisition"
                                    }),
                                renderTable(custAcquisition, true, undefined, undefined),
                                JsxRuntime.jsx("h3", {
                                      children: "Passengers by Loyalty Tier"
                                    }),
                                renderTable(loyalty, true, undefined, undefined)
                              ]
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h3", {
                                      children: "Downloadable Reports"
                                    }),
                                makeDownloadButton("Booking and Cancellation Curves", "\n  query {\n    reportVoyBookingAndCancelCurves(\n      _voyageSlug: \"2025\"\n      bucket: DAY\n      _fareClassSlug: \"retail\"\n      windowEnd: \"2024-10-01\"\n      windowStart: \"2024-01-25\"\n    ) {\n      nodes {\n        activityDate\n        numBookings\n        totalBerths\n        numCanceled\n        totalCanceledBerths\n        netIncomingPayments\n        netOutgoingPayments\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("All Booked Pax", "\n  query {\n    reportVoyAllBookedPax(_fareClassSlug: \"\", _voyageSlug: \"2025\") {\n      nodes {\n        loyaltyNumber\n        bookingId\n        paxIdx\n        cruiselineBookingNumber\n        cabinCategory\n        cabinNumber\n        cabinSalesMaxOccupancy\n        cabinCruiselineMaxOccupancy\n        cruiselineBookingNumberSent\n        displayFirstName\n        displayLastName\n        docFirstName\n        docLastName\n        docGender\n        docBirthdate\n        docCitizenship\n        residenceCountry\n        email\n        phone\n        postalCode\n        inventorySegment\n        fareClass\n        bookingCreatedAt\n        userAccountStatus\n        howHeard\n        bookingDinnerSeating\n        bookingBedConfiguration\n        currentTier\n        previousCruises\n        previousCruiseCount\n        pendingTier\n        pendingCruises\n        pendingCruiseCount\n        ageAsOfSailing\n        billingContactName\n        billingContactEmail\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Booked Pax No History", "\n  query {\n    reportVoyAllBookedPax(_fareClassSlug: \"\", _voyageSlug: \"2025\") {\n      nodes {\n        loyaltyNumber\n        paxIdx\n        cruiselineBookingNumber\n        cruiselineBookingNumberSent\n        displayFirstName\n        displayLastName\n        docFirstName\n        docLastName\n        docGender\n        docBirthdate\n        docCitizenship\n        postalCode\n        residenceCountry\n        email\n        phone\n        bookingCreatedAt\n        bookingId\n        halMarinerNumber\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Cabin Inventory", "\n  query {\n    reportVoyCabinInventory(_voyageSlug: \"2025\") {\n      nodes {\n        cabinNumber\n        holdExpiresAt\n        salesMinBerth\n        salesMaxBerth\n        totalBerths\n        cabinDeck\n        cabinConfigurationNotes\n        reservedByBooking\n        heldByCustomer\n        heldByTeamMember\n        cabinCategorySlug\n        inventorySegment\n        cabinReservationNotes\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Billed and Paid by Booking", "\n  query {\n    reportVoyBilledAndPaidByBooking(_voyageSlug: \"2025\") {\n      nodes {\n        bookingId\n        bookingCreatedAt\n        fareClassSlug\n        numBerths\n        billingContactFirstName\n        billingContactLastName\n        billingContactEmail\n        billingContactPhone\n        bookingStatus\n        totPaid\n        totBilled\n        hasPifDiscount\n        fullyPaid\n        pifDiscountAmount\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Payment Plan Status", "\n  query {\n    bookingPaymentPlans {\n      nodes {\n        bookingId\n        balance: totalBalanceDue\n        overdueByDays\n        overdueAmount\n        maximalDuePeriodIdx\n        nextAmountDueAfter\n        nextAmountDue\n        secondNextAmountDueAfter\n        secondNextAmountDue\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Private Block Breakdown", "\n  query {\n    reportVoyPrivateBlockBreakdown(_voyageSlug: \"2025\") {\n      nodes {\n        ClassCatID:voyageClassSlug\n        CabinClassName:voyageCategorySlug\n        CabinDeck:cabinDeck\n        CabinNumber:cabinNumber\n        CabinPrivateReservation:cabinReservationNotes\n        InventorySegment:inventorySegmentOldCompat\n        SegmentRevenueClassification:cabinNumber\n        SegmentNotes:cabinNumber\n        CabinInventoryBucket:inventoryBucketOldCompat\n        CabinInventoryBucketNotes:inventoryBucketOldCompat\n        BookingStatus:bookingStatusOldCompat\n        PublicBookingID:bookingId\n        PaxList:paxListOldCompat\n        ContractOccupancy:totBerths\n        PrimName:billingContactName\n        PrimAndComment:billingContactAndNoteOldCompat\n        ReportGenerationTimestamp:reportGenerationTimestampOldCompat\n        NumGuests:numBerths\n      }\n    }\n  }\n"),
                                JsxRuntime.jsx("br", {}),
                                makeDownloadButton("Simple Dupechecker", "\n  query {\n    reportVoySimpleDupechecker(_voyageSlug: \"2025\") {\n      nodes {\n        bdayLastNameDupeKey\n        numberOfOccurences\n        bookingId\n        bookingStatus\n        loyaltyNumber\n        paxIdx\n        preferredFirstName\n        preferredLastName\n        docFirstName\n        docLastName\n        email\n        docGender\n        phone\n        accountStatus\n        numEligibleCruises\n        lastCruise\n        pastCruises\n      }\n    }\n  }\n")
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = VoyageDashboardPage;

exports.make = make;
/* CS_Slugs-Util Not a pure module */
