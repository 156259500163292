// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;

function CopyPasteableText(props) {
  var value = props.value;
  var label = props.label;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "body-sm",
                      noWrap: true,
                      sx: {
                        color: "text.primary"
                      },
                      fontWeight: "normal",
                      children: Caml_option.some(label)
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      alignItems: "center",
                      spacing: 1,
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "body-sm",
                              noWrap: true,
                              sx: {
                                color: value !== undefined ? "text.primary" : "neutral.plainDisabledColor"
                              },
                              fontWeight: "normal",
                              children: Caml_option.some(value !== undefined ? value : "No " + label.toLowerCase() + " provided")
                            }),
                        JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(ContentCopyOutlined, {
                                    fontSize: "sm",
                                    onClick: (function (param) {
                                        if (value !== undefined) {
                                          Common$Thick.copyToClipboard(value, setCopied);
                                          return ;
                                        }
                                        
                                      })
                                  }),
                              arrow: true,
                              size: "sm",
                              sx: {
                                cursor: "pointer"
                              },
                              title: Caml_option.some(match[0] ? "Copied!" : "Copy to clipboard")
                            })
                      ]
                    })
              ]
            });
}

var make = CopyPasteableText;

exports.make = make;
/* react Not a pure module */
