// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_obj = require("rescript/lib/js/caml_obj.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var DateOnly$Util = require("util/src/DateOnly.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var VoyageDetails_passengersfrag_graphql$Thick = require("../../../__generated__/VoyageDetails_passengersfrag_graphql.bs.js");

var convertFragment = VoyageDetails_passengersfrag_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(VoyageDetails_passengersfrag_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, VoyageDetails_passengersfrag_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(VoyageDetails_passengersfrag_graphql$Thick.node, convertFragment, fRef);
}

var PassengersFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function map(voyageSlug, voyageName, voyStartDate, voyEndDate, onlyBeforeOpt, excludeVoyageSlugsOpt) {
  var onlyBefore = onlyBeforeOpt !== undefined ? Caml_option.valFromOption(onlyBeforeOpt) : (function (prim) {
        return new Date();
      });
  var excludeVoyageSlugs = excludeVoyageSlugsOpt !== undefined ? excludeVoyageSlugsOpt : [];
  var isExcluded = Belt_Array.keep(excludeVoyageSlugs, (function (v) {
          return Caml_obj.equal(v, voyageSlug);
        })).length !== 0 || Belt_Option.mapWithDefault(onlyBefore, false, (function (fn) {
          return DateFns.isBefore(DateOnly$Util.toDate(voyStartDate), fn());
        }));
  if (!isExcluded) {
    return {
            voyageSlug: voyageSlug,
            voyageName: Belt_Option.getWithDefault(voyageName, CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive(CS_Slugs$Util.VoyageSlug.toString(voyageSlug))),
            voyStartDate: voyStartDate,
            voyEndDate: voyEndDate,
            image: {
              alt: "Voyage Image",
              src: "https://via.placeholder.com/150"
            }
          };
  }
  
}

function mapPassengersFragment(f, onlyBefore, excludeVoyageSlugs) {
  return Belt_Array.keepMap(Belt_Array.keepMap(Belt_Array.keepMap(f.nodes, (function (param) {
                        return param.booking_version;
                      })), (function (param) {
                    return param.voyage;
                  })), (function (param) {
                return map(param.voyageSlug, param.voyageName, param.voyStartDate, param.voyEndDate, onlyBefore, excludeVoyageSlugs);
              }));
}

function usePassengersFragment(fragmentRefs, onlyBefore, excludeVoyageSlugs) {
  return Belt_Array.keepMap(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.mapWithDefault(useOpt(fragmentRefs), [], (function (param) {
                            return param.nodes;
                          })), (function (param) {
                        return param.booking_version;
                      })), (function (param) {
                    return param.voyage;
                  })), (function (param) {
                return map(param.voyageSlug, param.voyageName, param.voyStartDate, param.voyEndDate, onlyBefore, excludeVoyageSlugs);
              }));
}

exports.PassengersFragment = PassengersFragment;
exports.map = map;
exports.mapPassengersFragment = mapPassengersFragment;
exports.usePassengersFragment = usePassengersFragment;
/* date-fns Not a pure module */
