// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../components/common/atoms/Spinner.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var BookingSession$Thick = require("../utils/BookingSession.bs.js");
var DashboardLayout$Thick = require("../layouts/DashboardLayout.bs.js");
var OrgProtectedRoute$Thick = require("../components/wrappers/OrgProtectedRoute.bs.js");
var BrandPermProtected$Thick = require("../components/wrappers/BrandPermProtected.bs.js");
var BookingSessionsPage$Thick = require("../pages/BookingSessionsPage.bs.js");
var VoyageProtectedRoute$Thick = require("../components/wrappers/VoyageProtectedRoute.bs.js");
var BookingSessionsRouteQuery_graphql$Thick = require("../__generated__/BookingSessionsRouteQuery_graphql.bs.js");

var convertVariables = BookingSessionsRouteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BookingSessionsRouteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BookingSessionsRouteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, BookingSessionsRouteQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BookingSessionsRouteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BookingSessionsRouteQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BookingSessionsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BookingSessionsRouteQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BookingSessionsRouteQuery_graphql$Thick.node, convertVariables);

var Query_accountStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.accountStatus_decode;

var Query_accountStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.accountStatus_fromString;

var Query_billingKind_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingSessionPassengerType_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Query_bookingSessionPassengerType_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Query_brandfamRole_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.brandfamRole_decode;

var Query_brandfamRole_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.brandfamRole_fromString;

var Query_custSignonStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.custSignonStatus_decode;

var Query_custSignonStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.custSignonStatus_fromString;

var Query_docGender_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_invitationType_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.invitationType_decode;

var Query_invitationType_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.invitationType_fromString;

var Query_lifecycleStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.lifecycleStatus_decode;

var Query_lifecycleStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.lifecycleStatus_fromString;

var Query_longitudinality_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_paxDataStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.paxDataStatus_decode;

var Query_paxDataStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.paxDataStatus_fromString;

var Query_pmtDirection_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtDirection_decode;

var Query_pmtDirection_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtDirection_fromString;

var Query_pmtLifecycleStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_signonStatus_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.signonStatus_decode;

var Query_signonStatus_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.signonStatus_fromString;

var Query_subtotalCategory_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query_versionProvenance_decode = BookingSessionsRouteQuery_graphql$Thick.Utils.versionProvenance_decode;

var Query_versionProvenance_fromString = BookingSessionsRouteQuery_graphql$Thick.Utils.versionProvenance_fromString;

var Query = {
  accountStatus_decode: Query_accountStatus_decode,
  accountStatus_fromString: Query_accountStatus_fromString,
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingSessionPassengerType_decode: Query_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Query_bookingSessionPassengerType_fromString,
  brandfamRole_decode: Query_brandfamRole_decode,
  brandfamRole_fromString: Query_brandfamRole_fromString,
  custSignonStatus_decode: Query_custSignonStatus_decode,
  custSignonStatus_fromString: Query_custSignonStatus_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  invitationType_decode: Query_invitationType_decode,
  invitationType_fromString: Query_invitationType_fromString,
  lifecycleStatus_decode: Query_lifecycleStatus_decode,
  lifecycleStatus_fromString: Query_lifecycleStatus_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  paxDataStatus_decode: Query_paxDataStatus_decode,
  paxDataStatus_fromString: Query_paxDataStatus_fromString,
  pmtDirection_decode: Query_pmtDirection_decode,
  pmtDirection_fromString: Query_pmtDirection_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  signonStatus_decode: Query_signonStatus_decode,
  signonStatus_fromString: Query_signonStatus_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  versionProvenance_decode: Query_versionProvenance_decode,
  versionProvenance_fromString: Query_versionProvenance_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BookingSessionsRoute(props) {
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var filter_brandFamilySlug = {
    equalTo: CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
  };
  var filter_voyageSlug = {
    equalTo: CS_Slugs$Util.VoyageSlug.toString(voyage)
  };
  var filter = {
    brandFamilySlug: filter_brandFamilySlug,
    voyageSlug: filter_voyageSlug
  };
  var data = use({
        brandFamily: brandFamily,
        filter: filter,
        voyage: voyage
      }, undefined, undefined, undefined);
  var fragmentRefs = data.fragmentRefs;
  var state = HopperState$Util.Observable.useStore((function () {
          return BookingSession$Thick.blankModel(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined));
        }), true);
  HopperState$Util.Observable.useManagedQueryString(state, BookingSession$Thick.QueryString.queryStateLens, {
        read: (function (m) {
            return m.url;
          }),
        write: (function (m, url) {
            return {
                    form: m.form,
                    paginationCursor: m.paginationCursor,
                    url: url,
                    focusedSession: m.focusedSession,
                    saveError: m.saveError
                  };
          })
      }, BookingSession$Thick.QueryString.getParser(RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined)), (function (param) {
          var path = param.path;
          if (!path) {
            return false;
          }
          var match = path.tl;
          if (!match) {
            return false;
          }
          var match$1 = match.tl;
          if (match$1 && match$1.hd === "booking-sessions" && !match$1.tl) {
            return true;
          } else {
            return false;
          }
        }), 555);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  HopperState$Util.Observable.notify(state, (function (s) {
          return {
                  form: s.form,
                  paginationCursor: s.paginationCursor,
                  url: url,
                  focusedSession: s.focusedSession,
                  saveError: s.saveError
                };
        }));
  return JsxRuntime.jsx(DashboardLayout$Thick.make, {
              fragmentRefs: fragmentRefs,
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(OrgProtectedRoute$Thick.make, {
                              fragmentRefs: fragmentRefs,
                              children: JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                    fragmentRefs: fragmentRefs,
                                    neededPerm: "VIEW_BOOKINGS",
                                    children: JsxRuntime.jsx(VoyageProtectedRoute$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          brandFamily: brandFamily,
                                          voyage: voyage,
                                          children: JsxRuntime.jsx(BookingSessionsPage$Thick.make, {
                                                fragmentRefs: fragmentRefs,
                                                brandFamily: brandFamily,
                                                voyage: voyage,
                                                filter: filter,
                                                state: state
                                              })
                                        }),
                                    isRoute: true,
                                    redirect: "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily)
                                  })
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
                  }),
              voyage: Caml_option.some(voyage)
            });
}

var make = BookingSessionsRoute;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
