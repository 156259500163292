// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var ReactState$Util = require("util/src/ReactState.bs.js");
var RemoteData$Util = require("util/src/RemoteData.bs.js");

function useMutation(refetch) {
  var localRequestState = React.useState(function () {
        return "NotAsked";
      });
  var isMutating = ReactState$Util.getter(localRequestState) === "Loading";
  var match = React.useState(function () {
        return "";
      });
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var password = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    RemoteData$Util.$$Promise.makeStatefulEffect(Common$Thick.cookieLogin({
              allegedEmail: email,
              allegedPass: password
            }, Caml_option.some((function () {
                    refetch();
                  })), Caml_option.some((function (eString) {
                    setError(function (param) {
                          console.log(eString);
                          return eString;
                        });
                  }))), localRequestState);
  };
  return {
          isMutating: isMutating,
          email: email,
          setEmail: match[1],
          password: password,
          setPassword: match$1[1],
          error: match$2[0],
          onSubmit: onSubmit
        };
}

exports.useMutation = useMutation;
/* react Not a pure module */
