// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Belt_MapString = require("rescript/lib/js/belt_MapString.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var Belt_HashMapString = require("rescript/lib/js/belt_HashMapString.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var DiffListQuery_graphql$Thick = require("../../../__generated__/DiffListQuery_graphql.bs.js");

var convertVariables = DiffListQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = DiffListQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = DiffListQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, DiffListQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, DiffListQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(DiffListQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(DiffListQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(DiffListQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(DiffListQuery_graphql$Thick.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function DiffList(props) {
  var __size = props.size;
  var versionBilling = props.versionBilling;
  var diffStruct = props.diffStruct;
  var size = __size !== undefined ? __size : "sm";
  var match = React.useState(function () {
        
      });
  var setReplacementPaxes = match[1];
  var replacementPaxes = match[0];
  var match$1 = diffStruct.finalPlacement;
  var finalPlacement = match$1 !== undefined ? (
      match$1 ? "Mark cabin placement as finalized" : "Mark cabin placement as not finalized"
    ) : undefined;
  var str = diffStruct.placementComment;
  var placementComment = str !== undefined ? "Update public cabin placement comment" : undefined;
  var berths = diffStruct.addBerths;
  var addBerthsInitial = berths !== undefined ? "Add " + String(berths) + " berth(s)" : undefined;
  var addBerths = React.useDeferredValue(addBerthsInitial);
  var removeNegative = function (str) {
    var match = str[0];
    if (match === "-") {
      return str.slice(1);
    } else {
      return str;
    }
  };
  var paxIdxAndName = Belt_HashMapString.fromArray(Belt_Array.keepMap(props.paxes.nodes, (function (p) {
              var user = p.bfcustUser;
              if (user !== undefined) {
                return [
                        String(p.idx),
                        user.fullName
                      ];
              }
              
            })));
  var berths$1 = diffStruct.dropBerths;
  var dropBerthsInitial;
  if (berths$1 !== undefined) {
    var removedPaxes = Belt_Array.map(berths$1, (function (idx) {
            var str = String(idx);
            var name = Belt_HashMapString.get(paxIdxAndName, str);
            if (name !== undefined) {
              return name;
            } else {
              return "Passenger " + String(idx);
            }
          }));
    dropBerthsInitial = "Remove berths occupied by: " + removedPaxes.join(", ") + ".";
  } else {
    dropBerthsInitial = undefined;
  }
  var dropBerths = React.useDeferredValue(dropBerthsInitial);
  var berths$2 = diffStruct.reindexPax;
  var reindexPaxInitial;
  if (berths$2 !== undefined) {
    var reindexPaxes = Belt_Array.map(berths$2, (function (idx) {
            var str = String(idx);
            var name = Belt_HashMapString.get(paxIdxAndName, str);
            if (name !== undefined) {
              return name;
            } else {
              return "Passenger " + String(idx);
            }
          }));
    reindexPaxInitial = "Reorder passengers to start with: " + reindexPaxes.join(", ") + ".";
  } else {
    reindexPaxInitial = undefined;
  }
  var reindexPax = React.useDeferredValue(reindexPaxInitial);
  var cabin = diffStruct.changeCabinU;
  var changeCabinUnbookedInitial = cabin !== undefined ? "Change cabin to " + CS_NonemptyStrings$Util.CabinNumber.toString(Caml_option.valFromOption(cabin)) + "." : undefined;
  var changeCabinUnbooked = React.useDeferredValue(changeCabinUnbookedInitial);
  var berths$3 = diffStruct.detachPax;
  var detachPaxInitial;
  if (berths$3 !== undefined) {
    var detachedPaxes = Belt_Array.map(berths$3, (function (idx) {
            var str = String(idx);
            var name = Belt_HashMapString.get(paxIdxAndName, str);
            if (name !== undefined) {
              return name;
            } else {
              return "Passenger " + String(idx);
            }
          }));
    detachPaxInitial = "Detach the following passengers from their berth(s): " + detachedPaxes.join(", ") + ".";
  } else {
    detachPaxInitial = undefined;
  }
  var detachPax = React.useDeferredValue(detachPaxInitial);
  var acctId = diffStruct.changeBillingContact;
  var bc;
  if (acctId !== undefined) {
    var str$1 = CS_NonemptyStrings$Util.AccountId.toString(Caml_option.valFromOption(acctId));
    var pax = Belt_MapString.get(replacementPaxes, str$1);
    bc = pax !== undefined ? "Change Billing Contact to " + pax.fullName + "." : "Change Billing Contact to " + str$1 + ".";
  } else {
    bc = undefined;
  }
  var bc$1 = React.useDeferredValue(bc);
  var map = diffStruct.attachExistingPax;
  var attachExistingPaxInitial;
  if (map !== undefined) {
    var paxes = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map)), (function (param) {
            var str = CS_NonemptyStrings$Util.AccountId.toString(param[1]);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax !== undefined) {
              return pax.fullName;
            } else {
              return str;
            }
          }));
    attachExistingPaxInitial = "Attach the following existing passenger(s): " + paxes.join(", ") + ".";
  } else {
    attachExistingPaxInitial = undefined;
  }
  var attachExistingPax = React.useDeferredValue(attachExistingPaxInitial);
  var map$1 = diffStruct.attachInvitedPax;
  var attachInvitedPaxInitial;
  if (map$1 !== undefined) {
    var paxes$1 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$1)), (function (param) {
            var p = param[1];
            var lastName = p.lastName;
            var firstName = p.firstName;
            var email = p.email;
            if (lastName !== undefined) {
              return CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) + " <" + CS_Emails$Util.Email.toString(email) + ">";
            } else {
              return CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + (" <" + CS_Emails$Util.Email.toString(email) + ">");
            }
          }));
    attachInvitedPaxInitial = "Invite the following passenger(s): " + paxes$1.join(", ") + ".";
  } else {
    attachInvitedPaxInitial = undefined;
  }
  var attachInvitedPax = React.useDeferredValue(attachInvitedPaxInitial);
  var map$2 = diffStruct.attachNewConnectedPax;
  var attachNewConnectedPaxInitial;
  if (map$2 !== undefined) {
    var paxes$2 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$2)), (function (param) {
            var paxNewConnected = param[1];
            var match = DraftUtils$Thick.generateNewConnectedNames(paxNewConnected);
            var custodian = CS_NonemptyStrings$Util.AccountId.toString(paxNewConnected.custodianAccountId);
            var pax = Belt_MapString.get(replacementPaxes, custodian);
            var custodianName = pax !== undefined ? pax.fullName : custodian;
            return "Passenger " + Belt_Option.getWithDefault(match.fullName, String(param[0])) + " (managed by " + custodianName + ")";
          }));
    attachNewConnectedPaxInitial = "Attach the following new connected passenger(s): " + paxes$2.join("; ") + ".";
  } else {
    attachNewConnectedPaxInitial = undefined;
  }
  var attachNewConnectedPax = React.useDeferredValue(attachNewConnectedPaxInitial);
  var map$3 = diffStruct.replacePaxExisting;
  var replaceExistingPaxInitial;
  if (map$3 !== undefined) {
    var paxes$3 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$3)), (function (param) {
            var idx = param[0];
            var str = CS_NonemptyStrings$Util.AccountId.toString(param[1]);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax === undefined) {
              return str;
            }
            var name = Belt_HashMapString.get(paxIdxAndName, String(idx));
            if (name !== undefined) {
              return "Replace " + name + " with " + pax.fullName;
            } else {
              return "Replace passenger " + String(idx) + " with " + pax.fullName;
            }
          }));
    replaceExistingPaxInitial = "Make the following passenger substitutions with existing users: " + paxes$3.join("; ") + ".";
  } else {
    replaceExistingPaxInitial = undefined;
  }
  var replaceExistingPax = React.useDeferredValue(replaceExistingPaxInitial);
  var map$4 = diffStruct.replaceInvitedPax;
  var replaceInvitedPaxInitial;
  if (map$4 !== undefined) {
    var paxes$4 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$4)), (function (param) {
            var p = param[1];
            var lastName = p.lastName;
            var firstName = p.firstName;
            var email = p.email;
            var idx = param[0];
            var invitee = lastName !== undefined ? CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + " " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(lastName)) + " <" + CS_Emails$Util.Email.toString(email) + ">" : CS_NonemptyStrings$Util.NonEmptyString.toString(firstName) + (" <" + CS_Emails$Util.Email.toString(email) + ">");
            var name = Belt_HashMapString.get(paxIdxAndName, String(idx));
            if (name !== undefined) {
              return "Replace " + name + " with " + invitee;
            } else {
              return "Replace passenger " + String(idx) + " with " + invitee;
            }
          }));
    replaceInvitedPaxInitial = "Make the following passenger substitutions via email invitation: " + paxes$4.join("; ") + ".";
  } else {
    replaceInvitedPaxInitial = undefined;
  }
  var replaceInvitedPax = React.useDeferredValue(replaceInvitedPaxInitial);
  var map$5 = diffStruct.replaceNewConnectedPax;
  var replaceNewConnectedPaxInitial;
  if (map$5 !== undefined) {
    var paxes$5 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$5)), (function (param) {
            var paxNewConnected = param[1];
            var idx = param[0];
            var match = DraftUtils$Thick.generateNewConnectedNames(paxNewConnected);
            var custodian = CS_NonemptyStrings$Util.AccountId.toString(paxNewConnected.custodianAccountId);
            var pax = Belt_MapString.get(replacementPaxes, custodian);
            var custodianName = pax !== undefined ? pax.fullName : custodian;
            var newPaxName = Belt_Option.getWithDefault(match.fullName, String(idx)) + " (managed by " + custodianName + ")";
            var name = Belt_HashMapString.get(paxIdxAndName, String(idx));
            if (name !== undefined) {
              return "Replace " + name + " with " + newPaxName;
            } else {
              return "Replace passenger " + String(idx) + " with " + newPaxName;
            }
          }));
    replaceNewConnectedPaxInitial = "Make the following passenger substitutions with new connected account(s): " + paxes$5.join("; ") + ".";
  } else {
    replaceNewConnectedPaxInitial = undefined;
  }
  var replaceNewConnectedPax = React.useDeferredValue(replaceNewConnectedPaxInitial);
  var map$6 = diffStruct.revokePaxInvitation;
  var revokePaxInvitationInitial;
  if (map$6 !== undefined) {
    var paxes$6 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$6)), (function (param) {
            var str = CS_NonemptyStrings$Util.AccountId.toString(param[1]);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax === undefined) {
              return "Passenger " + String(param[0]) + " <Account " + str + ">";
            }
            var email = pax.invitationEmail;
            if (email !== undefined) {
              return pax.fullName + " <" + email + ">";
            } else {
              return pax.fullName;
            }
          }));
    revokePaxInvitationInitial = "Revoke the following passenger invitation(s): " + paxes$6.join(", ") + ".";
  } else {
    revokePaxInvitationInitial = undefined;
  }
  var map$7 = diffStruct.recordManualRefunds;
  var recordManualRefundsInitial;
  if (map$7 !== undefined) {
    var paxes$7 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$7)), (function (param) {
            var ref = param[1];
            var str = CS_NonemptyStrings$Util.AccountId.toString(ref.payee);
            var pax = Belt_MapString.get(replacementPaxes, str);
            if (pax !== undefined) {
              return "$" + removeNegative(CS_Decimal$Util.Decimal10_2.toString(ref.netRefundedAmount)) + (
                      Caml_obj.equal(ref.netRefundedBfFeeAmount, CS_Decimal$Util.Decimal10_2.Exn.fromPrimitive("0.00")) ? "" : "($" + removeNegative(CS_Decimal$Util.Decimal10_2.toString(ref.netRefundedBfFeeAmount)) + " fee)"
                    ) + " to " + pax.fullName;
            } else {
              return "Passenger " + String(param[0]) + " <Account " + str + ">";
            }
          }));
    recordManualRefundsInitial = "Issue the following manual refunds: " + paxes$7.join(", ") + ".";
  } else {
    recordManualRefundsInitial = undefined;
  }
  var recordManualRefunds = React.useDeferredValue(recordManualRefundsInitial);
  var revokePaxInvitation = React.useDeferredValue(revokePaxInvitationInitial);
  var arr = diffStruct.suppressManualBillingLines;
  var suppressManualBillingLinesInitial;
  if (arr !== undefined) {
    var listStr = versionBilling !== undefined ? Belt_Array.map(arr, (function (v) {
                var line = Belt_Array.get(Belt_Array.keep(versionBilling.billing.billingLines, (function (line) {
                            return line.billingIdx === v;
                          })), 0);
                if (line !== undefined) {
                  return line.billingLineDescription + " (" + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(line.lineAmount)) + ")";
                } else {
                  return String(v);
                }
              })).join("; ") : Belt_Array.map(arr, (function (idx) {
                return String(idx);
              })).join(", ");
    suppressManualBillingLinesInitial = "Suppress the following manual billing line(s): " + listStr + ".";
  } else {
    suppressManualBillingLinesInitial = undefined;
  }
  var suppressManualBillingLines = React.useDeferredValue(suppressManualBillingLinesInitial);
  var map$8 = diffStruct.addManualBillingLines;
  var addManualBillingLinesInitial;
  if (map$8 !== undefined) {
    var listStr$1 = Belt_Array.map(Belt_MapString.toArray(Caml_option.valFromOption(map$8)), (function (param) {
              var match = param[1];
              return CS_NonemptyStrings$Util.NonEmptyString.toPrimitive(match.description) + " (" + Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(match.amount)) + ")";
            })).join("; ");
    addManualBillingLinesInitial = "Add the following manual billing line(s): " + listStr$1 + ".";
  } else {
    addManualBillingLinesInitial = undefined;
  }
  var addManualBillingLines = React.useDeferredValue(addManualBillingLinesInitial);
  var map$9 = diffStruct.suppressAutoBillingLines;
  var suppressAutoBillingLinesInitial;
  if (map$9 !== undefined) {
    var listStr$2 = Belt_Array.map(Belt_MapString.toArray(Caml_option.valFromOption(map$9)), (function (param) {
              var match = param[1];
              var nSuppressed = match.nSuppressed;
              var hookFiredAt = match.hookFiredAt;
              var fareRuleSlug = match.fareRuleSlug;
              if (versionBilling === undefined) {
                return CS_Slugs$Util.FareRuleSlug.toPrimitive(fareRuleSlug) + " x " + String(nSuppressed) + " suppressed";
              }
              var l = Belt_Array.get(Belt_Array.keep(versionBilling.billing.billingLines, (function (line) {
                          if (Caml_obj.equal(line.fareRuleSlug, Caml_option.some(fareRuleSlug))) {
                            return Caml_obj.equal(line.hookFiredAt, hookFiredAt);
                          } else {
                            return false;
                          }
                        })), 0);
              if (l !== undefined) {
                return "\"" + l.billingLineDescription + "\" x" + String((l.ruleApplicationCount + l.suppressedCount | 0) - nSuppressed | 0);
              } else {
                return CS_Slugs$Util.FareRuleSlug.toPrimitive(fareRuleSlug) + " x " + String(nSuppressed) + " suppressed";
              }
            })).join("; ");
    suppressAutoBillingLinesInitial = "Make the following rule application change(s): " + listStr$2 + ".";
  } else {
    suppressAutoBillingLinesInitial = undefined;
  }
  var suppressAutoBillingLines = React.useDeferredValue(suppressAutoBillingLinesInitial);
  var match$2 = diffStruct.updateBookingPreferences;
  var updateBookingPreferences = match$2 !== undefined ? "Update booking preferences." : undefined;
  var cabin$1 = diffStruct.changeCabinB;
  var changeCabinBookedInitial = cabin$1 !== undefined ? "Change cabin to " + CS_NonemptyStrings$Util.CabinNumber.toString(cabin$1.cabinNumber) + ", currently held by booking " + CS_NonemptyStrings$Util.BookingId.toString(cabin$1.bookingId) + "." : undefined;
  var changeCabinBooked = React.useDeferredValue(changeCabinBookedInitial);
  var map$10 = diffStruct.patchPax;
  var patchPaxInitial;
  if (map$10 !== undefined) {
    var paxes$8 = Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(map$10)), (function (param) {
            var match = param[1];
            var accountString = "<Account " + CS_NonemptyStrings$Util.AccountId.toString(match.accountId) + ">";
            var match$1 = Belt_Option.map(match.pDocFirstName, CS_NonemptyStrings$Util.NonEmptyString.toString);
            var match$2 = Belt_Option.map(match.pDocLastName, CS_NonemptyStrings$Util.NonEmptyString.toString);
            if (match$1 !== undefined) {
              if (match$2 !== undefined) {
                return match$1 + " " + match$2 + " " + accountString;
              } else {
                return match$1 + " " + accountString;
              }
            } else if (match$2 !== undefined) {
              return match$2 + " " + accountString;
            } else {
              return accountString;
            }
          }));
    patchPaxInitial = "Update details for the following passenger(s): " + paxes$8.join(", ") + ".";
  } else {
    patchPaxInitial = undefined;
  }
  var patchPax = React.useDeferredValue(patchPaxInitial);
  var arr$1 = diffStruct.movePayments;
  var movePaymentsInitial;
  if (arr$1 !== undefined) {
    var moved = Belt_Array.map(arr$1, (function (v) {
            return CS_NonemptyStrings$Util.PmtTransactionId.toString(v.publicPmtId) + " to " + CS_NonemptyStrings$Util.BookingId.toString(v.toBookingId);
          }));
    movePaymentsInitial = "Move the following payment(s) from this booking: " + moved.join(", ") + ".";
  } else {
    movePaymentsInitial = undefined;
  }
  var movePayments = React.useDeferredValue(movePaymentsInitial);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  React.useEffect((function () {
          var match = diffStruct.changeBillingContact;
          var match$1 = diffStruct.attachExistingPax;
          var match$2 = diffStruct.replacePaxExisting;
          var match$3 = diffStruct.attachNewConnectedPax;
          var match$4 = diffStruct.replaceNewConnectedPax;
          var match$5 = diffStruct.revokePaxInvitation;
          var match$6 = diffStruct.recordManualRefunds;
          var exit = 0;
          if (match !== undefined || match$1 !== undefined || match$2 !== undefined || match$3 !== undefined || match$4 !== undefined || match$5 !== undefined || match$6 !== undefined) {
            exit = 1;
          } else {
            setReplacementPaxes(function (param) {
                  
                });
          }
          if (exit === 1) {
            var bc = match !== undefined ? [Caml_option.valFromOption(match)] : [];
            var attachEx = match$1 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$1)), (function (param) {
                      return param[1];
                    })) : [];
            var replaceEx = match$2 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$2)), (function (param) {
                      return param[1];
                    })) : [];
            var attachCon = match$3 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$3)), (function (param) {
                      return param[1].custodianAccountId;
                    })) : [];
            var replaceCon = match$4 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$4)), (function (param) {
                      return param[1].custodianAccountId;
                    })) : [];
            var revokeInv = match$5 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$5)), (function (param) {
                      return param[1];
                    })) : [];
            var manRef = match$6 !== undefined ? Belt_Array.map(Belt_MapInt.toArray(Caml_option.valFromOption(match$6)), (function (param) {
                      return param[1].payee;
                    })) : [];
            var acctIds = Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(Belt_Array.concat(bc, attachEx), replaceEx), attachCon), replaceCon), revokeInv), manRef);
            $$fetch(RelayEnv$Thick.environment, {
                  acctIds: acctIds
                }, (function (res) {
                    if (res.TAG === "Ok") {
                      var match = res._0.bfcustUsers;
                      if (match !== undefined) {
                        var arr = Belt_MapString.fromArray(Belt_Array.map(match.nodes, (function (acct) {
                                    return [
                                            CS_NonemptyStrings$Util.AccountId.toString(acct.accountId),
                                            {
                                              accountId: CS_NonemptyStrings$Util.AccountId.toString(acct.accountId),
                                              fullName: acct.fullName,
                                              invitationEmail: Belt_Option.map(acct.invitationEmail, (function (email) {
                                                      return CS_Emails$Util.Email.toString(email);
                                                    }))
                                            }
                                          ];
                                  })));
                        setReplacementPaxes(function (param) {
                              return arr;
                            });
                      } else {
                        console.log("nothing doing");
                      }
                      return ;
                    }
                    console.error(res._0);
                  }), undefined, undefined);
          }
          
        }), [url.search]);
  var reason = diffStruct.cancellationReason;
  return JsxRuntime.jsxs(List, {
              children: [
                reason !== undefined ? JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                  color: "danger",
                                  level: size === "sm" ? "body-sm" : "body-md",
                                  children: Caml_option.some("When this draft is applied, the current booking will be canceled for the following reason: " + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(reason)) + ".")
                                }))
                      }) : null,
                Belt_Array.mapWithIndex([
                      changeCabinUnbooked,
                      changeCabinBooked,
                      finalPlacement,
                      placementComment,
                      addBerths,
                      dropBerths,
                      reindexPax,
                      detachPax,
                      bc$1,
                      attachExistingPax,
                      attachInvitedPax,
                      attachNewConnectedPax,
                      replaceExistingPax,
                      replaceInvitedPax,
                      replaceNewConnectedPax,
                      revokePaxInvitation,
                      updateBookingPreferences,
                      suppressManualBillingLines,
                      addManualBillingLines,
                      suppressAutoBillingLines,
                      patchPax,
                      recordManualRefunds,
                      movePayments
                    ], (function (i, update) {
                        if (update !== undefined) {
                          return JsxRuntime.jsx(ListItem, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: size === "sm" ? "body-sm" : "body-md",
                                                children: Caml_option.some(update)
                                              }))
                                    }, String(i));
                        } else {
                          return null;
                        }
                      }))
              ]
            });
}

var make = DiffList;

exports.Query = Query;
exports.make = make;
/* use Not a pure module */
