// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Divider = require("@mui/joy/Divider").default;
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var Textarea = require("@mui/joy/Textarea").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;

function BDEditPlacementCommentModal(props) {
  var initialComment = props.initialComment;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match = React.useState(function () {
        var cmt = diffStruct.placementComment;
        if (cmt !== undefined) {
          return cmt;
        } else {
          return initialComment;
        }
      });
  var setComment = match[1];
  var comment = match[0];
  var clearAll = function () {
    setComment(function (param) {
          var cmt = diffStruct.placementComment;
          if (cmt !== undefined) {
            return cmt;
          } else {
            return initialComment;
          }
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
  };
  var onSubmit = function (e) {
    e.preventDefault();
    var applyDiff = BookingDiff$Thick.Alter.changePlacementComment(diffStruct, comment);
    DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
    onClose();
  };
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Dialog$Thick.make, {
                        open_: props.open_,
                        onClose: cancel,
                        title: "Update placement comment",
                        children: [
                          JsxRuntime.jsx(DialogContent, {
                                children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                          spacing: 2,
                                          sx: {
                                            py: 1
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(FormControl, {
                                                    error: false,
                                                    children: Caml_option.some(JsxRuntime.jsx(Textarea, {
                                                              value: comment,
                                                              required: true,
                                                              onChange: (function (e) {
                                                                  var val = e.currentTarget.value;
                                                                  setComment(function (param) {
                                                                        return val;
                                                                      });
                                                                }),
                                                              minRows: 3
                                                            }))
                                                  }))
                                        }))
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsx(DialogActions, {
                                children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                          direction: {
                                            xs: "column-reverse",
                                            sm: "row"
                                          },
                                          component: "form",
                                          justifyContent: "space-between",
                                          spacing: 1,
                                          sx: {
                                            width: "100%"
                                          },
                                          onSubmit: onSubmit,
                                          children: [
                                            JsxRuntime.jsx(Button, {
                                                  onClick: cancel,
                                                  variant: "outlined",
                                                  color: "neutral",
                                                  children: "Cancel"
                                                }),
                                            JsxRuntime.jsx(Button, {
                                                  type: "submit",
                                                  children: "Add to draft"
                                                })
                                          ]
                                        }))
                              })
                        ]
                      }))
            });
}

var make = BDEditPlacementCommentModal;

exports.make = make;
/* react Not a pure module */
