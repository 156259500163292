// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../../utils/Common.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDBillPayTable(props) {
  var __showCanceledActions = props.showCanceledActions;
  var pendingTotal = props.pendingTotal;
  var columns = props.columns;
  var showCanceledActions = __showCanceledActions !== undefined ? __showCanceledActions : false;
  var shouldRender = props.isActive && !props.snapshot && props.canManage;
  return JsxRuntime.jsxs(Table, {
              sx: {
                "& th": {
                  py: 0
                },
                "& td": {
                  py: 0
                }
              },
              children: [
                JsxRuntime.jsx("thead", {
                      children: JsxRuntime.jsx("tr", {
                            children: Belt_Array.mapWithIndex(columns, (function (i, param) {
                                    var heading = param.heading;
                                    return JsxRuntime.jsx("th", {
                                                children: JsxRuntime.jsx(Stack, {
                                                      direction: "row",
                                                      justifyContent: heading === "Amount" || i === (columns.length - 1 | 0) ? "flex-end" : "flex-start",
                                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                level: "body-xs",
                                                                textColor: "neutral.500",
                                                                children: Caml_option.some(heading)
                                                              }))
                                                    }),
                                                style: {
                                                  width: param.width
                                                }
                                              }, String(i));
                                  }))
                          })
                    }),
                JsxRuntime.jsx("tbody", {
                      children: props.children
                    }),
                JsxRuntime.jsxs("tfoot", {
                      children: [
                        JsxRuntime.jsxs("tr", {
                              children: [
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(Typography, {
                                            level: "body-sm",
                                            fontWeight: "bold",
                                            children: Caml_option.some(props.totalDescription)
                                          }),
                                      style: {
                                        backgroundColor: "inherit"
                                      }
                                    }),
                                JsxRuntime.jsx("td", {
                                      children: JsxRuntime.jsx(Stack, {
                                            direction: "row",
                                            justifyContent: "flex-end",
                                            sx: {
                                              width: "100%"
                                            },
                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      fontWeight: "bold",
                                                      children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(props.total)))
                                                    }))
                                          }),
                                      style: {
                                        backgroundColor: "inherit"
                                      },
                                      colSpan: shouldRender || showCanceledActions ? columns.length - 2 | 0 : columns.length - 1 | 0
                                    }),
                                shouldRender || showCanceledActions ? JsxRuntime.jsx("td", {
                                        style: {
                                          backgroundColor: "inherit"
                                        }
                                      }) : null
                              ]
                            }),
                        CS_Decimal$Util.Decimal10_2.toFloat(pendingTotal) > 0.0 ? JsxRuntime.jsxs("tr", {
                                children: [
                                  JsxRuntime.jsx("td", {
                                        children: JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              sx: {
                                                fontStyle: "italic"
                                              },
                                              fontWeight: "normal",
                                              children: Caml_option.some(props.pendingDescription)
                                            }),
                                        style: {
                                          backgroundColor: "inherit",
                                          borderColor: "transparent"
                                        }
                                      }),
                                  JsxRuntime.jsx("td", {
                                        children: JsxRuntime.jsx(Stack, {
                                              direction: "row",
                                              justifyContent: "flex-end",
                                              sx: {
                                                width: "100%"
                                              },
                                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                        level: "body-sm",
                                                        sx: {
                                                          fontStyle: "italic"
                                                        },
                                                        fontWeight: "normal",
                                                        children: Caml_option.some(Common$Thick.formatCurrency(CS_Decimal$Util.Decimal10_2.toFloat(pendingTotal)))
                                                      }))
                                            }),
                                        style: {
                                          backgroundColor: "inherit",
                                          borderColor: "transparent"
                                        },
                                        colSpan: shouldRender || showCanceledActions ? columns.length - 2 | 0 : columns.length - 1 | 0
                                      }),
                                  shouldRender || showCanceledActions ? JsxRuntime.jsx("td", {
                                          style: {
                                            backgroundColor: "inherit",
                                            borderColor: "transparent"
                                          }
                                        }) : null
                                ],
                                style: {
                                  borderColor: "transparent"
                                }
                              }) : null
                      ]
                    })
              ]
            });
}

var make = BDBillPayTable;

exports.make = make;
/* Common-Thick Not a pure module */
