// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ApplyDraft_ApplyBookingChangesMutation_graphql$Thick = require("../../../../__generated__/ApplyDraft_ApplyBookingChangesMutation_graphql.bs.js");

var convertVariables = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var ApplyBookingChangesMutation_bookingDiffError_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.bookingDiffError_decode;

var ApplyBookingChangesMutation_bookingDiffError_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.bookingDiffError_fromString;

var ApplyBookingChangesMutation_docGender_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.docGender_decode;

var ApplyBookingChangesMutation_docGender_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.docGender_fromString;

var ApplyBookingChangesMutation_elevatorProximity_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.elevatorProximity_decode;

var ApplyBookingChangesMutation_elevatorProximity_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.elevatorProximity_fromString;

var ApplyBookingChangesMutation_longitudinality_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.longitudinality_decode;

var ApplyBookingChangesMutation_longitudinality_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.longitudinality_fromString;

var ApplyBookingChangesMutation_pmtLifecycleStatus_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var ApplyBookingChangesMutation_pmtLifecycleStatus_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var ApplyBookingChangesMutation_pmtMethod_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtMethod_decode;

var ApplyBookingChangesMutation_pmtMethod_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtMethod_fromString;

var ApplyBookingChangesMutation_pmtProcessor_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtProcessor_decode;

var ApplyBookingChangesMutation_pmtProcessor_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.pmtProcessor_fromString;

var ApplyBookingChangesMutation_refundability_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.refundability_decode;

var ApplyBookingChangesMutation_refundability_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.refundability_fromString;

var ApplyBookingChangesMutation_subtotalCategory_decode = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.subtotalCategory_decode;

var ApplyBookingChangesMutation_subtotalCategory_fromString = ApplyDraft_ApplyBookingChangesMutation_graphql$Thick.Utils.subtotalCategory_fromString;

var ApplyBookingChangesMutation = {
  bookingDiffError_decode: ApplyBookingChangesMutation_bookingDiffError_decode,
  bookingDiffError_fromString: ApplyBookingChangesMutation_bookingDiffError_fromString,
  docGender_decode: ApplyBookingChangesMutation_docGender_decode,
  docGender_fromString: ApplyBookingChangesMutation_docGender_fromString,
  elevatorProximity_decode: ApplyBookingChangesMutation_elevatorProximity_decode,
  elevatorProximity_fromString: ApplyBookingChangesMutation_elevatorProximity_fromString,
  longitudinality_decode: ApplyBookingChangesMutation_longitudinality_decode,
  longitudinality_fromString: ApplyBookingChangesMutation_longitudinality_fromString,
  pmtLifecycleStatus_decode: ApplyBookingChangesMutation_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: ApplyBookingChangesMutation_pmtLifecycleStatus_fromString,
  pmtMethod_decode: ApplyBookingChangesMutation_pmtMethod_decode,
  pmtMethod_fromString: ApplyBookingChangesMutation_pmtMethod_fromString,
  pmtProcessor_decode: ApplyBookingChangesMutation_pmtProcessor_decode,
  pmtProcessor_fromString: ApplyBookingChangesMutation_pmtProcessor_fromString,
  refundability_decode: ApplyBookingChangesMutation_refundability_decode,
  refundability_fromString: ApplyBookingChangesMutation_refundability_fromString,
  subtotalCategory_decode: ApplyBookingChangesMutation_subtotalCategory_decode,
  subtotalCategory_fromString: ApplyBookingChangesMutation_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(applyArgs, redirect, versionComment) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = use();
  var mutate = match$1[0];
  var onSubmit = function (e) {
    e.preventDefault();
    setError(function (param) {
          
        });
    var versionComment$1 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(versionComment);
    var tmp;
    tmp = versionComment$1.TAG === "Ok" ? versionComment$1._0 : CS_NonemptyStrings$Util.NonEmptyString.Exn.fromPrimitive("No comment provided");
    mutate({
          input: {
            _voyageSlug: applyArgs.voyageSlug,
            _bookingId: applyArgs.bookingId,
            _bookingVersionNumber: applyArgs.bookingVersionNumber,
            _bookingDiff: applyArgs.bookingDiff,
            versionComment: tmp
          }
        }, undefined, undefined, (function (store, param) {
            store.invalidateStore();
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.applyBookingChanges;
              if (match !== undefined) {
                var affectedBookings = match.results;
                if (affectedBookings !== undefined) {
                  var exit = 0;
                  if (affectedBookings.length !== 1) {
                    exit = 2;
                  } else {
                    var match$1 = affectedBookings[0];
                    var err$1 = match$1.nullableErr;
                    if (err$1 !== undefined) {
                      setError(function (param) {
                            return err$1;
                          });
                      return ;
                    }
                    exit = 2;
                  }
                  if (exit === 2) {
                    var currentBooking = Belt_Array.get(Belt_Array.keep(affectedBookings, (function (x) {
                                return Caml_obj.equal(x.bookingId, Caml_option.some(applyArgs.bookingId));
                              })), 0);
                    var exit$1 = 0;
                    if (currentBooking !== undefined) {
                      var num = currentBooking.bookingVersionNumber;
                      if (num !== undefined) {
                        var url = redirect + "/" + String(num);
                        RescriptReactRouter.push(url);
                        return ;
                      }
                      exit$1 = 3;
                    } else {
                      exit$1 = 3;
                    }
                    if (exit$1 === 3) {
                      setError(function (param) {
                            return "Failed to apply changes";
                          });
                      return ;
                    }
                    
                  }
                  
                }
                
              }
              setError(function (param) {
                    return "Failed to apply changes";
                  });
              return ;
            }
            setError(function (param) {
                  return "Failed to apply changes";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "Something went wrong, please try again.";
                });
          }), undefined);
  };
  return {
          isMutating: match$1[1],
          error: match[0],
          onSubmit: onSubmit
        };
}

exports.ApplyBookingChangesMutation = ApplyBookingChangesMutation;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
