// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var Checkbox = require("@mui/joy/Checkbox").default;
var TableFilter$Thick = require("./TableFilter.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var HoldCabinForm$Thick = require("./HoldCabinForm.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Delete = require("@mui/icons-material/Delete").default;
var CabinInventoryFilters$Thick = require("../../utils/CabinInventoryFilters.bs.js");
var RemoveCabinHoldSubmit$Thick = require("./mutations/RemoveCabinHoldSubmit.bs.js");
var CabinTable_cabin_graphql$Thick = require("../../__generated__/CabinTable_cabin_graphql.bs.js");
var LuggageOutlined = require("@mui/icons-material/LuggageOutlined").default;
var CabinTableQuery_query_graphql$Thick = require("../../__generated__/CabinTableQuery_query_graphql.bs.js");
var LockClockOutlined = require("@mui/icons-material/LockClockOutlined").default;
var CabinTableQueryRefetchQuery_graphql$Thick = require("../../__generated__/CabinTableQueryRefetchQuery_graphql.bs.js");
var EnhancedEncryptionOutlined = require("@mui/icons-material/EnhancedEncryptionOutlined").default;

var convertFragment = CabinTableQuery_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CabinTableQuery_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CabinTableQuery_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CabinTableQuery_query_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = CabinTableQueryRefetchQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = CabinTableQueryRefetchQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CabinTableQuery_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = CabinTable_cabin_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CabinTable_cabin_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CabinTable_cabin_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(CabinTable_cabin_graphql$Thick.node, convertFragment$1, fRef);
}

var CabinFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

function CabinTable$CabinTableRow(props) {
  var removeHoldVars = props.removeHoldVars;
  var setHoldCabinVars = props.setHoldCabinVars;
  var handleCabinToggle = props.handleCabinToggle;
  var cabin = use$1(props.fragmentRefs);
  var node = Belt_Array.get(cabin.booking.nodes, 0);
  var match;
  if (node !== undefined) {
    var version = Belt_Array.get(node.bookingVersion.nodes, 0);
    match = version !== undefined ? [
        CS_NonemptyStrings$Util.BookingId.toString(node.bookingId),
        String(version.bookingVersionNumber)
      ] : [
        CS_NonemptyStrings$Util.BookingId.toString(node.bookingId),
        undefined
      ];
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var bookingVersionNumber = match[1];
  var bookingId = match[0];
  var match$1 = cabin.cabinStatus;
  var tmp;
  switch (match$1) {
    case "booked" :
        tmp = JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(LuggageOutlined, {
                    sx: {
                      width: "0.75em",
                      height: "0.75em"
                    }
                  }),
              arrow: true,
              placement: "top",
              size: "sm",
              title: "Booked"
            });
        break;
    case "held" :
        var user = cabin.heldByBfcustUser;
        var tmp$1;
        if (user !== undefined) {
          tmp$1 = "Held by " + user.fullName;
        } else {
          var exp = cabin.holdExpiresAt;
          tmp$1 = exp !== undefined ? "Held by " + Belt_Option.getWithDefault(cabin.heldByTeamUserEmail, "") + " until " + CS_Make$Util.Dateable.Plain.format(Caml_option.valFromOption(exp), "MM/dd/yyyy hh:mm b") : "Held by " + Belt_Option.getWithDefault(cabin.heldByTeamUserEmail, "");
        }
        tmp = JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(LockClockOutlined, {
                    sx: {
                      width: "0.75em",
                      height: "0.75em"
                    }
                  }),
              arrow: true,
              placement: "top",
              size: "sm",
              title: Caml_option.some(JsxRuntime.jsx(Box, {
                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  spacing: 2,
                                  children: [
                                    JsxRuntime.jsx(Typography, {
                                          level: "title-md",
                                          component: "h3",
                                          children: "Cabin Hold"
                                        }),
                                    JsxRuntime.jsx(Typography, {
                                          level: "body-sm",
                                          component: "p",
                                          children: Caml_option.some(tmp$1)
                                        }),
                                    JsxRuntime.jsx(Stack, {
                                          direction: "row",
                                          justifyContent: "flex-end",
                                          children: Caml_option.some(JsxRuntime.jsx(Button, {
                                                    loading: removeHoldVars.isMutating,
                                                    onClick: (function (e) {
                                                        e.preventDefault();
                                                        removeHoldVars.removeHold(cabin.cabinNumber);
                                                      }),
                                                    startDecorator: Caml_option.some(JsxRuntime.jsx(Delete, {})),
                                                    color: "danger",
                                                    size: "sm",
                                                    sx: {
                                                      maxWidth: "150px"
                                                    },
                                                    children: "Remove Hold"
                                                  }))
                                        })
                                  ]
                                })),
                        sx: {
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "300px",
                          justifyContent: "center",
                          p: "1"
                        }
                      })),
              variant: "outlined"
            });
        break;
    default:
      tmp = JsxRuntime.jsx(Tooltip, {
            children: JsxRuntime.jsx(IconButton, {
                  onClick: (function (param) {
                      return setHoldCabinVars(function (param) {
                                  return [
                                          cabin.cabinNumber,
                                          cabin.occRange
                                        ];
                                });
                    }),
                  size: "sm",
                  children: Caml_option.some(JsxRuntime.jsx(EnhancedEncryptionOutlined, {
                            sx: {
                              width: "0.75em",
                              height: "0.75em"
                            }
                          }))
                }),
            arrow: true,
            placement: "top",
            size: "sm",
            title: "Hold cabin"
          });
  }
  var tmp$2;
  var exit = 0;
  if (bookingId !== undefined && bookingVersionNumber !== undefined) {
    tmp$2 = JsxRuntime.jsx(Joy$Util.Link.make, {
          children: JsxRuntime.jsx(Typography, {
                color: "primary",
                noWrap: true,
                sx: {
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline"
                  }
                },
                children: Caml_option.some(bookingId)
              }),
          href: props.bookingUrl + "/" + bookingId + "/" + bookingVersionNumber
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsx(Typography, {
          noWrap: true,
          children: "N/A"
        });
  }
  var match$2 = cabin.occRange.start;
  var match$3 = cabin.occRange.end;
  var pubNote = cabin.configurationNoteMd;
  var privNote = cabin.reservationNoteMd;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Checkbox, {
                            checked: Belt_Set.has(props.selectedCabins, cabin.cabinNumber),
                            onChange: (function (param) {
                                handleCabinToggle(cabin.cabinNumber);
                              })
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_Slugs$Util.CabinCategorySlug.toString(cabin.voyageCategorySlug).toLocaleUpperCase())
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_NonemptyStrings$Util.CabinNumber.toString(cabin.cabinNumber))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_Slugs$Util.CabinClassSlug.toString(cabin.voyageClassSlug))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(CS_Slugs$Util.CabinSubclassSlug.toString(cabin.voyageSubclassSlug))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: tmp$2
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(match$2 !== undefined ? (
                                    match$2.inclusive ? String(match$2.value) : String(match$2.value + 1 | 0)
                                  ) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(match$3 !== undefined ? (
                                    match$3.inclusive ? String(match$3.value) : String(match$3.value - 1 | 0)
                                  ) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(String(cabin.totBerths))
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(cabin.voyageSegment)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(pubNote !== undefined ? Caml_option.valFromOption(pubNote) : "N/A")
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            noWrap: true,
                            children: Caml_option.some(privNote !== undefined ? Caml_option.valFromOption(privNote) : "N/A")
                          })
                    })
              ]
            });
}

var CabinTableRow = {
  make: CabinTable$CabinTableRow
};

function CabinTable(props) {
  var handleCabinToggle = props.handleCabinToggle;
  var setSelectedCabins = props.setSelectedCabins;
  var selectedCabins = props.selectedCabins;
  var allCabins = props.allCabins;
  var voyage = props.voyage;
  var brandFamily = props.brandFamily;
  var fragmentRefs = props.fragmentRefs;
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var refetch$1 = function (param) {
    return refetch(makeRefetchVariables(undefined, undefined, undefined, undefined), "store-and-network", undefined);
  };
  var match$1 = React.useState(function () {
        
      });
  var setHoldCabinVars = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var removeHoldVars = RemoveCabinHoldSubmit$Thick.useMutation(brandFamily, CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(voyage), refetch$1, match$2[1]);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var path = "/" + Belt_Array.joinWith(Belt_List.toArray(url.path), "/", (function (prim) {
          return prim;
        }));
  var page = CabinInventoryFilters$Thick.Pagination.codec.decode(url.search);
  var filterSearchStr = CabinInventoryFilters$Thick.ColumnFilter.codec.encode(CabinInventoryFilters$Thick.ColumnFilter.codec.decode(url.search));
  var bookingUrl = "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/" + voyage + "/bookings";
  var match$3 = data.cabins;
  var total = match$3 !== undefined ? match$3.totalCount : 0;
  var offsetFromPage = function (page) {
    return Math.imul(page - 1 | 0, 50);
  };
  var onLoadNext = function (newPage) {
    var newOffset = offsetFromPage(newPage);
    if (newOffset > total) {
      console.log("No more cabins to load");
      return ;
    }
    var pageSearchStr = CabinInventoryFilters$Thick.Pagination.codec.encode(newPage);
    var newUrl_path = url.path;
    var newUrl_hash = url.hash;
    var newUrl_search = pageSearchStr + (
      filterSearchStr === "" ? "" : "&" + filterSearchStr
    );
    RescriptReactRouter.push(path + (
          newUrl_search === "" ? "" : "?"
        ) + newUrl_search);
  };
  var offset = offsetFromPage(page);
  var match$4 = data.cabins;
  var tmp;
  var exit = 0;
  if (match$4 !== undefined) {
    var edges = match$4.edges;
    if (edges.length !== 0) {
      tmp = JsxRuntime.jsxs(Table, {
            borderAxis: "xBetween",
            stripe: "odd",
            hoverRow: true,
            sx: {
              "& tbody": {
                bgcolor: "background.surface"
              },
              "& td": {
                textAlign: "center"
              },
              "& th": {
                textAlign: "center"
              }
            },
            size: "sm",
            children: [
              JsxRuntime.jsx("thead", {
                    children: JsxRuntime.jsxs("tr", {
                          children: [
                            JsxRuntime.jsx("th", {
                                  children: JsxRuntime.jsx(Checkbox, {
                                        checked: Belt_Set.eq(selectedCabins, allCabins),
                                        onChange: (function (param) {
                                            if (Belt_Set.eq(selectedCabins, allCabins)) {
                                              return setSelectedCabins(function (param) {
                                                          return Belt_Set.make(Common$Thick.CabinCmp);
                                                        });
                                            } else {
                                              return setSelectedCabins(function (param) {
                                                          return allCabins;
                                                        });
                                            }
                                          })
                                      }),
                                  style: {
                                    width: "40px"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Status",
                                  style: {
                                    width: "25px"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Category",
                                  style: {
                                    width: "4%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Cabin #",
                                  style: {
                                    width: "4%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Class",
                                  style: {
                                    width: "7%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Subclass",
                                  style: {
                                    width: "7%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Booking ID"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Sales min",
                                  style: {
                                    width: "4%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Sales max",
                                  style: {
                                    width: "4%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "#Berths",
                                  style: {
                                    width: "4%"
                                  }
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Segment"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Configuration notes"
                                }),
                            JsxRuntime.jsx("th", {
                                  children: "Reservation notes"
                                })
                          ]
                        })
                  }),
              JsxRuntime.jsx("tbody", {
                    children: Belt_Array.map(edges, (function (edge) {
                            return JsxRuntime.jsx(CabinTable$CabinTableRow, {
                                        fragmentRefs: edge.node.fragmentRefs,
                                        handleCabinToggle: handleCabinToggle,
                                        selectedCabins: selectedCabins,
                                        bookingUrl: bookingUrl,
                                        setHoldCabinVars: setHoldCabinVars,
                                        removeHoldVars: removeHoldVars
                                      }, edge.node.id);
                          }))
                  })
            ]
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Stack, {
          justifyContent: "center",
          alignItems: "center",
          sx: {
            height: "15rem",
            width: "100%"
          },
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "title-lg",
                    sx: {
                      color: "neutral.500"
                    },
                    children: "No cabins found"
                  }))
        });
  }
  return JsxRuntime.jsx(Stack, {
              spacing: 1,
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        spacing: 1,
                        children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                                  variant: "outlined",
                                  sx: {
                                    borderRadius: "4px",
                                    borderLeft: "1px solid",
                                    borderRight: "1px solid",
                                    borderTop: "1px solid",
                                    borderColor: "neutral.200"
                                  },
                                  children: [
                                    JsxRuntime.jsxs(Sheet, {
                                          variant: "solid",
                                          color: "primary",
                                          sx: {
                                            px: 3,
                                            py: 2,
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px"
                                          },
                                          children: [
                                            JsxRuntime.jsx(Typography, {
                                                  level: "title-lg",
                                                  textColor: "neutral.50",
                                                  children: "Cabin Inventory"
                                                }),
                                            JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  textColor: "neutral.300",
                                                  children: Caml_option.some(CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + " | " + voyage)
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsx(TableFilter$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          total: total,
                                          offset: offset,
                                          onLoadNext: onLoadNext,
                                          page: page,
                                          path: path,
                                          selectedCabins: selectedCabins,
                                          setSelectedCabins: setSelectedCabins,
                                          refetch: refetch$1,
                                          voyage: voyage,
                                          brandFamily: brandFamily
                                        }),
                                    tmp,
                                    JsxRuntime.jsx(HoldCabinForm$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          holdCabinVars: match$1[0],
                                          onClose: (function () {
                                              setHoldCabinVars(function (param) {
                                                    
                                                  });
                                            }),
                                          voyage: voyage,
                                          brandFamily: brandFamily,
                                          refetch: refetch$1
                                        }),
                                    JsxRuntime.jsx(TableFilter$Thick.make, {
                                          fragmentRefs: fragmentRefs,
                                          total: total,
                                          offset: offset,
                                          onLoadNext: onLoadNext,
                                          page: page,
                                          path: path,
                                          bottom: true,
                                          selectedCabins: selectedCabins,
                                          setSelectedCabins: setSelectedCabins,
                                          refetch: refetch$1,
                                          voyage: voyage,
                                          brandFamily: brandFamily
                                        })
                                  ]
                                }))
                      }))
            });
}

var make = CabinTable;

exports.QueryFragment = QueryFragment;
exports.CabinFragment = CabinFragment;
exports.CabinTableRow = CabinTableRow;
exports.make = make;
/* react Not a pure module */
