// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Drawer = require("@mui/joy/Drawer").default;
var BSPInputs$Thick = require("./BSPInputs.bs.js");
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var DialogTitle = require("@mui/joy/DialogTitle").default;
var BookingSession$Thick = require("../../utils/BookingSession.bs.js");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

function BSDrawer$DrawerSave(props) {
  var openErrorModal = props.openErrorModal;
  var onClose = props.onClose;
  var form = props.form;
  var triggerRefetch = props.triggerRefetch;
  var validatedForm = BookingSession$Thick.useValidatedForm(form, props.brandFamily, props.voyage, undefined, undefined);
  var formHasErrors = Belt_Result.isError(validatedForm);
  var match = BookingSession$Thick.Query.Save.use();
  var mut = match[0];
  var isDirty = BookingSession$Thick.useIsDirty(form);
  var isEditContext = HopperState$Util.Observable.useComputed(form, (function (param) {
          var tmp = param.target;
          if (typeof tmp !== "object") {
            return false;
          } else {
            return true;
          }
        }), undefined, undefined);
  var tooltipTitle = formHasErrors ? "This form has errors and cannot be saved. Click to show all error messages." : (
      isEditContext ? (
          isDirty ? "This form has been modified from its original version" : "This form has not been modified"
        ) : null
    );
  return JsxRuntime.jsx(Tooltip, {
              children: JsxRuntime.jsx(Button, {
                    loading: match[1],
                    onClick: (function (param) {
                        if (validatedForm.TAG === "Ok") {
                          mut({
                                input: validatedForm._0
                              }, undefined, undefined, undefined, (function (response, param) {
                                  var match = response.saveBookingSession;
                                  if (match === undefined) {
                                    return openErrorModal();
                                  }
                                  var match$1 = match.boolean;
                                  if (match$1 !== undefined && match$1) {
                                    triggerRefetch();
                                    return onClose();
                                  } else {
                                    return openErrorModal();
                                  }
                                }), undefined, undefined);
                          return ;
                        }
                        var idx = validatedForm._0;
                        if (idx !== undefined) {
                          BookingSession$Thick.markAsTouched(form);
                          return HopperState$Util.Observable.notify(form, (function (f) {
                                        return {
                                                target: f.target,
                                                billingContactAccountId: f.billingContactAccountId,
                                                isBfcustFacing: f.isBfcustFacing,
                                                bookingChannelSlug: f.bookingChannelSlug,
                                                fareClassSlug: f.fareClassSlug,
                                                cabinNumber: f.cabinNumber,
                                                forOccupancy: f.forOccupancy,
                                                pifIntention: f.pifIntention,
                                                descriptiveNote: f.descriptiveNote,
                                                focusedPax: idx,
                                                pax: f.pax,
                                                privateReservationNote: f.privateReservationNote
                                              };
                                      }));
                        } else {
                          return BookingSession$Thick.markAsTouched(form);
                        }
                      }),
                    startDecorator: Caml_option.some(formHasErrors ? JsxRuntime.jsx(WarningAmber, {
                                fontSize: "md"
                              }) : null),
                    variant: formHasErrors ? "outlined" : "solid",
                    color: formHasErrors ? "danger" : "primary",
                    children: "Save"
                  }),
              arrow: true,
              placement: "top",
              size: "sm",
              title: Caml_option.some(tooltipTitle)
            });
}

var DrawerSave = {
  make: BSDrawer$DrawerSave
};

function BSDrawer(props) {
  var onClose = props.onClose;
  var title = props.title;
  var open_ = props.open_;
  var form = open_ ? BookingSession$Thick.formExn(props.form) : undefined;
  return JsxRuntime.jsxs(Drawer, {
              children: [
                JsxRuntime.jsxs(Box, {
                      children: [
                        title !== undefined ? JsxRuntime.jsx(DialogTitle, {
                                children: Caml_option.some(title)
                              }) : null,
                        form !== undefined ? JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 3,
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                children: "Visible to billing contact?"
                                              }),
                                          JsxRuntime.jsx(BSPInputs$Thick.Bool.make, {
                                                view: {
                                                  TAG: "Direct",
                                                  _0: form
                                                },
                                                input: BookingSession$Thick.isBfcustFacing,
                                                label: "Visible to billing contact",
                                                helperText: "",
                                                required: true,
                                                withLabels: false
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(BSDrawer$DrawerSave, {
                                                brandFamily: props.brandFamily,
                                                voyage: props.voyage,
                                                triggerRefetch: props.triggerRefetch,
                                                form: form,
                                                onClose: onClose,
                                                openErrorModal: props.openErrorModal
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                onClick: onClose,
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              })
                                        ]
                                      })
                                ]
                              }) : null
                      ],
                      sx: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 0.5,
                        p: 1,
                        width: "calc(100% - 16px)"
                      }
                    }),
                JsxRuntime.jsx(Divider, {}),
                props.children
              ],
              open: open_,
              anchor: "bottom",
              disablePortal: true,
              onClose: onClose,
              size: "md",
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none"
                  }
                },
                content: {
                  sx: {
                    "--Drawer-verticalSize": "clamp(500px, 40%, 40%)",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                    ml: "8px",
                    mr: "8px",
                    width: "calc(100% - 16px)"
                  }
                }
              }
            });
}

var make = BSDrawer;

exports.DrawerSave = DrawerSave;
exports.make = make;
/* @mui/joy/Box Not a pure module */
