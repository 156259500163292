// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var DraftUtils$Thick = require("./DraftUtils.bs.js");
var Checkbox = require("@mui/joy/Checkbox").default;
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PreviewModal$Thick = require("./PreviewModal.bs.js");
var SharedDrawer$Thick = require("../../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var PreviewChanges$Thick = require("./PreviewChanges.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var FetchBfCustUserVoyages$Thick = require("../../common/queries/FetchBfCustUserVoyages.bs.js");
var UpdateAccountAndPaxData$Thick = require("../../common/mutations/UpdateAccountAndPaxData.bs.js");

function PatchPaxDrawer(props) {
  var fragmentRefs = props.countryFragmentRefs;
  var versionUrl = props.versionUrl;
  var onClose = props.onClose;
  var pax = props.pax;
  var match = pax.paxDataStatus;
  var match$1 = pax.custodianAccountId;
  var isNewConnected;
  isNewConnected = typeof match !== "object" || match.TAG === "Status" ? false : match$1 !== undefined;
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowVoyageOptions = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setApplyToUserAcct = match$3[1];
  var applyToUserAcct = match$3[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var diffStruct = BookingDiff$Thick.parse(url.search);
  var match$4 = React.useState(function () {
        return UpdateAccountAndPaxData$Thick.Util.paxToUpdate(pax);
      });
  var setUpdate = match$4[1];
  var update = match$4[0];
  React.useEffect((function () {
          setUpdate(function (param) {
                return UpdateAccountAndPaxData$Thick.Util.paxToUpdate(pax);
              });
        }), [pax]);
  var match$5 = FetchBfCustUserVoyages$Thick.useQuery(props.brandFamily, Caml_option.some(props.voyage));
  var fetchVoyages = match$5.fetchVoyages;
  var voyOpt = match$5.voyOpt;
  React.useEffect((function () {
          var accountId = pax.accountId;
          if (accountId !== undefined) {
            fetchVoyages(Caml_option.valFromOption(accountId));
          }
          
        }), []);
  var match$6 = PreviewChanges$Thick.useQuery(props.generatePreviewFromDiff, diffStruct, onClose);
  var clearPreviewFields = match$6.clearPreviewFields;
  var setError = match$6.setError;
  var setLoading = match$6.setLoading;
  var onSubmit = function (e) {
    e.preventDefault();
    if (isNewConnected) {
      var connected = UpdateAccountAndPaxData$Thick.Util.updateToNewConnected(update);
      if (connected.TAG === "Ok") {
        var connected$1 = connected._0;
        var match = pax.paxDataStatus;
        var applyDiff;
        if (typeof match !== "object" || match.TAG === "Status") {
          applyDiff = diffStruct;
        } else {
          var match$1 = match._0;
          applyDiff = match$1 === "attach" ? BookingDiff$Thick.Alter.attachNewConnectedPax(diffStruct, pax.idx, connected$1) : (
              match$1 === "replace" ? BookingDiff$Thick.Alter.replaceWithNewConnectedPax(diffStruct, pax.idx, connected$1) : diffStruct
            );
        }
        if (diffStruct === applyDiff) {
          setError(function (param) {
                return "No changes made";
              });
          return ;
        } else {
          DraftUtils$Thick.addToDraft(applyDiff, versionUrl);
          return onClose();
        }
      }
      var err = connected._0;
      return setError(function (param) {
                  return err;
                });
    }
    var patch = UpdateAccountAndPaxData$Thick.Util.updateToPaxPatch(update);
    if (patch.TAG === "Ok") {
      var patch$1 = patch._0;
      var patch_accountId = patch$1.accountId;
      var patch_pDocFirstName = patch$1.pDocFirstName;
      var patch_pDocLastName = patch$1.pDocLastName;
      var patch_pDocBirthdate = patch$1.pDocBirthdate;
      var patch_pDocGender = patch$1.pDocGender;
      var patch_pDocCitizenship = patch$1.pDocCitizenship;
      var patch_updateOtherVoyages = patch$1.updateOtherVoyages;
      var patch$2 = {
        accountId: patch_accountId,
        pDocFirstName: patch_pDocFirstName,
        pDocLastName: patch_pDocLastName,
        pDocBirthdate: patch_pDocBirthdate,
        pDocGender: patch_pDocGender,
        pDocCitizenship: patch_pDocCitizenship,
        updateUserAccount: applyToUserAcct,
        updateOtherVoyages: patch_updateOtherVoyages
      };
      var applyDiff$1 = BookingDiff$Thick.Alter.patchPax(diffStruct, pax.idx, patch$2);
      DraftUtils$Thick.addToDraft(applyDiff$1, versionUrl);
      return onClose();
    }
    var err$1 = patch._0;
    setError(function (param) {
          return err$1;
        });
  };
  var clearAll = function () {
    clearPreviewFields();
    setLoading(function (param) {
          return false;
        });
    setShowVoyageOptions(function (param) {
          return false;
        });
    setError(function (param) {
          
        });
  };
  var cancel = function () {
    clearAll();
    onClose();
    setUpdate(function (param) {
          return UpdateAccountAndPaxData$Thick.Util.paxToUpdate(pax);
        });
  };
  var tmp;
  tmp = typeof voyOpt !== "object" ? null : (
      voyOpt.TAG === "Success" ? JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Voyages.make, {
              update: update,
              setUpdate: setUpdate,
              showVoyageOptions: match$2[0],
              setShowVoyageOptions: setShowVoyageOptions,
              voyageOptions: voyOpt._0,
              display: true
            }) : JsxRuntime.jsx(Typography, {
              color: "danger",
              children: Caml_option.some(voyOpt._0)
            })
    );
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(SharedDrawer$Thick.make, {
                      open_: props.open_,
                      title: "Update passenger details",
                      onClose: onClose,
                      children: [
                        JsxRuntime.jsx(DialogContent, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          px: 2,
                                          py: 3
                                        },
                                        children: [
                                          isNewConnected ? JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Personal.make, {
                                                  fragmentRefs: fragmentRefs,
                                                  title: "User Profile",
                                                  update: update,
                                                  setUpdate: setUpdate,
                                                  variant: "Gray",
                                                  noHelperText: true,
                                                  namesOnly: true
                                                }) : null,
                                          JsxRuntime.jsx(UpdateAccountAndPaxData$Thick.Form.Travel.make, {
                                                fragmentRefs: fragmentRefs,
                                                update: update,
                                                setUpdate: setUpdate,
                                                variant: "Gray"
                                              }),
                                          isNewConnected ? null : JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  alignItems: "center",
                                                  spacing: 1,
                                                  sx: {
                                                    cursor: "pointer"
                                                  },
                                                  onClick: (function (param) {
                                                      setApplyToUserAcct(function (param) {
                                                            return !applyToUserAcct;
                                                          });
                                                    }),
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox, {
                                                          checked: applyToUserAcct,
                                                          size: "sm"
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          children: "Apply changes to user account"
                                                        })
                                                  ]
                                                }),
                                          tmp
                                        ]
                                      }))
                            }),
                        JsxRuntime.jsx(DialogActions, {
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: {
                                          xs: "column-reverse",
                                          sm: "row"
                                        },
                                        component: "form",
                                        justifyContent: "space-between",
                                        spacing: 1,
                                        sx: {
                                          width: "100%",
                                          p: 2
                                        },
                                        onSubmit: onSubmit,
                                        children: [
                                          JsxRuntime.jsx(Button, {
                                                onClick: cancel,
                                                variant: "outlined",
                                                color: "neutral",
                                                children: "Cancel"
                                              }),
                                          JsxRuntime.jsx(Button, {
                                                type: "submit",
                                                disabled: (
                                                  isNewConnected ? Belt_Result.isError(UpdateAccountAndPaxData$Thick.Util.updateToNewConnected(update)) : Belt_Result.isError(UpdateAccountAndPaxData$Thick.Util.updateToPaxPatch(update))
                                                ) || match$6.loading,
                                                children: "Add to draft"
                                              })
                                        ]
                                      }))
                            })
                      ]
                    }),
                JsxRuntime.jsx(PreviewModal$Thick.make, {
                      billPayCurrent: props.billPayCurrent,
                      billPayNew: match$6.billPayNew,
                      applyDiff: match$6.applyDiff,
                      title: "Update passenger details",
                      description: "Preview changes for update to passenger details.",
                      onClose: (function () {
                          clearAll();
                        }),
                      open_: match$6.previewModalOpen,
                      versionUrl: versionUrl,
                      error: match$6.error
                    })
              ]
            });
}

var make = PatchPaxDrawer;

exports.make = make;
/* react Not a pure module */
