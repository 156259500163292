// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");

var Types = {};

var variablesConverter = {"bookInput":{"_voyageSlug":{"c":"Util.CustomScalars.VoyageSlug.Exn"},"_brandFamilySlug":{"c":"Util.CustomScalars.BrandFamilySlug.Exn"},"_bookingSessionId":{"c":"Util.CustomScalars.ObjId.Exn"},"_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}},"__root":{"input":{"r":"bookInput"}}};

var variablesConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize,
  "Util.CustomScalars.ObjId.Exn": CS_UUID$Util.ObjId.Exn.serialize,
  "Util.CustomScalars.BrandFamilySlug.Exn": CS_Slugs$Util.BrandFamilySlug.Exn.serialize,
  "Util.CustomScalars.VoyageSlug.Exn": CS_Slugs$Util.VoyageSlug.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"book_createBookingResult_bookingVersionNumber":{"b":""},"book_createBookingResult_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var wrapResponseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"book_createBookingResult_bookingVersionNumber":{"b":""},"book_createBookingResult_bookingId":{"c":"Util.CustomScalars.BookingId.Exn"}}};

var responseConverterMap = {
  "Util.CustomScalars.BookingId.Exn": CS_NonemptyStrings$Util.BookingId.Exn.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function bookingSessionError_decode($$enum) {
  if ($$enum === "INVALID_PAYMENT_PLAN" || $$enum === "NEW_UNCLAIMED_USER_WITH_NULL_CUSTODIAN_ACCOUNT_ID" || $$enum === "USER_DELETED_STATUS" || $$enum === "AMBIGUOUS_PAX_TARGETS" || $$enum === "PAX_INVALID_AGE_FOR_PAX1" || $$enum === "PAX_IDX_INVALID" || $$enum === "PAX_HAS_ANOTHER_ACTIVE_BOOKING" || $$enum === "PAX_ACCT_INVALID" || $$enum === "BILLING_CONTACT_HAS_TOO_MANY_BOOKINGS" || $$enum === "INVALID_BILLING_CONTACT" || $$enum === "INVALID_CABIN_VARS" || $$enum === "INVALID_CABIN" || $$enum === "INVALID_FARE_CLASS" || $$enum === "BOOKING_ID_ALREADY_IN_USE" || $$enum === "NO_PAX") {
    return $$enum;
  }
  
}

function bookingSessionError_fromString(str) {
  return bookingSessionError_decode(str);
}

var Utils = {
  bookingSessionError_decode: bookingSessionError_decode,
  bookingSessionError_fromString: bookingSessionError_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BookPayload",
    "kind": "LinkedField",
    "name": "book",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CreateBookingResult",
        "kind": "LinkedField",
        "name": "createBookingResult",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "success",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nullableError",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bookingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bookingVersionNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOrBookModal_BookMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteOrBookModal_BookMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b73bb14ba077117799c9b64c4a567d50",
    "id": null,
    "metadata": {},
    "name": "DeleteOrBookModal_BookMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteOrBookModal_BookMutation(\n  $input: BookInput!\n) {\n  book(input: $input) {\n    createBookingResult {\n      success\n      nullableError\n      bookingId\n      bookingVersionNumber\n    }\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
