// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var BillPayInfo$Thick = require("../billing-payments/BillPayInfo.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick = require("../../../__generated__/RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql.bs.js");

var convertVariables = RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RecordPostCancellationBilling_RecordPostCancellationBillingMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RecordPostCancellationBillingMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_brandFamilySlug, _voyageSlug, _bookingId, _bookingVersionNumber, onClose, refetch) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = use();
  var mutate = match$1[0];
  var recordPostCancellationBilling = function (param) {
    mutate({
          input: {
            _brandFamilySlug: _brandFamilySlug,
            _voyageSlug: _voyageSlug,
            _bookingId: _bookingId,
            _bookingVersionNumber: _bookingVersionNumber,
            _lineAmount: param._lineAmount,
            _description: param._description
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.recordPostCancellationBilling;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully recorded billing");
                  refetch();
                  onClose();
                  setError(function (param) {
                        
                      });
                  return ;
                }
                
              }
              setError(function (param) {
                    return "Failed to record billing";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError(function (param) {
                    return "Error recording billing";
                  });
              return ;
            }
            setError(function (param) {
                  return "Error recording billing";
                });
          }), (function (err) {
            console.log(err);
            setError(function (param) {
                  return "A server error occurred while recording billing";
                });
          }), undefined);
  };
  return {
          error: match[0],
          setError: setError,
          isMutating: match$1[1],
          recordPostCancellationBilling: recordPostCancellationBilling
        };
}

function postCancellationBillingIntermToPostCancellationBilling(i) {
  var match = Belt_Option.map(i.lineAmount, (function (str) {
          return CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(str));
        }));
  var match$1 = Belt_Option.map(i.description, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive);
  if (match !== undefined && match.TAG === "Ok" && match$1 !== undefined && match$1.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              _description: match$1._0,
              _lineAmount: match._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: "Invalid input"
          };
  }
}

var Utils_empty = {
  lineAmount: undefined,
  description: undefined
};

var Utils = {
  empty: Utils_empty,
  postCancellationBillingIntermToPostCancellationBilling: postCancellationBillingIntermToPostCancellationBilling
};

exports.RecordPostCancellationBillingMutation = RecordPostCancellationBillingMutation;
exports.useMutation = useMutation;
exports.Utils = Utils;
/* commitMutation Not a pure module */
