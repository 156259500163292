// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Badge = require("@mui/joy/Badge").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var FilterMenu$Thick = require("./FilterMenu.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var BulkActionForm$Thick = require("./BulkActionForm.bs.js");
var BrandPermProtected$Thick = require("../wrappers/BrandPermProtected.bs.js");
var Share = require("@mui/icons-material/Share").default;
var CabinInventoryFilters$Thick = require("../../utils/CabinInventoryFilters.bs.js");
var FilterList = require("@mui/icons-material/FilterList").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;
var ArrowDropDown = require("@mui/icons-material/ArrowDropDown").default;

function TableFilter(props) {
  var selectedCabins = props.selectedCabins;
  var __bottom = props.bottom;
  var page = props.page;
  var onLoadNext = props.onLoadNext;
  var offset = props.offset;
  var total = props.total;
  var fragmentRefs = props.fragmentRefs;
  var bottom = __bottom !== undefined ? __bottom : false;
  var match = React.useState(function () {
        
      });
  var setActionType = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setIsOpen = match$1[1];
  var isOpen = match$1[0];
  var filterState = CabinInventoryFilters$Thick.ColumnFilter.useState();
  var setToStorage = filterState.setToStorage;
  var match$2 = filterState.urlFilters;
  var curFilters = match$2[0];
  React.useEffect((function () {
          setToStorage(curFilters);
        }), [match$2[1]]);
  var actionOptions = [
    [
      "change_cabin_capacity",
      "Change Cabin Capacity"
    ],
    [
      "change_cabin_segment",
      "Change Cabin Segment"
    ],
    [
      "change_cabin_notes",
      "Change Cabin Notes"
    ]
  ];
  var match$3 = React.useState(function () {
        return false;
      });
  var setCopied = match$3[1];
  var handleClose = function (param) {
    setIsOpen(function (param) {
          return !isOpen;
        });
  };
  var match$4 = React.useState(function () {
        return false;
      });
  var setActionModalOpen = match$4[1];
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                p: 1
              },
              children: [
                JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                      fragmentRefs: fragmentRefs,
                      neededPerm: "MANAGE_CABIN_INVENTORY",
                      children: JsxRuntime.jsx(BulkActionForm$Thick.make, {
                            fragmentRefs: fragmentRefs,
                            selectedCabins: selectedCabins,
                            isOpen: match$4[0],
                            onClose: (function () {
                                setActionModalOpen(function (param) {
                                      return false;
                                    });
                                setActionType(function (param) {
                                      
                                    });
                              }),
                            actionType: match[0],
                            setSelectedCabins: props.setSelectedCabins,
                            voyage: props.voyage,
                            brandFamily: props.brandFamily,
                            refetch: props.refetch
                          })
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              children: Caml_option.some(bottom ? JsxRuntime.jsx("div", {}) : JsxRuntime.jsxs(Dropdown, {
                                          children: [
                                            JsxRuntime.jsx(Badge, {
                                                  badgeContent: filterState.conditions.length,
                                                  children: Caml_option.some(JsxRuntime.jsx(MenuButton, {
                                                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                      level: "body-xs",
                                                                      children: "Filter "
                                                                    })),
                                                            endDecorator: Caml_option.some(JsxRuntime.jsx(FilterList, {
                                                                      fontSize: "lg"
                                                                    })),
                                                            size: "sm",
                                                            variant: "outlined"
                                                          })),
                                                  color: "neutral",
                                                  showZero: false,
                                                  size: "sm"
                                                }),
                                            JsxRuntime.jsx(Menu, {
                                                  children: Caml_option.some(JsxRuntime.jsx(FilterMenu$Thick.make, {
                                                            path: props.path,
                                                            handleClose: handleClose,
                                                            filterState: filterState
                                                          })),
                                                  onClose: handleClose,
                                                  placement: "bottom-start"
                                                })
                                          ],
                                          open: isOpen,
                                          onOpenChange: (function (param, param$1) {
                                              setIsOpen(function (param) {
                                                    return !isOpen;
                                                  });
                                            })
                                        }))
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              children: [
                                JsxRuntime.jsx(Stack, {
                                      direction: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      spacing: 1,
                                      sx: {
                                        position: "relative"
                                      },
                                      children: Caml_option.some(bottom ? JsxRuntime.jsx("div", {}) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                  children: [
                                                    JsxRuntime.jsx(BrandPermProtected$Thick.make, {
                                                          fragmentRefs: fragmentRefs,
                                                          neededPerm: "MANAGE_CABIN_INVENTORY",
                                                          children: JsxRuntime.jsxs(Dropdown, {
                                                                children: [
                                                                  JsxRuntime.jsx(MenuButton, {
                                                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                  level: "body-xs",
                                                                                  textColor: "inherit",
                                                                                  children: "Bulk Actions"
                                                                                })),
                                                                        color: "primary",
                                                                        disabled: Belt_Set.isEmpty(selectedCabins),
                                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(ArrowDropDown, {})),
                                                                        size: "sm",
                                                                        variant: Belt_Set.isEmpty(selectedCabins) ? "outlined" : "solid"
                                                                      }),
                                                                  JsxRuntime.jsx(Menu, {
                                                                        children: Caml_option.some(Belt_Array.map(actionOptions, (function (param) {
                                                                                    var action = param[0];
                                                                                    return JsxRuntime.jsx(MenuItem, {
                                                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                                                          level: "body-xs",
                                                                                                          children: Caml_option.some(param[1])
                                                                                                        })),
                                                                                                onClick: (function (param) {
                                                                                                    setActionType(function (param) {
                                                                                                          return action;
                                                                                                        });
                                                                                                    setActionModalOpen(function (param) {
                                                                                                          return true;
                                                                                                        });
                                                                                                  })
                                                                                              }, action);
                                                                                  })))
                                                                      })
                                                                ]
                                                              })
                                                        }),
                                                    JsxRuntime.jsx(Tooltip, {
                                                          children: JsxRuntime.jsx(Button, {
                                                                onClick: (function (param) {
                                                                    return Common$Thick.copyToClipboard(window.location.href, setCopied);
                                                                  }),
                                                                endDecorator: Caml_option.some(JsxRuntime.jsx(Share, {
                                                                          fontSize: "sm"
                                                                        })),
                                                                variant: "outlined",
                                                                color: "neutral",
                                                                size: "sm",
                                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          children: "Share Current View"
                                                                        }))
                                                              }),
                                                          arrow: true,
                                                          disableHoverListener: true,
                                                          open: match$3[0],
                                                          placement: "top",
                                                          size: "sm",
                                                          title: "Copied to clipboard!"
                                                        })
                                                  ]
                                                }))
                                    }),
                                JsxRuntime.jsx(IconButton, {
                                      onClick: (function (param) {
                                          return onLoadNext(page - 1 | 0);
                                        }),
                                      variant: "plain",
                                      disabled: offset === 0,
                                      children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      level: "body-xs",
                                      children: Caml_option.some(String(total < 1 ? 0 : offset + 1 | 0) + " - " + String(Math.min(offset + 50 | 0, total)) + " / " + String(total))
                                    }),
                                JsxRuntime.jsx(IconButton, {
                                      onClick: (function (param) {
                                          return onLoadNext(page + 1 | 0);
                                        }),
                                      variant: "plain",
                                      disabled: (offset + 50 | 0) >= total,
                                      children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var make = TableFilter;

exports.make = make;
/* react Not a pure module */
