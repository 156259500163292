// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick = require("../../../__generated__/RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql.bs.js");

var convertVariables = RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, RemoveCabinHoldSubmit_RemoveCabinHoldMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var RemoveCabinHold = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_brandFamilySlug, _voyageSlug, refetch, setError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError$1 = match$1[1];
  var removeHold = function (_cabinNumber) {
    mutate({
          input: {
            _brandFamilySlug: _brandFamilySlug,
            _voyageSlug: _voyageSlug,
            _cabinNumber: _cabinNumber
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.removeCabinHold;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  console.log("Successfully released cabin hold");
                  refetch();
                  setError$1(function (param) {
                        
                      });
                  return ;
                }
                
              }
              console.log("Failed to release cabin hold");
              setError$1(function (param) {
                    return "Failed to release cabin hold";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError$1(function (param) {
                    return "Error releasing cabin hold";
                  });
              return ;
            }
            console.log("Unknown error releasing cabin hold");
          }), (function (err) {
            console.log(err);
            setError$1(function (param) {
                  return "Error releasing cabin hold";
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError$1,
          isMutating: match[1],
          removeHold: removeHold
        };
}

exports.RemoveCabinHold = RemoveCabinHold;
exports.useMutation = useMutation;
/* commitMutation Not a pure module */
