// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_MapInt = require("rescript/lib/js/belt_MapInt.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_UUID$Util = require("util/src/CustomScalars/CS_UUID.bs.js");
var CS_Phone$Util = require("util/src/CustomScalars/CS_Phone.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var RelayEnv$Thick = require("../RelayEnv.bs.js");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var QueryString$Util = require("util/src/QueryString.bs.js");
var S$RescriptSchema = require("rescript-schema/src/S.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var BookingSessionList_query_graphql$Thick = require("../__generated__/BookingSessionList_query_graphql.bs.js");
var BookingSessionDetail_query_graphql$Thick = require("../__generated__/BookingSessionDetail_query_graphql.bs.js");
var BookingSessionSaveMutation_graphql$Thick = require("../__generated__/BookingSessionSaveMutation_graphql.bs.js");
var BookingSessionFetchOneQuery_graphql$Thick = require("../__generated__/BookingSessionFetchOneQuery_graphql.bs.js");
var BookingSessionListRefetchQuery_graphql$Thick = require("../__generated__/BookingSessionListRefetchQuery_graphql.bs.js");
var BookingSessionCabinByPrivNoteQuery_graphql$Thick = require("../__generated__/BookingSessionCabinByPrivNoteQuery_graphql.bs.js");

var UnsupportedBookingSessionPaxType = /* @__PURE__ */Caml_exceptions.create("BookingSession-Thick.UnsupportedBookingSessionPaxType");

var convertFragment = BookingSessionDetail_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingSessionDetail_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingSessionDetail_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(BookingSessionDetail_query_graphql$Thick.node, convertFragment, fRef);
}

var Single_bookingSessionPassengerType_decode = BookingSessionDetail_query_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var Single_bookingSessionPassengerType_fromString = BookingSessionDetail_query_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var Single_docGender_decode = BookingSessionDetail_query_graphql$Thick.Utils.docGender_decode;

var Single_docGender_fromString = BookingSessionDetail_query_graphql$Thick.Utils.docGender_fromString;

var Single = {
  bookingSessionPassengerType_decode: Single_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: Single_bookingSessionPassengerType_fromString,
  docGender_decode: Single_docGender_decode,
  docGender_fromString: Single_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var convertVariables = BookingSessionFetchOneQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = BookingSessionFetchOneQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = BookingSessionFetchOneQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, BookingSessionFetchOneQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, BookingSessionFetchOneQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(BookingSessionFetchOneQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(BookingSessionFetchOneQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(BookingSessionFetchOneQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(BookingSessionFetchOneQuery_graphql$Thick.node, convertVariables);

var FetchOne = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment$1 = BookingSessionList_query_graphql$Thick.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingSessionList_query_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingSessionList_query_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(BookingSessionList_query_graphql$Thick.node, convertFragment$1, fRef);
}

var makeRefetchVariables = BookingSessionListRefetchQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = BookingSessionListRefetchQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingSessionList_query_graphql$Thick.node, convertFragment$1, convertRefetchVariables, fRef);
}

function usePagination(fRef) {
  return RescriptRelay_Fragment.usePaginationFragment(BookingSessionList_query_graphql$Thick.node, fRef, convertFragment$1, convertRefetchVariables);
}

function useBlockingPagination(fRef) {
  return RescriptRelay_Fragment.useBlockingPaginationFragment(BookingSessionList_query_graphql$Thick.node, fRef, convertFragment$1, convertRefetchVariables);
}

var List_getConnectionNodes = BookingSessionList_query_graphql$Thick.Utils.getConnectionNodes;

var List_bookingSessionPassengerType_decode = BookingSessionList_query_graphql$Thick.Utils.bookingSessionPassengerType_decode;

var List_bookingSessionPassengerType_fromString = BookingSessionList_query_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var List = {
  getConnectionNodes: List_getConnectionNodes,
  bookingSessionPassengerType_decode: List_bookingSessionPassengerType_decode,
  bookingSessionPassengerType_fromString: List_bookingSessionPassengerType_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$2,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable,
  usePagination: usePagination,
  useBlockingPagination: useBlockingPagination
};

var convertVariables$1 = BookingSessionSaveMutation_graphql$Thick.Internal.convertVariables;

var convertResponse$1 = BookingSessionSaveMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$1 = BookingSessionSaveMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables$1, BookingSessionSaveMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$1, BookingSessionSaveMutation_graphql$Thick.node, convertResponse$1, convertWrapRawResponse$1);

var Save_docGender_decode = BookingSessionSaveMutation_graphql$Thick.Utils.docGender_decode;

var Save_docGender_fromString = BookingSessionSaveMutation_graphql$Thick.Utils.docGender_fromString;

var Save = {
  docGender_decode: Save_docGender_decode,
  docGender_fromString: Save_docGender_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation,
  use: use$3
};

function formatPaxInputArray(arr) {
  if (arr.length === 0) {
    return ;
  } else {
    return Belt_Array.map(arr, (function (a) {
                  return Caml_option.some(a);
                }));
  }
}

function paxDetailArrayToPaxInputArrays(a, map) {
  var param = Belt_Array.reduce(a, [
        [],
        [],
        []
      ], (function (param, deets) {
          var ci = param[2];
          var ii = param[1];
          var ai = param[0];
          var next = map(deets);
          switch (next.TAG) {
            case "ExistingUser" :
                return [
                        Belt_Array.concat(ai, [next._0]),
                        ii,
                        ci
                      ];
            case "NewInvitation" :
                return [
                        ai,
                        Belt_Array.concat(ii, [next._0]),
                        ci
                      ];
            case "NewConnectedUser" :
                return [
                        ai,
                        ii,
                        Belt_Array.concat(ci, [next._0])
                      ];
            
          }
        }));
  return [
          formatPaxInputArray(param[0]),
          formatPaxInputArray(param[1]),
          formatPaxInputArray(param[2])
        ];
}

var decodePaxTypeInput = BookingSessionDetail_query_graphql$Thick.Utils.bookingSessionPassengerType_decode;

function decodePaxTypeInputExn(g) {
  return Belt_Option.getExn(decodePaxTypeInput(g));
}

function paxTypeToString(prim) {
  return prim;
}

var paxTypeFromString = BookingSessionDetail_query_graphql$Thick.Utils.bookingSessionPassengerType_fromString;

var allLegalPaxTypes = [
  "EXISTING_USER",
  "NEW_INVITATION",
  "NEW_CONNECTED_USER"
];

function paxTypeNiceName(pt) {
  switch (pt) {
    case "EXISTING_USER" :
        return "Existing User Account";
    case "NEW_INVITATION" :
        return "Invite a New Account";
    case "NEW_CONNECTED_USER" :
        return "Create a New Connected Account";
    
  }
}

var decodeDocGender = BookingSessionDetail_query_graphql$Thick.Utils.docGender_decode;

function decodeDocGenderExn(g) {
  return Belt_Option.getExn(decodeDocGender(g));
}

function docGenderToString(prim) {
  return prim;
}

var docGenderFromString = BookingSessionDetail_query_graphql$Thick.Utils.docGender_fromString;

var allLegalDocGenders = [
  "F",
  "M",
  "X"
];

function docGenderNiceName(dg) {
  switch (dg) {
    case "M" :
        return "Male on Travel Documents";
    case "F" :
        return "Female on Travel Documents";
    case "X" :
        return "X on Travel Documents";
    
  }
}

var Query = {
  Single: Single,
  FetchOne: FetchOne,
  List: List,
  Save: Save,
  formatPaxInputArray: formatPaxInputArray,
  paxDetailArrayToPaxInputArrays: paxDetailArrayToPaxInputArrays,
  decodePaxTypeInput: decodePaxTypeInput,
  decodePaxTypeInputExn: decodePaxTypeInputExn,
  paxTypeToString: paxTypeToString,
  paxTypeFromString: paxTypeFromString,
  allLegalPaxTypes: allLegalPaxTypes,
  paxTypeNiceName: paxTypeNiceName,
  decodeDocGender: decodeDocGender,
  decodeDocGenderExn: decodeDocGenderExn,
  docGenderToString: docGenderToString,
  docGenderFromString: docGenderFromString,
  allLegalDocGenders: allLegalDocGenders,
  docGenderNiceName: docGenderNiceName
};

var billingContactAccountId = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.billingContactAccountId;
        }),
      write: (function (form, billingContactAccountId) {
          return {
                  target: form.target,
                  billingContactAccountId: billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, "", undefined);

var isBfcustFacing = HopperState$Util.Observable.FormField.makeBool({
      read: (function (param) {
          return param.isBfcustFacing;
        }),
      write: (function (form, isBfcustFacing) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, (function (v) {
        return {
                TAG: "Ok",
                _0: v
              };
      }), (function (v) {
        return v;
      }), false, undefined);

var bookingChannelSlug = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.bookingChannelSlug;
        }),
      write: (function (form, bookingChannelSlug) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, CS_Slugs$Util.BookingChannelSlug.fromPrimitive, CS_Slugs$Util.BookingChannelSlug.toPrimitive, "", undefined);

var fareClassSlug = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.fareClassSlug;
        }),
      write: (function (form, fareClassSlug) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, CS_Slugs$Util.FareClassSlug.fromPrimitive, CS_Slugs$Util.FareClassSlug.toPrimitive, "", undefined);

var cabinNumber = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.cabinNumber;
        }),
      write: (function (form, cabinNumber) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, CS_NonemptyStrings$Util.CabinNumber.fromPrimitive, CS_NonemptyStrings$Util.CabinNumber.toPrimitive, "", undefined);

var forOccupancy = HopperState$Util.Observable.FormField.makeInt({
      read: (function (param) {
          return param.forOccupancy;
        }),
      write: (function (form, forOccupancy) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, (function ($$int) {
        if ($$int > 0) {
          return {
                  TAG: "Ok",
                  _0: $$int
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Occupancies must be greater than 0"
                };
        }
      }), (function (v) {
        return v;
      }), 2, undefined);

var pifIntention = HopperState$Util.Observable.FormField.makeBool({
      read: (function (param) {
          return param.pifIntention;
        }),
      write: (function (form, pifIntention) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, (function (pifIntention) {
        return {
                TAG: "Ok",
                _0: pifIntention
              };
      }), (function (v) {
        return v;
      }), false, undefined);

var descriptiveNote = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.descriptiveNote;
        }),
      write: (function (form, descriptiveNote) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, (function (descriptiveNote) {
        var val = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(descriptiveNote);
        if (val.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: Caml_option.some(val._0)
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        }
      }), (function (v) {
        return Belt_Option.mapWithDefault(v, "", CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
      }), "", undefined);

var privateReservationNote = HopperState$Util.Observable.FormField.makeString({
      read: (function (param) {
          return param.privateReservationNote;
        }),
      write: (function (form, privateReservationNote) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: form.pax,
                  privateReservationNote: privateReservationNote
                };
        })
    }, (function (privateReservationNote) {
        var val = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(privateReservationNote);
        if (val.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: Caml_option.some(val._0)
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: undefined
                };
        }
      }), (function (v) {
        return Belt_Option.mapWithDefault(v, "", CS_NonemptyStrings$Util.NonEmptyString.toPrimitive);
      }), "", undefined);

var bookingSessionPassengerType = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.bookingSessionPassengerType;
        }),
      write: (function (form, bookingSessionPassengerType) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), HopperState$Util.Observable.FormField.makeStringableSchema(paxTypeToString, paxTypeFromString), {
      TAG: "Ok",
      _0: undefined
    }, Caml_option.some(undefined), undefined);

var existingUserAccountId = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.existingUserAccountId;
        }),
      write: (function (form, existingUserAccountId) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newCustodianAccountId = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newCustodianAccountId;
        }),
      write: (function (form, newCustodianAccountId) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.AccountId.fromPrimitive, CS_NonemptyStrings$Util.AccountId.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var invitationEmail = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.invitationEmail;
        }),
      write: (function (form, invitationEmail) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_Emails$Util.Email.fromPrimitive, CS_Emails$Util.Email.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocFirstname = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocFirstname;
        }),
      write: (function (form, newDocFirstname) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocLastname = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocLastname;
        }),
      write: (function (form, newDocLastname) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocBirthdate = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocBirthdate;
        }),
      write: (function (form, newDocBirthdate) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_SQLDatesAndTimes$Util.DateOnly.fromPrimitive, CS_SQLDatesAndTimes$Util.DateOnly.toPrimitive, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocCitizenship = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.newDocCitizenship;
        }),
      write: (function (form, newDocCitizenship) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocGender = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.newDocGender;
        }),
      write: (function (form, newDocGender) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), HopperState$Util.Observable.FormField.makeStringableSchema(docGenderToString, docGenderFromString), {
      TAG: "Error",
      _0: "This field is required"
    }, undefined, undefined);

var newDocHalMarinerNumber = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocHalMarinerNumber;
        }),
      write: (function (form, newDocHalMarinerNumber) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_LoyaltyNumbers$Util.HalMarinerNumber.fromPrimitive, CS_LoyaltyNumbers$Util.HalMarinerNumber.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var newDocPhone = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocPhone;
        }),
      write: (function (form, newDocPhone) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_Phone$Util.Phone.fromPrimitive, CS_Phone$Util.Phone.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var newDocPostalCode = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newDocPostalCode;
        }),
      write: (function (form, newDocPostalCode) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.PostalCode.fromPrimitive, CS_NonemptyStrings$Util.PostalCode.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var newDocResidenceCountry = HopperState$Util.Observable.FormField.makeOptional({
      read: (function (param) {
          return param.newDocResidenceCountry;
        }),
      write: (function (form, newDocResidenceCountry) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, (function (a) {
        return {
                TAG: "Ok",
                _0: a
              };
      }), (function (a) {
        return a;
      }), CS_ISO3166_1$Util.Alpha3.schema, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var newPreferredFirstName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newPreferredFirstName;
        }),
      write: (function (form, newPreferredFirstName) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: newPreferredFirstName,
                  newPreferredLastName: form.newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

var newPreferredLastName = HopperState$Util.Observable.FormField.makeOptionalString({
      read: (function (param) {
          return param.newPreferredLastName;
        }),
      write: (function (form, newPreferredLastName) {
          return {
                  idx: form.idx,
                  bookingSessionPassengerType: form.bookingSessionPassengerType,
                  existingUserAccountId: form.existingUserAccountId,
                  newCustodianAccountId: form.newCustodianAccountId,
                  invitationEmail: form.invitationEmail,
                  newDocFirstname: form.newDocFirstname,
                  newDocLastname: form.newDocLastname,
                  newDocBirthdate: form.newDocBirthdate,
                  newDocCitizenship: form.newDocCitizenship,
                  newDocGender: form.newDocGender,
                  newDocHalMarinerNumber: form.newDocHalMarinerNumber,
                  newDocPhone: form.newDocPhone,
                  newDocPostalCode: form.newDocPostalCode,
                  newDocResidenceCountry: form.newDocResidenceCountry,
                  newPreferredFirstName: form.newPreferredFirstName,
                  newPreferredLastName: newPreferredLastName
                };
        })
    }, CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive, CS_NonemptyStrings$Util.NonEmptyString.toPrimitive, {
      TAG: "Ok",
      _0: undefined
    }, undefined, undefined);

function blankModel(url) {
  return {
          form: undefined,
          paginationCursor: undefined,
          url: url,
          focusedSession: undefined,
          saveError: false
        };
}

function blankForm(target) {
  return {
          target: target,
          billingContactAccountId: billingContactAccountId.blankStore,
          isBfcustFacing: isBfcustFacing.blankStore,
          bookingChannelSlug: bookingChannelSlug.blankStore,
          fareClassSlug: fareClassSlug.blankStore,
          cabinNumber: cabinNumber.blankStore,
          forOccupancy: forOccupancy.blankStore,
          pifIntention: pifIntention.blankStore,
          descriptiveNote: descriptiveNote.blankStore,
          focusedPax: undefined,
          pax: undefined,
          privateReservationNote: privateReservationNote.blankStore
        };
}

function blankPax(idx) {
  return {
          idx: idx,
          bookingSessionPassengerType: bookingSessionPassengerType.blankStore,
          existingUserAccountId: existingUserAccountId.blankStore,
          newCustodianAccountId: newCustodianAccountId.blankStore,
          invitationEmail: invitationEmail.blankStore,
          newDocFirstname: newDocFirstname.blankStore,
          newDocLastname: newDocLastname.blankStore,
          newDocBirthdate: newDocBirthdate.blankStore,
          newDocCitizenship: newDocCitizenship.blankStore,
          newDocGender: newDocGender.blankStore,
          newDocHalMarinerNumber: newDocHalMarinerNumber.blankStore,
          newDocPhone: newDocPhone.blankStore,
          newDocPostalCode: newDocPostalCode.blankStore,
          newDocResidenceCountry: newDocResidenceCountry.blankStore,
          newPreferredFirstName: newPreferredFirstName.blankStore,
          newPreferredLastName: newPreferredLastName.blankStore
        };
}

function formView(view) {
  return HopperState$Util.Observable.makeView(view, {
              read: (function (m) {
                  return m.form;
                }),
              write: (function (m, form) {
                  return {
                          form: form,
                          paginationCursor: m.paginationCursor,
                          url: m.url,
                          focusedSession: m.focusedSession,
                          saveError: m.saveError
                        };
                })
            }, undefined);
}

function formExn(view) {
  return HopperState$Util.Observable.makeExnOptionView(view);
}

function paxView(view, idx) {
  return HopperState$Util.Observable.makeView(view, {
              read: (function (form) {
                  return Belt_MapInt.get(form.pax, idx);
                }),
              write: (function (form, paxOpt) {
                  return {
                          target: form.target,
                          billingContactAccountId: form.billingContactAccountId,
                          isBfcustFacing: form.isBfcustFacing,
                          bookingChannelSlug: form.bookingChannelSlug,
                          fareClassSlug: form.fareClassSlug,
                          cabinNumber: form.cabinNumber,
                          forOccupancy: form.forOccupancy,
                          pifIntention: form.pifIntention,
                          descriptiveNote: form.descriptiveNote,
                          focusedPax: form.focusedPax,
                          pax: paxOpt !== undefined ? Belt_MapInt.set(form.pax, idx, paxOpt) : Belt_MapInt.remove(form.pax, idx),
                          privateReservationNote: form.privateReservationNote
                        };
                })
            }, undefined);
}

function paxExn(view) {
  return HopperState$Util.Observable.makeExnOptionView(view);
}

var paxUtils = HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.emptyContainer(), bookingSessionPassengerType), existingUserAccountId), newCustodianAccountId), invitationEmail), newDocFirstname), newDocLastname), newDocBirthdate), newDocCitizenship), newDocGender), newDocHalMarinerNumber), newDocPhone), newDocPostalCode), newDocResidenceCountry), newPreferredFirstName), newPreferredLastName);

var formUtils = HopperState$Util.Observable.FormField.Group.addChildren(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.add(HopperState$Util.Observable.FormField.Group.container(function (optionalBookingSessionTarget) {
                                              return S$RescriptSchema.transform(S$RescriptSchema.$$null(CS_UUID$Util.ObjId.schema), (function (param) {
                                                            return {
                                                                    p: (function (opt) {
                                                                        return blankForm(Belt_Option.mapWithDefault(opt, "New", (function (obj) {
                                                                                          return {
                                                                                                  TAG: "Edit",
                                                                                                  _0: obj
                                                                                                };
                                                                                        })));
                                                                      }),
                                                                    s: (function (form) {
                                                                        var obj = form.target;
                                                                        if (typeof obj !== "object") {
                                                                          return ;
                                                                        } else {
                                                                          return Caml_option.some(obj._0);
                                                                        }
                                                                      })
                                                                  };
                                                          }));
                                            }), billingContactAccountId), isBfcustFacing), bookingChannelSlug), fareClassSlug), cabinNumber), forOccupancy), pifIntention), descriptiveNote), privateReservationNote), {
      read: (function (form) {
          return Belt_MapInt.toArray(form.pax);
        }),
      write: (function (form, array) {
          return {
                  target: form.target,
                  billingContactAccountId: form.billingContactAccountId,
                  isBfcustFacing: form.isBfcustFacing,
                  bookingChannelSlug: form.bookingChannelSlug,
                  fareClassSlug: form.fareClassSlug,
                  cabinNumber: form.cabinNumber,
                  forOccupancy: form.forOccupancy,
                  pifIntention: form.pifIntention,
                  descriptiveNote: form.descriptiveNote,
                  focusedPax: form.focusedPax,
                  pax: Belt_MapInt.fromArray(array),
                  privateReservationNote: form.privateReservationNote
                };
        })
    }, paxUtils, S$RescriptSchema.$$int, blankPax);

var makeSchema = formUtils.makeSchema;

function useIsDirty(view) {
  return HopperState$Util.Observable.FormField.useIsDirty(view, formUtils);
}

function markAsTouched(view) {
  HopperState$Util.Observable.FormField.markAsTouched(view, formUtils);
}

var formSchema = makeSchema("v_sep182024", (function () {
        return blankForm("New");
      }));

function validatePax(pax, $staropt$star) {
  var idx = pax.idx;
  var target = $staropt$star !== undefined ? $staropt$star : "ValidateInputValue";
  var match = pax.bookingSessionPassengerType.value;
  if (match === undefined) {
    return {
            TAG: "Error",
            _0: idx
          };
  }
  switch (match) {
    case "EXISTING_USER" :
        var match$1 = existingUserAccountId.validate(pax, target);
        if (match$1.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var accountId = match$1._0;
        if (accountId !== undefined) {
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "ExistingUser",
                    _0: {
                      idx: idx,
                      accountId: Caml_option.valFromOption(accountId)
                    }
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
    case "NEW_INVITATION" :
        var match$2 = invitationEmail.validate(pax, target);
        var match$3 = newPreferredFirstName.validate(pax, target);
        var match$4 = newPreferredLastName.validate(pax, target);
        if (match$2.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var invitationEmail$1 = match$2._0;
        if (invitationEmail$1 === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$3.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var preferredFirstName = match$3._0;
        if (preferredFirstName !== undefined && match$4.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "NewInvitation",
                    _0: {
                      idx: idx,
                      invitationEmail: Caml_option.valFromOption(invitationEmail$1),
                      preferredFirstName: Caml_option.valFromOption(preferredFirstName),
                      preferredLastName: match$4._0
                    }
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
    case "NEW_CONNECTED_USER" :
        var match$5 = newCustodianAccountId.validate(pax, target);
        var match$6 = newDocFirstname.validate(pax, target);
        var match$7 = newDocLastname.validate(pax, target);
        var match$8 = newDocBirthdate.validate(pax, target);
        var match$9 = newDocCitizenship.validate(pax, target);
        var match$10 = newDocGender.validate(pax, target);
        var match$11 = newDocHalMarinerNumber.validate(pax, target);
        var match$12 = newDocPhone.validate(pax, target);
        var match$13 = newDocPostalCode.validate(pax, target);
        var match$14 = newDocResidenceCountry.validate(pax, target);
        var match$15 = newPreferredFirstName.validate(pax, target);
        var match$16 = newPreferredLastName.validate(pax, target);
        if (match$5.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var custodianAccountId = match$5._0;
        if (custodianAccountId === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$6.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var docFirstname = match$6._0;
        if (docFirstname === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$7.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var docLastname = match$7._0;
        if (docLastname === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$8.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var docBirthdate = match$8._0;
        if (docBirthdate === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$9.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var docCitizenship = match$9._0;
        if (docCitizenship === undefined) {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        if (match$10.TAG !== "Ok") {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
        var docGender = match$10._0;
        if (docGender !== undefined && match$11.TAG === "Ok" && match$12.TAG === "Ok" && match$13.TAG === "Ok" && match$14.TAG === "Ok" && match$15.TAG === "Ok" && match$16.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "NewConnectedUser",
                    _0: {
                      idx: idx,
                      custodianAccountId: Caml_option.valFromOption(custodianAccountId),
                      docFirstname: Caml_option.valFromOption(docFirstname),
                      docLastname: Caml_option.valFromOption(docLastname),
                      docGender: docGender,
                      docCitizenship: Caml_option.valFromOption(docCitizenship),
                      docBirthdate: Caml_option.valFromOption(docBirthdate),
                      preferredFirstName: match$15._0,
                      preferredLastName: match$16._0,
                      phone: match$12._0,
                      postalCode: match$13._0,
                      residenceCountry: match$14._0,
                      halMarinerNumber: match$11._0
                    }
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: idx
                };
        }
    
  }
}

function aggregatePaxValidations(paxes, forOccupancy, target) {
  return Belt_Array.reduce(Belt_Array.reverse(Belt_Array.keep(paxes, (function (pa) {
                        if (pa.idx <= forOccupancy) {
                          return Belt_Result.mapWithDefault(bookingSessionPassengerType.validate(pa, target), false, Belt_Option.isSome);
                        } else {
                          return false;
                        }
                      }))), {
              TAG: "Ok",
              _0: []
            }, (function (carry, pa) {
                return Belt_Result.flatMap(validatePax(pa, target), (function (vPax) {
                              return Belt_Result.map(carry, (function (arr) {
                                            return Belt_Array.concat(arr, [vPax]);
                                          }));
                            }));
              }));
}

function prefill(view, sess) {
  HopperState$Util.Observable.notify(view, (function (form) {
          return descriptiveNote.setFromValid(pifIntention.setFromValid(forOccupancy.setFromValid(cabinNumber.setFromValid(fareClassSlug.setFromValid(bookingChannelSlug.setFromValid(isBfcustFacing.setFromValid(billingContactAccountId.setFromValid({
                                                  target: {
                                                    TAG: "Edit",
                                                    _0: sess.bookingSessionId
                                                  },
                                                  billingContactAccountId: form.billingContactAccountId,
                                                  isBfcustFacing: form.isBfcustFacing,
                                                  bookingChannelSlug: form.bookingChannelSlug,
                                                  fareClassSlug: form.fareClassSlug,
                                                  cabinNumber: form.cabinNumber,
                                                  forOccupancy: form.forOccupancy,
                                                  pifIntention: form.pifIntention,
                                                  descriptiveNote: form.descriptiveNote,
                                                  focusedPax: form.focusedPax,
                                                  pax: Belt_Array.reduce(sess.passenger.nodes, form.pax, (function (map, pa) {
                                                          var idx = pa.idx;
                                                          return Belt_MapInt.update(map, idx, (function (oPax) {
                                                                        return newPreferredLastName.setFromValid(newPreferredFirstName.setFromValid(newDocResidenceCountry.setFromValid(newDocPostalCode.setFromValid(newDocPhone.setFromValid(newDocHalMarinerNumber.setFromValid(newDocGender.setFromValid(newDocCitizenship.setFromValid(newDocBirthdate.setFromValid(newDocLastname.setFromValid(newDocFirstname.setFromValid(invitationEmail.setFromValid(newCustodianAccountId.setFromValid(existingUserAccountId.setFromValid(bookingSessionPassengerType.setFromValid(Belt_Option.getWithDefault(oPax, blankPax(idx)), Belt_Option.getExn(decodePaxTypeInput(pa.bookingSessionPassengerType)), "InitialValueAsPrefill"), pa.existingUserAccountId, "InitialValueAsPrefill"), pa.newCustodianAccountId, "InitialValueAsPrefill"), pa.invitationEmail, "InitialValueAsPrefill"), pa.newDocFirstname, "InitialValueAsPrefill"), pa.newDocLastname, "InitialValueAsPrefill"), pa.newDocBirthdate, "InitialValueAsPrefill"), pa.newDocCitizenship, "InitialValueAsPrefill"), Belt_Option.map(pa.newDocGender, decodeDocGenderExn), "InitialValueAsPrefill"), pa.newDocHalMarinerNumber, "InitialValueAsPrefill"), pa.newDocPhone, "InitialValueAsPrefill"), pa.newDocPostalCode, "InitialValueAsPrefill"), pa.newDocResidenceCountry, "InitialValueAsPrefill"), pa.newPreferredFirstName, "InitialValueAsPrefill"), pa.newPreferredLastName, "InitialValueAsPrefill");
                                                                      }));
                                                        })),
                                                  privateReservationNote: form.privateReservationNote
                                                }, sess.billingContactAccountId, "InitialValueAsPrefill"), sess.isBfcustFacing, "InitialValueAsPrefill"), sess.bookingChannelSlug, "InitialValueAsPrefill"), sess.fareClassSlug, "InitialValueAsPrefill"), sess.cabinNumber, "InitialValueAsPrefill"), sess.forOccupancy, "InitialValueAsPrefill"), sess.pifIntention, "InitialValueAsPrefill"), sess.descriptiveNote, "InitialValueAsPrefill");
        }));
}

function useValidatedForm(view, _brandFamilySlug, _voyageSlug, targetOpt, millisOpt) {
  var target = targetOpt !== undefined ? targetOpt : "ValidateInputValue";
  var millis = millisOpt !== undefined ? Caml_option.valFromOption(millisOpt) : undefined;
  return HopperState$Util.Observable.useComputed(view, (function (form) {
                var match = aggregatePaxValidations(Belt_MapInt.valuesToArray(form.pax), form.forOccupancy.value, target);
                var match$1 = billingContactAccountId.validate(form, target);
                var match$2 = isBfcustFacing.validate(form, target);
                var match$3 = bookingChannelSlug.validate(form, target);
                var match$4 = fareClassSlug.validate(form, target);
                var match$5 = cabinNumber.validate(form, target);
                var match$6 = forOccupancy.validate(form, target);
                var match$7 = pifIntention.validate(form, target);
                var match$8 = descriptiveNote.validate(form, target);
                if (match.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: match._0
                        };
                }
                if (match$1.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$2.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$3.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$4.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$5.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$6.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$7.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                if (match$8.TAG !== "Ok") {
                  return {
                          TAG: "Error",
                          _0: undefined
                        };
                }
                var match$9 = paxDetailArrayToPaxInputArrays(match._0, (function (a) {
                        return a;
                      }));
                var objId = form.target;
                var tmp;
                tmp = typeof objId !== "object" ? undefined : Caml_option.some(objId._0);
                return {
                        TAG: "Ok",
                        _0: {
                          _brandFamilySlug: _brandFamilySlug,
                          _voyageSlug: _voyageSlug,
                          _billingContactAccountId: match$1._0,
                          _isBfcustFacing: match$2._0,
                          _bookingChannelSlug: match$3._0,
                          _fareClassSlug: match$4._0,
                          _cabinNumber: match$5._0,
                          _forOccupancy: match$6._0,
                          _pifIntention: match$7._0,
                          _attachExistingPax: match$9[0],
                          _attachInvitedPax: match$9[1],
                          _attachNewConnectedPax: match$9[2],
                          _descriptiveNote: match$8._0,
                          _bookingSessionId: tmp
                        }
                      };
              }), undefined, HopperState$Util.Observable.useOptionalDebounceIntervention(Caml_option.some(millis)));
}

function fieldToString(field) {
  return "sess." + field;
}

function queryStateLens_read(m) {
  return m;
}

function queryStateLens_write(param, m) {
  return m;
}

var queryStateLens = {
  read: queryStateLens_read,
  write: queryStateLens_write
};

function getParser(url) {
  var objId = QueryString$Util.Codec.customScalar(CS_UUID$Util.ObjId);
  var iso31661 = QueryString$Util.Codec.customScalar(CS_ISO3166_1$Util.Alpha3);
  var passengerInputType = QueryString$Util.Codec.customStringable({
        legalVals: allLegalPaxTypes,
        toString: paxTypeToString
      });
  var docGenderInputType = QueryString$Util.Codec.customStringable({
        legalVals: [
          "M",
          "F",
          "X"
        ],
        toString: docGenderToString
      });
  var formLens_read = function (param) {
    return param.form;
  };
  var formLens_write = function (m, a) {
    return {
            form: a,
            paginationCursor: m.paginationCursor,
            url: m.url,
            focusedSession: m.focusedSession,
            saveError: m.saveError
          };
  };
  var formLens = {
    read: formLens_read,
    write: formLens_write
  };
  var formField = function (param, field, token) {
    var changedInputLens = param.changedInputLens;
    var chained = HopperState$Util.Lens.chain(formLens, {
          read: (function (oForm) {
              return Belt_Option.flatMap(oForm, (function (form) {
                            return changedInputLens.read(form);
                          }));
            }),
          write: (function (oForm, oVal) {
              return changedInputLens.write(Belt_Option.getWithDefault(oForm, blankForm("New")), oVal);
            })
        });
    return QueryString$Util.Param.Singular.make(field, fieldToString, QueryString$Util.Codec.scalar(token), undefined, (function (parent, opt) {
                  return Belt_Option.mapWithDefault(opt, parent, (function (value) {
                                return chained.write(parent, value);
                              }));
                }), (function (v) {
                  return Caml_option.some(chained.read(v));
                }));
  };
  var billingContactAccountId$1 = formField(billingContactAccountId, "billingAcct", QueryString$Util.Codec.string);
  var isBfcustFacing$1 = formField(isBfcustFacing, "custVisible", QueryString$Util.Codec.bool);
  var bookingChannelSlug$1 = formField(bookingChannelSlug, "channel", QueryString$Util.Codec.string);
  var fareClassSlug$1 = formField(fareClassSlug, "fareCls", QueryString$Util.Codec.string);
  var cabinNumber$1 = formField(cabinNumber, "cabin", QueryString$Util.Codec.string);
  var forOccupancy$1 = formField(forOccupancy, "occ", QueryString$Util.Codec.$$int);
  var pifIntention$1 = formField(pifIntention, "willPif", QueryString$Util.Codec.bool);
  var descriptiveNote$1 = formField(descriptiveNote, "note", QueryString$Util.Codec.string);
  var privateReservationNote$1 = formField(privateReservationNote, "privResNote", QueryString$Util.Codec.string);
  var paxField = function (param, field, token) {
    var changedInputLens = param.changedInputLens;
    var paxFieldLens = function (idx) {
      return HopperState$Util.Lens.chain(HopperState$Util.Lens.chain(formLens, {
                      read: (function (formOpt) {
                          return Belt_Option.flatMap(formOpt, (function (param) {
                                        return Belt_MapInt.get(param.pax, idx);
                                      }));
                        }),
                      write: (function (oForm, oPax) {
                          var formBase = Belt_Option.getWithDefault(oForm, blankForm("New"));
                          var paxBase = formBase.pax;
                          return {
                                  target: formBase.target,
                                  billingContactAccountId: formBase.billingContactAccountId,
                                  isBfcustFacing: formBase.isBfcustFacing,
                                  bookingChannelSlug: formBase.bookingChannelSlug,
                                  fareClassSlug: formBase.fareClassSlug,
                                  cabinNumber: formBase.cabinNumber,
                                  forOccupancy: formBase.forOccupancy,
                                  pifIntention: formBase.pifIntention,
                                  descriptiveNote: formBase.descriptiveNote,
                                  focusedPax: formBase.focusedPax,
                                  pax: oPax !== undefined ? Belt_MapInt.set(paxBase, idx, oPax) : Belt_MapInt.remove(paxBase, idx),
                                  privateReservationNote: formBase.privateReservationNote
                                };
                        })
                    }), {
                  read: (function (oPax) {
                      return Belt_Option.flatMap(Belt_Option.flatMap(oPax, (function (pax) {
                                        return changedInputLens.read(pax);
                                      })), (function (a) {
                                    return a;
                                  }));
                    }),
                  write: (function (oPax, oVal) {
                      return changedInputLens.write(Belt_Option.getWithDefault(oPax, blankPax(idx)), Caml_option.some(oVal));
                    })
                });
    };
    return QueryString$Util.Param.Multiple.Custom.make(field, fieldToString, QueryString$Util.Codec.tuple2(QueryString$Util.Codec.required(QueryString$Util.Codec.$$int), token), (function (root, oarr) {
                  return Belt_Array.reduce(Belt_Option.getWithDefault(oarr, []), root, (function (root, param) {
                                return paxFieldLens(param[0]).write(root, param[1]);
                              }));
                }), (function (model) {
                  return Belt_Option.map(model.form, (function (param) {
                                return Belt_MapInt.reduce(param.pax, [], (function (carry, idx, pax) {
                                              var v = changedInputLens.read(pax);
                                              if (v !== undefined) {
                                                return Belt_Array.concat(carry, [[
                                                              idx,
                                                              Caml_option.valFromOption(v)
                                                            ]]);
                                              } else {
                                                return carry;
                                              }
                                            }));
                              }));
                }), {
                decode: (function (a) {
                    return a;
                  }),
                encode: (function (oarr) {
                    return Belt_Option.getWithDefault(oarr, []);
                  })
              });
  };
  var bookingSessionPassengerType$1 = paxField(bookingSessionPassengerType, "paxType", passengerInputType);
  var existingUserAccountId$1 = paxField(existingUserAccountId, "bfcust", QueryString$Util.Codec.string);
  var newCustodianAccountId$1 = paxField(newCustodianAccountId, "custodian", QueryString$Util.Codec.string);
  var invitationEmail$1 = paxField(invitationEmail, "invite", QueryString$Util.Codec.string);
  var newDocFirstname$1 = paxField(newDocFirstname, "first", QueryString$Util.Codec.string);
  var newDocLastname$1 = paxField(newDocLastname, "last", QueryString$Util.Codec.string);
  var newDocBirthdate$1 = paxField(newDocBirthdate, "bday", QueryString$Util.Codec.string);
  var newDocCitizenship$1 = paxField(newDocCitizenship, "ctz", iso31661);
  var newDocGender$1 = paxField(newDocGender, "docgen", docGenderInputType);
  var newDocHalMarinerNumber$1 = paxField(newDocHalMarinerNumber, "halNum", QueryString$Util.Codec.string);
  var newDocPhone$1 = paxField(newDocPhone, "phone", QueryString$Util.Codec.string);
  var newDocPostalCode$1 = paxField(newDocPostalCode, "postal", QueryString$Util.Codec.string);
  var newDocResidenceCountry$1 = paxField(newDocResidenceCountry, "reside", iso31661);
  var newPreferredFirstName$1 = paxField(newPreferredFirstName, "prefFirst", QueryString$Util.Codec.string);
  var newPreferredLastName$1 = paxField(newPreferredLastName, "prefLast", QueryString$Util.Codec.string);
  return QueryString$Util.Parser.makeExtended([
              QueryString$Util.Param.Singular.make("edit", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(objId)), undefined, (function (model, opt) {
                      return Belt_Option.mapWithDefault(opt, model, (function (objId) {
                                    var init = Belt_Option.getWithDefault(model.form, blankForm("New"));
                                    return {
                                            form: {
                                              target: {
                                                TAG: "Edit",
                                                _0: objId
                                              },
                                              billingContactAccountId: init.billingContactAccountId,
                                              isBfcustFacing: init.isBfcustFacing,
                                              bookingChannelSlug: init.bookingChannelSlug,
                                              fareClassSlug: init.fareClassSlug,
                                              cabinNumber: init.cabinNumber,
                                              forOccupancy: init.forOccupancy,
                                              pifIntention: init.pifIntention,
                                              descriptiveNote: init.descriptiveNote,
                                              focusedPax: init.focusedPax,
                                              pax: init.pax,
                                              privateReservationNote: init.privateReservationNote
                                            },
                                            paginationCursor: model.paginationCursor,
                                            url: model.url,
                                            focusedSession: model.focusedSession,
                                            saveError: model.saveError
                                          };
                                  }));
                    }), (function (model) {
                      return Belt_Option.flatMap(model.form, (function (param) {
                                    var target = param.target;
                                    if (typeof target !== "object") {
                                      return ;
                                    } else {
                                      return Caml_option.some(target._0);
                                    }
                                  }));
                    })),
              QueryString$Util.Param.Singular.make("new", fieldToString, QueryString$Util.Codec.scalar(QueryString$Util.Codec.required(QueryString$Util.Codec.bool)), undefined, (function (model, bopt) {
                      if (Belt_Option.getWithDefault(bopt, false)) {
                        return {
                                form: Belt_Option.getWithDefault(model.form, blankForm("New")),
                                paginationCursor: model.paginationCursor,
                                url: model.url,
                                focusedSession: model.focusedSession,
                                saveError: model.saveError
                              };
                      } else {
                        return model;
                      }
                    }), (function (model) {
                      return Belt_Option.flatMap(model.form, (function (param) {
                                    var tmp = param.target;
                                    if (typeof tmp !== "object") {
                                      return true;
                                    }
                                    
                                  }));
                    })),
              billingContactAccountId$1,
              isBfcustFacing$1,
              bookingChannelSlug$1,
              fareClassSlug$1,
              cabinNumber$1,
              forOccupancy$1,
              pifIntention$1,
              descriptiveNote$1,
              privateReservationNote$1,
              bookingSessionPassengerType$1,
              existingUserAccountId$1,
              newCustodianAccountId$1,
              invitationEmail$1,
              newDocFirstname$1,
              newDocLastname$1,
              newDocBirthdate$1,
              newDocCitizenship$1,
              newDocGender$1,
              newDocHalMarinerNumber$1,
              newDocPhone$1,
              newDocPostalCode$1,
              newDocResidenceCountry$1,
              newPreferredFirstName$1,
              newPreferredLastName$1
            ], blankModel(url));
}

var QueryString = {
  queryStateLens: queryStateLens,
  getParser: getParser
};

var convertVariables$2 = BookingSessionCabinByPrivNoteQuery_graphql$Thick.Internal.convertVariables;

var convertResponse$2 = BookingSessionCabinByPrivNoteQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse$2 = BookingSessionCabinByPrivNoteQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use$4 = RescriptRelay_Query.useQuery(convertVariables$2, BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, convertResponse$2);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$2, BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, convertResponse$2, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, convertResponse$2, convertVariables$2);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, convertResponse$2, convertVariables$2);

var retain$1 = RescriptRelay_Query.retain(BookingSessionCabinByPrivNoteQuery_graphql$Thick.node, convertVariables$2);

var CabinByPrivNoteQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$2,
  convertResponse: convertResponse$2,
  convertWrapRawResponse: convertWrapRawResponse$2,
  use: use$4,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function setCabinFromPrivateReservationNote(form, brandFamily, voyage) {
  var match = HopperState$Util.Observable.useComputed(form, (function (f) {
          return [
                  f.privateReservationNote.value,
                  f.cabinNumber.value
                ];
        }), undefined, undefined);
  var match$1 = CS_NonemptyStrings$Util.CabinNumber.fromPrimitive(match[1]);
  var match$2 = CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(match[0]);
  if (match$1.TAG === "Ok" || match$2.TAG !== "Ok") {
    return ;
  } else {
    return $$fetch$1(RelayEnv$Thick.environment, {
                brandFamily: CS_Slugs$Util.BrandFamilySlug.toPrimitive(brandFamily),
                privResNote: match$2._0,
                voyage: CS_Slugs$Util.VoyageSlug.toPrimitive(voyage)
              }, (function (res) {
                  if (res.TAG !== "Ok") {
                    return ;
                  }
                  var match = res._0.cabins;
                  if (match === undefined) {
                    return ;
                  }
                  var match$1 = match.nodes;
                  if (match$1.length !== 1) {
                    return ;
                  }
                  var match$2 = match$1[0];
                  var cn = match$2.cabinNumber;
                  HopperState$Util.Observable.notify(form, (function (f) {
                          return cabinNumber.set(f, CS_NonemptyStrings$Util.CabinNumber.toString(cn), undefined);
                        }));
                }), undefined, undefined);
  }
}

exports.UnsupportedBookingSessionPaxType = UnsupportedBookingSessionPaxType;
exports.Query = Query;
exports.billingContactAccountId = billingContactAccountId;
exports.isBfcustFacing = isBfcustFacing;
exports.bookingChannelSlug = bookingChannelSlug;
exports.fareClassSlug = fareClassSlug;
exports.cabinNumber = cabinNumber;
exports.forOccupancy = forOccupancy;
exports.pifIntention = pifIntention;
exports.descriptiveNote = descriptiveNote;
exports.privateReservationNote = privateReservationNote;
exports.bookingSessionPassengerType = bookingSessionPassengerType;
exports.existingUserAccountId = existingUserAccountId;
exports.newCustodianAccountId = newCustodianAccountId;
exports.invitationEmail = invitationEmail;
exports.newDocFirstname = newDocFirstname;
exports.newDocLastname = newDocLastname;
exports.newDocBirthdate = newDocBirthdate;
exports.newDocCitizenship = newDocCitizenship;
exports.newDocGender = newDocGender;
exports.newDocHalMarinerNumber = newDocHalMarinerNumber;
exports.newDocPhone = newDocPhone;
exports.newDocPostalCode = newDocPostalCode;
exports.newDocResidenceCountry = newDocResidenceCountry;
exports.newPreferredFirstName = newPreferredFirstName;
exports.newPreferredLastName = newPreferredLastName;
exports.blankModel = blankModel;
exports.blankForm = blankForm;
exports.blankPax = blankPax;
exports.formView = formView;
exports.formExn = formExn;
exports.paxView = paxView;
exports.paxExn = paxExn;
exports.paxUtils = paxUtils;
exports.makeSchema = makeSchema;
exports.formUtils = formUtils;
exports.useIsDirty = useIsDirty;
exports.markAsTouched = markAsTouched;
exports.formSchema = formSchema;
exports.prefill = prefill;
exports.useValidatedForm = useValidatedForm;
exports.validatePax = validatePax;
exports.aggregatePaxValidations = aggregatePaxValidations;
exports.fieldToString = fieldToString;
exports.QueryString = QueryString;
exports.CabinByPrivNoteQuery = CabinByPrivNoteQuery;
exports.setCabinFromPrivateReservationNote = setCabinFromPrivateReservationNote;
/* use Not a pure module */
