// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Set = require("rescript/lib/js/belt_Set.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var Textarea = require("@mui/joy/Textarea").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var ChangeCabinNotesSubmit$Thick = require("./mutations/ChangeCabinNotesSubmit.bs.js");

function ChangeCabinNotesForm(props) {
  var onClose = props.onClose;
  var match = React.useState(function () {
        return ChangeCabinNotesSubmit$Thick.Utils.empty;
      });
  var setUpdate = match[1];
  var update = match[0];
  var clearAll = function (param) {
    setUpdate(function (param) {
          return ChangeCabinNotesSubmit$Thick.Utils.empty;
        });
  };
  var cancel = function (param) {
    clearAll();
    onClose();
  };
  var match$1 = ChangeCabinNotesSubmit$Thick.useMutation(props.brandFamily, CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(props.voyage), Belt_Array.map(Belt_Set.toArray(props.selectedCabins), (function (cabin) {
              return Caml_option.some(cabin);
            })), props.refetch, props.setSelectedCabins, cancel, props.setError);
  var changeCabinNotes = match$1.changeCabinNotes;
  var onSubmit = function (e) {
    e.preventDefault();
    var record = ChangeCabinNotesSubmit$Thick.Utils.notesIntermToCabinNotes(update);
    if (record.TAG === "Ok") {
      changeCabinNotes(record._0);
      return ;
    }
    console.error("Please fill out all fields.");
  };
  var val = update.noteConfig;
  var val$1 = update.noteReservation;
  var match$2 = update.noteReservation;
  var match$3 = update.noteConfig;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: true,
              onClose: onClose,
              title: "Update Cabin Notes",
              children: [
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "title-md",
                              children: "Enter notes to replace the existing notes for all selected cabins. \n        Leaving the input blank will avoid changing that field from the current values."
                            }),
                        JsxRuntime.jsxs(FormControl, {
                              error: val !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val)) : true,
                              children: [
                                JsxRuntime.jsx(FormLabel, {
                                      children: "Configuration Notes"
                                    }),
                                JsxRuntime.jsx(Textarea, {
                                      value: Belt_Option.getWithDefault(update.noteConfig, ""),
                                      required: false,
                                      onChange: (function (e) {
                                          var val = e.currentTarget.value;
                                          if (val === "") {
                                            return setUpdate(function (param) {
                                                        return {
                                                                noteReservation: update.noteReservation,
                                                                noteConfig: undefined
                                                              };
                                                      });
                                          } else {
                                            return setUpdate(function (param) {
                                                        return {
                                                                noteReservation: update.noteReservation,
                                                                noteConfig: val
                                                              };
                                                      });
                                          }
                                        })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(FormControl, {
                              error: val$1 !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val$1)) : true,
                              children: [
                                JsxRuntime.jsx(FormLabel, {
                                      children: "Reservation notes"
                                    }),
                                JsxRuntime.jsx(Textarea, {
                                      value: Belt_Option.getWithDefault(update.noteReservation, ""),
                                      required: false,
                                      onChange: (function (e) {
                                          var val = e.currentTarget.value;
                                          if (val === "") {
                                            return setUpdate(function (param) {
                                                        return {
                                                                noteReservation: undefined,
                                                                noteConfig: update.noteConfig
                                                              };
                                                      });
                                          } else {
                                            return setUpdate(function (param) {
                                                        return {
                                                                noteReservation: val,
                                                                noteConfig: update.noteConfig
                                                              };
                                                      });
                                          }
                                        })
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                onSubmit: onSubmit,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: cancel,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$1.isMutating,
                                        type: "submit",
                                        disabled: match$2 !== undefined ? false : match$3 === undefined,
                                        children: "Update Selected Cabins"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = ChangeCabinNotesForm;

exports.make = make;
/* react Not a pure module */
