// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var $$String = require("rescript/lib/js/string.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var Add = require("@mui/icons-material/Add").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;
var Delete = require("@mui/icons-material/Delete").default;
var CabinInventoryFilters$Thick = require("../../utils/CabinInventoryFilters.bs.js");

function FilterMenu$Rule(props) {
  var match = props.filterState;
  var check = match.check;
  var setNoun = match.setNoun;
  var setVerb = match.setVerb;
  var setColumn = match.setColumn;
  var isDisabled = match.isDisabled;
  var dropCondition = match.dropCondition;
  var condition = props.condition;
  var tmp;
  tmp = typeof condition !== "object" ? undefined : condition.column;
  var tmp$1;
  tmp$1 = typeof condition !== "object" ? null : Belt_Array.map(condition.column.sortedVerbs, (function (value) {
            return JsxRuntime.jsx($$Option, {
                        value: value,
                        children: Caml_option.some(value.desc)
                      }, value.id + "-select");
          }));
  var tmp$2;
  tmp$2 = typeof condition !== "object" ? true : false;
  var tmp$3;
  if (typeof condition !== "object") {
    tmp$3 = undefined;
  } else {
    var verb = condition.verb;
    tmp$3 = verb !== undefined ? verb : undefined;
  }
  var tmp$4;
  if (typeof condition !== "object") {
    tmp$4 = null;
  } else {
    var match$1 = condition.verb;
    if (match$1 !== undefined) {
      var exit = 0;
      if (condition.column.id === "cq.cabinStatus" && match$1.id === "eq") {
        var tmp$5;
        if (typeof condition !== "object") {
          tmp$5 = undefined;
        } else {
          var noun = condition.noun;
          tmp$5 = noun !== undefined ? noun : undefined;
        }
        tmp$4 = JsxRuntime.jsx(Joy$Util.JoySelect.make, {
              children: Caml_option.some(Belt_Array.map([
                        "Available",
                        "Booked",
                        "Held"
                      ], (function (v) {
                          return JsxRuntime.jsx($$Option, {
                                      value: $$String.lowercase_ascii(v),
                                      children: Caml_option.some(v)
                                    });
                        }))),
              onChange: (function (param, val) {
                  if (val === undefined) {
                    return ;
                  }
                  var val$1 = Caml_option.valFromOption(val);
                  if (val$1 !== undefined) {
                    return setNoun(condition, val$1);
                  }
                  
                }),
              placeholder: "Select value...",
              sx: {
                width: "10rem",
                height: "1rem"
              },
              value: tmp$5
            });
      } else {
        exit = 1;
      }
      if (exit === 1) {
        var tmp$6;
        if (typeof condition !== "object") {
          tmp$6 = true;
        } else {
          var match$2 = condition.verb;
          if (match$2 !== undefined) {
            switch (match$2.id) {
              case "isFalse" :
              case "isTrue" :
                  tmp$6 = true;
                  break;
              default:
                tmp$6 = false;
            }
          } else {
            tmp$6 = false;
          }
        }
        var tmp$7;
        if (typeof condition !== "object") {
          tmp$7 = "";
        } else {
          var match$3 = condition.verb;
          if (match$3 !== undefined) {
            var noun$1 = condition.noun;
            if (noun$1 !== undefined) {
              switch (match$3.id) {
                case "isFalse" :
                    tmp$7 = "false";
                    break;
                case "isTrue" :
                    tmp$7 = "true";
                    break;
                default:
                  tmp$7 = noun$1;
              }
            } else {
              tmp$7 = "";
            }
          } else {
            tmp$7 = "";
          }
        }
        var tmp$8;
        tmp$8 = typeof condition !== "object" ? null : Belt_Array.map(condition.column.sortedVerbs, (function (param) {
                  var value = param.id;
                  return JsxRuntime.jsx($$Option, {
                              value: value,
                              children: Caml_option.some(param.desc)
                            }, value + "-select");
                }));
        tmp$4 = JsxRuntime.jsx(Joy$Util.Input.make, {
              type_: "text",
              size: "sm",
              placeholder: "Enter value...",
              disabled: tmp$6,
              sx: {
                height: "100%",
                width: "100%"
              },
              value: tmp$7,
              onChange: (function (e) {
                  var val = e.currentTarget.value;
                  setNoun(condition, val);
                }),
              children: Caml_option.some(tmp$8)
            });
      }
      
    } else {
      tmp$4 = null;
    }
  }
  var errorText = check(condition);
  var tmp$9;
  tmp$9 = errorText.TAG === "Ok" ? null : JsxRuntime.jsx(FormHelperText, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "body-xs",
                    children: Caml_option.some(errorText._0)
                  })),
          sx: {
            position: "absolute",
            top: "1.8rem"
          }
        });
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        spacing: 1,
                        sx: {
                          height: "1rem",
                          py: 2.5
                        },
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "body-md",
                                children: Caml_option.some(props.first ? "Where" : "and")
                              }),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                sx: {
                                  width: "100%"
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                children: Caml_option.some(Belt_Array.map(CabinInventoryFilters$Thick.ColumnFilter.sortedFilters, (function (value) {
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: value,
                                                                        children: Caml_option.some(value.niceName),
                                                                        disabled: isDisabled(value)
                                                                      }, value.id);
                                                          }))),
                                                onChange: (function (param, val) {
                                                    if (val === undefined) {
                                                      return ;
                                                    }
                                                    var col = Caml_option.valFromOption(val);
                                                    if (col !== undefined) {
                                                      return setColumn(condition, col);
                                                    }
                                                    
                                                  }),
                                                placeholder: "Select column...",
                                                size: "sm",
                                                sx: {
                                                  width: "10rem",
                                                  height: "1rem"
                                                },
                                                value: tmp
                                              }),
                                          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                children: Caml_option.some(tmp$1),
                                                disabled: tmp$2,
                                                onChange: (function (param, val) {
                                                    if (val === undefined) {
                                                      return ;
                                                    }
                                                    var verb = Caml_option.valFromOption(val);
                                                    if (verb !== undefined) {
                                                      return setVerb(condition, verb);
                                                    }
                                                    
                                                  }),
                                                placeholder: "Select condition...",
                                                size: "sm",
                                                sx: {
                                                  width: "10rem",
                                                  height: "1rem"
                                                },
                                                value: tmp$3
                                              }),
                                          JsxRuntime.jsxs(FormControl, {
                                                error: Belt_Result.isError(check(condition)),
                                                sx: {
                                                  position: "relative",
                                                  width: "10rem"
                                                },
                                                children: [
                                                  tmp$4,
                                                  tmp$9
                                                ]
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            dropCondition(condition);
                                          }),
                                        color: "danger",
                                        children: Caml_option.some(JsxRuntime.jsx(Delete, {}))
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var Rule = {
  make: FilterMenu$Rule
};

function FilterMenu(props) {
  var filterState = props.filterState;
  var match = filterState.conditionFilters;
  var newQueryString = match[1];
  var addCondition = filterState.addCondition;
  var conditions = filterState.conditions;
  var handleClose = props.handleClose;
  var path = props.path;
  var canApply = Caml_obj.notequal(filterState.urlFilters[0], match[0]);
  var applyFiltersToUrl = function (param) {
    if (canApply) {
      return RescriptReactRouter.replace(Belt_Option.mapWithDefault(newQueryString, path, (function (queryString) {
                        return path + "?" + queryString;
                      })));
    }
    
  };
  React.useEffect((function () {
          if (Belt_Option.isNone(newQueryString)) {
            applyFiltersToUrl();
          }
          
        }), [newQueryString]);
  var onSubmit = canApply ? (function (e) {
        e.preventDefault();
        applyFiltersToUrl();
        handleClose();
      }) : undefined;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              component: "form",
              spacing: 1,
              sx: {
                p: 2
              },
              onSubmit: onSubmit,
              children: [
                conditions.length !== 0 ? JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 1,
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                children: "Show me cabins..."
                              }),
                          Belt_Array.mapWithIndex(conditions, (function (idx, condition) {
                                  return JsxRuntime.jsx(FilterMenu$Rule, {
                                              first: idx === 0,
                                              condition: condition,
                                              filterState: filterState
                                            }, String(idx));
                                }))
                        ]
                      }) : JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        children: "No filters are currently applied."
                      }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      spacing: 1,
                      sx: {
                        pt: 1
                      },
                      children: [
                        JsxRuntime.jsx(Button, {
                              onClick: addCondition,
                              startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
                              variant: "outlined",
                              size: "sm",
                              disabled: Belt_Option.isNone(addCondition),
                              children: "Add rule"
                            }),
                        JsxRuntime.jsx(Button, {
                              type: "submit",
                              onClick: onSubmit,
                              color: "primary",
                              size: "sm",
                              disabled: Belt_Option.isNone(onSubmit),
                              children: "Apply filter"
                            })
                      ]
                    })
              ]
            });
}

var make = FilterMenu;

exports.Rule = Rule;
exports.make = make;
/* react Not a pure module */
