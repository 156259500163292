// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var HoldCabinSubmit_HoldCabinMutation_graphql$Thick = require("../../../__generated__/HoldCabinSubmit_HoldCabinMutation_graphql.bs.js");

var convertVariables = HoldCabinSubmit_HoldCabinMutation_graphql$Thick.Internal.convertVariables;

var convertResponse = HoldCabinSubmit_HoldCabinMutation_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = HoldCabinSubmit_HoldCabinMutation_graphql$Thick.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, HoldCabinSubmit_HoldCabinMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, HoldCabinSubmit_HoldCabinMutation_graphql$Thick.node, convertResponse, convertWrapRawResponse);

var HoldCabinMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(_brandFamilySlug, _voyageSlug, _cabinNumber, refetch, onClose, setError) {
  var match = use();
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError$1 = match$1[1];
  var holdCabin = function (param) {
    mutate({
          input: {
            _brandFamilySlug: _brandFamilySlug,
            _voyageSlug: _voyageSlug,
            _fareClassSlug: param._fareClassSlug,
            _cabinNumber: _cabinNumber,
            _forOccupancy: param._forOccupancy
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.holdCabin;
              if (match !== undefined && match.datetime !== undefined) {
                console.log("Successfully held cabin");
                refetch();
                onClose();
                setError$1(function (param) {
                      
                    });
                return ;
              }
              console.log("Failed to hold cabin");
              setError$1(function (param) {
                    return "Failed to hold cabin";
                  });
              return ;
            }
            if (err !== undefined) {
              var error = Belt_Array.reduce(err, "", (function (carry, obj) {
                      if (carry === "") {
                        return obj.message;
                      } else {
                        return carry + ", " + obj.message;
                      }
                    }));
              console.log(error);
              setError$1(function (param) {
                    return "Error holding cabin";
                  });
              return ;
            }
            console.log("Unknown error holding cabin");
          }), (function (err) {
            console.log(err);
            setError$1(function (param) {
                  return "Error holding cabin";
                });
          }), undefined);
  };
  return {
          error: match$1[0],
          setError: setError$1,
          isMutating: match[1],
          holdCabin: holdCabin
        };
}

function cabinHoldIntermToCabinHold(i) {
  return Belt_Result.map(CS_Slugs$Util.FareClassSlug.fromPrimitive(i.holdFareClass), (function (v) {
                return {
                        _fareClassSlug: v,
                        _forOccupancy: i.holdOccupancy
                      };
              }));
}

var Utils_empty = {
  holdFareClass: "retail",
  holdOccupancy: 2
};

var Utils = {
  empty: Utils_empty,
  cabinHoldIntermToCabinHold: cabinHoldIntermToCabinHold
};

exports.HoldCabinMutation = HoldCabinMutation;
exports.useMutation = useMutation;
exports.Utils = Utils;
/* commitMutation Not a pure module */
