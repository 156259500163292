// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../common/molecules/Dialog.bs.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var HoldCabinSubmit$Thick = require("./mutations/HoldCabinSubmit.bs.js");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var HoldCabinForm_query_graphql$Thick = require("../../__generated__/HoldCabinForm_query_graphql.bs.js");

var convertFragment = HoldCabinForm_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(HoldCabinForm_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, HoldCabinForm_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(HoldCabinForm_query_graphql$Thick.node, convertFragment, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function HoldCabinForm(props) {
  var onClose = props.onClose;
  var holdCabinVars = props.holdCabinVars;
  var query = use(props.fragmentRefs);
  var match = React.useState(function () {
        return HoldCabinSubmit$Thick.Utils.empty;
      });
  var setUpdate = match[1];
  var update = match[0];
  var match$1 = React.useState(function () {
        
      });
  var isOpen = Belt_Option.isSome(holdCabinVars);
  var match$2 = holdCabinVars !== undefined ? [
      holdCabinVars[0],
      holdCabinVars[1]
    ] : [
      CS_NonemptyStrings$Util.CabinNumber.Exn.fromPrimitive("1000"),
      {
        end: undefined,
        start: undefined
      }
    ];
  var occRange = match$2[1];
  var rangeStart = occRange.start;
  var rangeEnd = occRange.end;
  var selectedCabin = match$2[0];
  var salesRange = rangeStart !== undefined && rangeEnd !== undefined ? Belt_Array.range(rangeStart.inclusive ? rangeStart.value : rangeStart.value + 1 | 0, rangeEnd.inclusive ? rangeEnd.value : rangeEnd.value - 1 | 0) : Belt_Array.range(1, 2);
  var clearAll = function (param) {
    setUpdate(function (param) {
          return HoldCabinSubmit$Thick.Utils.empty;
        });
  };
  var cancel = function (param) {
    clearAll();
    onClose();
  };
  var match$3 = HoldCabinSubmit$Thick.useMutation(props.brandFamily, CS_Slugs$Util.VoyageSlug.Exn.fromPrimitive(props.voyage), selectedCabin, props.refetch, cancel, match$1[1]);
  var holdCabin = match$3.holdCabin;
  var setError = match$3.setError;
  var error = match$3.error;
  var onSubmit = function (e) {
    e.preventDefault();
    var record = HoldCabinSubmit$Thick.Utils.cabinHoldIntermToCabinHold(update);
    if (record.TAG === "Ok") {
      holdCabin(record._0);
      return ;
    }
    console.error("Please fill out all required fields.");
  };
  var match$4 = query.voyageFareClasses;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: isOpen,
              onClose: (function () {
                  onClose();
                  setError(function (param) {
                        
                      });
                }),
              title: "Hold Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(selectedCabin),
              children: [
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                spacing: 2,
                                children: [
                                  JsxRuntime.jsxs(FormControl, {
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: "Hold under fare class"
                                              }),
                                          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                children: Caml_option.some(match$4 !== undefined ? Belt_Array.map(match$4.nodes, (function (node) {
                                                              var pref = node.bookingPrefix;
                                                              return JsxRuntime.jsx($$Option, {
                                                                          value: Caml_option.some(node.fareClassSlug),
                                                                          children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(node.salesName) + (
                                                                                pref !== undefined ? " (" + CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(pref)) + ")" : ""
                                                                              ))
                                                                        });
                                                            })) : []),
                                                onChange: (function (param, val) {
                                                    setUpdate(function (param) {
                                                          return {
                                                                  holdFareClass: Belt_Option.getWithDefault(Belt_Option.flatMap(val, (function (v) {
                                                                              return v;
                                                                            })), ""),
                                                                  holdOccupancy: update.holdOccupancy
                                                                };
                                                        });
                                                  }),
                                                required: true,
                                                size: "sm",
                                                value: update.holdFareClass
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(FormControl, {
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: "Hold for occupancy"
                                              }),
                                          JsxRuntime.jsx(Joy$Util.JoySelect.make, {
                                                children: Caml_option.some(Belt_Array.map(salesRange, (function (occ) {
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: occ,
                                                                        children: Caml_option.some(occ)
                                                                      });
                                                          }))),
                                                onChange: (function (param, val) {
                                                    setUpdate(function (param) {
                                                          return {
                                                                  holdFareClass: update.holdFareClass,
                                                                  holdOccupancy: Belt_Option.getWithDefault(Belt_Option.flatMap(val, (function (v) {
                                                                              return v;
                                                                            })), 2)
                                                                };
                                                        });
                                                  }),
                                                required: true,
                                                size: "sm",
                                                value: update.holdOccupancy
                                              })
                                        ]
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                component: "form",
                                onSubmit: onSubmit,
                                children: Caml_option.some(JsxRuntime.jsx(Button, {
                                          loading: match$3.isMutating,
                                          type: "submit",
                                          children: Caml_option.some("Hold Cabin " + CS_NonemptyStrings$Util.CabinNumber.toString(selectedCabin))
                                        }))
                              }))
                    })
              ]
            });
}

var make = HoldCabinForm;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
