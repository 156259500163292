// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var CS_ISO3166_1$Util = require("util/src/CustomScalars/CS_ISO3166_1.bs.js");
var CS_LoyaltyNumbers$Util = require("util/src/CustomScalars/CS_LoyaltyNumbers.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var CS_SQLDatesAndTimes$Util = require("util/src/CustomScalars/CS_SQLDatesAndTimes.bs.js");
var BookingDetailsPageVersionQueryRefetch_graphql$Thick = require("./BookingDetailsPageVersionQueryRefetch_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"passenger_nodes_idx":{"b":""},"passenger_nodes_docLastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passenger_nodes_docFirstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passenger_nodes_docCitizenship":{"c":"Util.CustomScalars.ISO3166_1.Alpha3.Exn"},"passenger_nodes_docBirthdate":{"c":"Util.CustomScalars.SQLDate.Exn"},"passenger_nodes_bfcustUser_loyalty_status_numEligibleVoyages":{"b":""},"passenger_nodes_bfcustUser_loyalty_status_currentTierTitle":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passenger_nodes_bfcustUser_loyaltyNumber":{"c":"Util.CustomScalars.LoyaltyNumber.Exn"},"passenger_nodes_bfcustUser_lastName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passenger_nodes_bfcustUser_invitationExpiry":{"c":"Util.CustomScalars.SQLDateTime.Exn"},"passenger_nodes_bfcustUser_invitationEmail":{"c":"Util.CustomScalars.Email.Exn"},"passenger_nodes_bfcustUser_halMarinerNumber":{"c":"Util.CustomScalars.HalMarinerNumber.Exn"},"passenger_nodes_bfcustUser_firstName":{"c":"Util.CustomScalars.NonemptyString.Exn"},"passenger_nodes_bfcustUser_email":{"c":"Util.CustomScalars.Email.Exn"},"passenger_nodes_bfcustUser_custodianAccountId":{"c":"Util.CustomScalars.AccountId.Exn"},"passenger_nodes_bfcustUser_accountId":{"c":"Util.CustomScalars.AccountId.Exn"},"occRange_end_value":{"b":""},"numBerths":{"b":""}}};

var fragmentConverterMap = {
  "Util.CustomScalars.AccountId.Exn": CS_NonemptyStrings$Util.AccountId.Exn.parse,
  "Util.CustomScalars.Email.Exn": CS_Emails$Util.Email.Exn.parse,
  "Util.CustomScalars.NonemptyString.Exn": CS_NonemptyStrings$Util.NonEmptyString.Exn.parse,
  "Util.CustomScalars.HalMarinerNumber.Exn": CS_LoyaltyNumbers$Util.HalMarinerNumber.Exn.parse,
  "Util.CustomScalars.SQLDateTime.Exn": CS_Make$Util.Dateable.Plain.Exn.parse,
  "Util.CustomScalars.LoyaltyNumber.Exn": CS_NonemptyStrings$Util.LoyaltyNumber.Exn.parse,
  "Util.CustomScalars.SQLDate.Exn": CS_SQLDatesAndTimes$Util.DateOnly.Exn.parse,
  "Util.CustomScalars.ISO3166_1.Alpha3.Exn": CS_ISO3166_1$Util.Alpha3.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function docGender_decode($$enum) {
  if ($$enum === "F" || $$enum === "M" || $$enum === "X") {
    return $$enum;
  }
  
}

function docGender_fromString(str) {
  return docGender_decode(str);
}

function paxDataStatus_decode($$enum) {
  if ($$enum === "INCOMPLETE" || $$enum === "CONFIRMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

function paxDataStatus_fromString(str) {
  return paxDataStatus_decode(str);
}

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString,
  paxDataStatus_decode: paxDataStatus_decode,
  paxDataStatus_fromString: paxDataStatus_fromString
};

function makeNode(rescript_graphql_node_BookingDetailsPageVersionQueryRefetch) {
  return ({
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": rescript_graphql_node_BookingDetailsPageVersionQueryRefetch,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BookingDetailsPage_version",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "IDX_ASC"
        }
      ],
      "concreteType": "PassengersConnection",
      "kind": "LinkedField",
      "name": "passenger",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Passenger",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paxDataStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "idx",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docLastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docBirthdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docGender",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docCitizenship",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BfcustUser",
              "kind": "LinkedField",
              "name": "bfcustUser",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "loyaltyNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "halMarinerNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invitationEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invitationExpiry",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "custodianAccountId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BfcustLoyaltyStatus",
                  "kind": "LinkedField",
                  "name": "loyalty_status",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currentTierTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "numEligibleVoyages",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "passenger(orderBy:\"IDX_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "occRange",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IntRangeBound",
          "kind": "LinkedField",
          "name": "end",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inclusive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BookingVersion",
  "abstractKey": null
});
}

var node = makeNode(BookingDetailsPageVersionQueryRefetch_graphql$Thick.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
