// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RelayEnv$Thick = require("../../../RelayEnv.bs.js");
var BillPayInfo$Thick = require("../billing-payments/BillPayInfo.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var PreviewChangesQuery_graphql$Thick = require("../../../__generated__/PreviewChangesQuery_graphql.bs.js");

var convertVariables = PreviewChangesQuery_graphql$Thick.Internal.convertVariables;

var convertResponse = PreviewChangesQuery_graphql$Thick.Internal.convertResponse;

var convertWrapRawResponse = PreviewChangesQuery_graphql$Thick.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, PreviewChangesQuery_graphql$Thick.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, PreviewChangesQuery_graphql$Thick.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(PreviewChangesQuery_graphql$Thick.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(PreviewChangesQuery_graphql$Thick.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(PreviewChangesQuery_graphql$Thick.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(PreviewChangesQuery_graphql$Thick.node, convertVariables);

var Query_billingKind_decode = PreviewChangesQuery_graphql$Thick.Utils.billingKind_decode;

var Query_billingKind_fromString = PreviewChangesQuery_graphql$Thick.Utils.billingKind_fromString;

var Query_bookingDiffError_decode = PreviewChangesQuery_graphql$Thick.Utils.bookingDiffError_decode;

var Query_bookingDiffError_fromString = PreviewChangesQuery_graphql$Thick.Utils.bookingDiffError_fromString;

var Query_docGender_decode = PreviewChangesQuery_graphql$Thick.Utils.docGender_decode;

var Query_docGender_fromString = PreviewChangesQuery_graphql$Thick.Utils.docGender_fromString;

var Query_elevatorProximity_decode = PreviewChangesQuery_graphql$Thick.Utils.elevatorProximity_decode;

var Query_elevatorProximity_fromString = PreviewChangesQuery_graphql$Thick.Utils.elevatorProximity_fromString;

var Query_longitudinality_decode = PreviewChangesQuery_graphql$Thick.Utils.longitudinality_decode;

var Query_longitudinality_fromString = PreviewChangesQuery_graphql$Thick.Utils.longitudinality_fromString;

var Query_pmtLifecycleStatus_decode = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_decode;

var Query_pmtLifecycleStatus_fromString = PreviewChangesQuery_graphql$Thick.Utils.pmtLifecycleStatus_fromString;

var Query_pmtMethod_decode = PreviewChangesQuery_graphql$Thick.Utils.pmtMethod_decode;

var Query_pmtMethod_fromString = PreviewChangesQuery_graphql$Thick.Utils.pmtMethod_fromString;

var Query_pmtProcessor_decode = PreviewChangesQuery_graphql$Thick.Utils.pmtProcessor_decode;

var Query_pmtProcessor_fromString = PreviewChangesQuery_graphql$Thick.Utils.pmtProcessor_fromString;

var Query_refundability_decode = PreviewChangesQuery_graphql$Thick.Utils.refundability_decode;

var Query_refundability_fromString = PreviewChangesQuery_graphql$Thick.Utils.refundability_fromString;

var Query_subtotalCategory_decode = PreviewChangesQuery_graphql$Thick.Utils.subtotalCategory_decode;

var Query_subtotalCategory_fromString = PreviewChangesQuery_graphql$Thick.Utils.subtotalCategory_fromString;

var Query = {
  billingKind_decode: Query_billingKind_decode,
  billingKind_fromString: Query_billingKind_fromString,
  bookingDiffError_decode: Query_bookingDiffError_decode,
  bookingDiffError_fromString: Query_bookingDiffError_fromString,
  docGender_decode: Query_docGender_decode,
  docGender_fromString: Query_docGender_fromString,
  elevatorProximity_decode: Query_elevatorProximity_decode,
  elevatorProximity_fromString: Query_elevatorProximity_fromString,
  longitudinality_decode: Query_longitudinality_decode,
  longitudinality_fromString: Query_longitudinality_fromString,
  pmtLifecycleStatus_decode: Query_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: Query_pmtLifecycleStatus_fromString,
  pmtMethod_decode: Query_pmtMethod_decode,
  pmtMethod_fromString: Query_pmtMethod_fromString,
  pmtProcessor_decode: Query_pmtProcessor_decode,
  pmtProcessor_fromString: Query_pmtProcessor_fromString,
  refundability_decode: Query_refundability_decode,
  refundability_fromString: Query_refundability_fromString,
  subtotalCategory_decode: Query_subtotalCategory_decode,
  subtotalCategory_fromString: Query_subtotalCategory_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function useQuery(generatePreviewFromDiff, diffStruct, onClose) {
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPreviewModalOpen = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setBillPayNew = match$3[1];
  var match$4 = React.useState(function () {
        return diffStruct;
      });
  var setApplyDiff = match$4[1];
  var onSuccess = function (b) {
    setBillPayNew(function (param) {
          return b;
        });
    onClose();
    setPreviewModalOpen(function (param) {
          return true;
        });
  };
  var preview = function (applyDiff) {
    setLoading(function (param) {
          return true;
        });
    var match = generatePreviewFromDiff(applyDiff);
    var bookingId = match.bookingId;
    $$fetch(RelayEnv$Thick.environment, {
          bookingDiff: match.bookingDiff,
          bookingId: bookingId,
          bookingVersionNumber: match.bookingVersionNumber,
          voyage: match.voyageSlug
        }, (function (res) {
            if (res.TAG === "Ok") {
              var res$1 = res._0;
              var match = res$1.proposedChanges;
              if (match !== undefined) {
                var match$1 = match.nodes;
                if (match$1.length === 1) {
                  var match$2 = match$1[0];
                  var err = match$2.nullableErr;
                  if (err !== undefined) {
                    return setError(function (param) {
                                return err;
                              });
                  }
                  
                }
                
              }
              var match$3 = res$1.proposedChanges;
              var billPayNew;
              if (match$3 !== undefined) {
                var updates = Belt_Array.keepMap(match$3.nodes, (function (q) {
                        var match = q.bookingId;
                        var match$1 = q.quote;
                        var match$2 = q.augmentedPayments;
                        if (match !== undefined && match$1 !== undefined && match$2 !== undefined) {
                          return BillPayInfo$Thick.parse({
                                      TAG: "Proposed",
                                      _0: match$1,
                                      _1: Caml_option.valFromOption(match),
                                      _2: match$2
                                    });
                        }
                        
                      }));
                var current = Belt_Array.get(Belt_Array.keep(updates, (function (b) {
                            return Caml_obj.equal(b.bookingId, bookingId);
                          })), 0);
                if (current !== undefined) {
                  var other = Belt_Array.keep(updates, (function (b) {
                          return b.bookingId !== bookingId;
                        }));
                  billPayNew = {
                    current: current,
                    other: other
                  };
                } else {
                  billPayNew = undefined;
                }
              } else {
                billPayNew = undefined;
              }
              onSuccess(billPayNew);
              setLoading(function (param) {
                    return false;
                  });
              return ;
            }
            setError(function (param) {
                  return "An error occurred";
                });
            setLoading(function (param) {
                  return false;
                });
          }), undefined, undefined);
  };
  var onSubmit = function (e, applyDiff) {
    e.preventDefault();
    preview(applyDiff);
  };
  var clearPreviewFields = function () {
    setBillPayNew(function (param) {
          
        });
    setError(function (param) {
          
        });
    setPreviewModalOpen(function (param) {
          return false;
        });
    setApplyDiff(function (param) {
          return diffStruct;
        });
  };
  return {
          generatePreview: preview,
          onSubmit: onSubmit,
          billPayNew: match$3[0],
          loading: match$1[0],
          setLoading: setLoading,
          error: match[0],
          setError: setError,
          previewModalOpen: match$2[0],
          applyDiff: match$4[0],
          setApplyDiff: setApplyDiff,
          clearPreviewFields: clearPreviewFields
        };
}

exports.Query = Query;
exports.useQuery = useQuery;
/* use Not a pure module */
