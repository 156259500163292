// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Make$Util = require("util/src/CustomScalars/CS_Make.bs.js");
var Common$Thick = require("../../utils/Common.bs.js");
var Menu = require("@mui/joy/Menu").default;
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var CS_Emails$Util = require("util/src/CustomScalars/CS_Emails.bs.js");
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var CustomersState$Thick = require("../../utils/CustomersState.bs.js");
var ChangeEmailForm$Thick = require("./ChangeEmailForm.bs.js");
var CustTableToolbar$Thick = require("./CustTableToolbar.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AccountCRUDDrawer$Thick = require("./AccountCRUDDrawer.bs.js");
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var Send = require("@mui/icons-material/Send").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var ResendInvitationForm$Thick = require("./ResendInvitationForm.bs.js");
var RevokeInvitationForm$Thick = require("./RevokeInvitationForm.bs.js");
var Warning = require("@mui/icons-material/Warning").default;
var Settings = require("@mui/icons-material/Settings").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var CreateLinkedAccountForm$Thick = require("./CreateLinkedAccountForm.bs.js");
var ContactMail = require("@mui/icons-material/ContactMail").default;
var CustomerTable_row_graphql$Thick = require("../../__generated__/CustomerTable_row_graphql.bs.js");
var ArrowOutward = require("@mui/icons-material/ArrowOutward").default;
var CustomerTableQuery_graphql$Thick = require("../../__generated__/CustomerTableQuery_graphql.bs.js");
var CustomerTable_user_graphql$Thick = require("../../__generated__/CustomerTable_user_graphql.bs.js");
var CustomerTable_query_graphql$Thick = require("../../__generated__/CustomerTable_query_graphql.bs.js");
var TransferConnectedAccountForm$Thick = require("./TransferConnectedAccountForm.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

var convertFragment = CustomerTable_query_graphql$Thick.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_query_graphql$Thick.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_query_graphql$Thick.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(CustomerTable_query_graphql$Thick.node, convertFragment, fRef);
}

var makeRefetchVariables = CustomerTableQuery_graphql$Thick.Types.makeRefetchVariables;

var convertRefetchVariables = CustomerTableQuery_graphql$Thick.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(CustomerTable_query_graphql$Thick.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

var convertFragment$1 = CustomerTable_user_graphql$Thick.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_user_graphql$Thick.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_user_graphql$Thick.node, convertFragment$1);
}

function readResolverFragment$1(fRef) {
  return RescriptRelay_Fragment.read(CustomerTable_user_graphql$Thick.node, convertFragment$1, fRef);
}

var DeprecatedFragmentForUpdateAccountDataMutationFileOnly_accountStatus_decode = CustomerTable_user_graphql$Thick.Utils.accountStatus_decode;

var DeprecatedFragmentForUpdateAccountDataMutationFileOnly_accountStatus_fromString = CustomerTable_user_graphql$Thick.Utils.accountStatus_fromString;

var DeprecatedFragmentForUpdateAccountDataMutationFileOnly_docGender_decode = CustomerTable_user_graphql$Thick.Utils.docGender_decode;

var DeprecatedFragmentForUpdateAccountDataMutationFileOnly_docGender_fromString = CustomerTable_user_graphql$Thick.Utils.docGender_fromString;

var DeprecatedFragmentForUpdateAccountDataMutationFileOnly = {
  accountStatus_decode: DeprecatedFragmentForUpdateAccountDataMutationFileOnly_accountStatus_decode,
  accountStatus_fromString: DeprecatedFragmentForUpdateAccountDataMutationFileOnly_accountStatus_fromString,
  docGender_decode: DeprecatedFragmentForUpdateAccountDataMutationFileOnly_docGender_decode,
  docGender_fromString: DeprecatedFragmentForUpdateAccountDataMutationFileOnly_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readResolverFragment: readResolverFragment$1
};

var convertFragment$2 = CustomerTable_row_graphql$Thick.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(CustomerTable_row_graphql$Thick.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, CustomerTable_row_graphql$Thick.node, convertFragment$2);
}

function readResolverFragment$2(fRef) {
  return RescriptRelay_Fragment.read(CustomerTable_row_graphql$Thick.node, convertFragment$2, fRef);
}

var RowFragment_accountStatus_decode = CustomerTable_row_graphql$Thick.Utils.accountStatus_decode;

var RowFragment_accountStatus_fromString = CustomerTable_row_graphql$Thick.Utils.accountStatus_fromString;

var RowFragment = {
  accountStatus_decode: RowFragment_accountStatus_decode,
  accountStatus_fromString: RowFragment_accountStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2,
  readResolverFragment: readResolverFragment$2
};

function CustomerTable$TableRow(props) {
  var onSelectUserToUpdate = props.onSelectUserToUpdate;
  var brandFamily = props.brandFamily;
  var refresh = props.refresh;
  var user = use$2(props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setCreateDialogOpen = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setTransferDialogOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setChangeEmailDialogOpen = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setCopied = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setResendDialogOpen = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setRevokeDialogOpen = match$5[1];
  var match$6 = React.useState(function () {
        return "transfer";
      });
  var setTargetUserType = match$6[1];
  var onResendRequest = function (t) {
    setTargetUserType(function (param) {
          return t;
        });
    setResendDialogOpen(function (param) {
          return true;
        });
  };
  var email = user.invitationEmail;
  var invitee = email !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email)) : "another user";
  var match$7 = Belt_Array.get(user.passenger_record.nodes, 0);
  var inviteeBookingUrl = match$7 !== undefined ? "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/" + CS_Slugs$Util.VoyageSlug.toString(match$7.voyageSlug) + "/bookings/" + CS_NonemptyStrings$Util.BookingId.toString(match$7.bookingId) + "/" + String(match$7.bookingVersionNumber) : undefined;
  var isInLimbo = user.accountStatus === "TRANSFERRING" || user.accountStatus === "INVITED";
  var expiry = user.invitationExpiry;
  var match$8;
  if (expiry !== undefined) {
    var expiry$1 = Caml_option.valFromOption(expiry);
    match$8 = CS_Make$Util.Dateable.Plain.isInThePast(expiry$1) ? [
        true,
        (function (param) {
            return "Invitation has expired! Please resend or revoke.";
          })
      ] : [
        false,
        (function (i) {
            return "Invitation to " + i + " expires on " + CS_Make$Util.Dateable.Plain.format(expiry$1, "MM/dd/yyyy") + " at " + CS_Make$Util.Dateable.Plain.format(expiry$1, "hh:mm b") + ".";
          })
      ];
  } else {
    match$8 = [
      false,
      (function (param) {
          return "";
        })
    ];
  }
  var bookingLinks = Belt_Array.map(user.passenger_record.nodes, (function (node) {
          return "/" + CS_Slugs$Util.BrandFamilySlug.toString(brandFamily) + "/" + CS_Slugs$Util.VoyageSlug.toString(node.voyageSlug) + "/bookings/" + CS_NonemptyStrings$Util.BookingId.toString(node.bookingId) + "/" + String(node.bookingVersionNumber);
        }));
  var custodian = user.custodian;
  var custodianName = custodian !== undefined ? custodian.fullName : undefined;
  var match$9 = user.accountStatus;
  var tmp;
  if (match$9 === "UNCLAIMED" || match$9 === "TRANSFERRING" || match$9 === "INVITED" || match$9 === "PREFILLED" || match$9 === "CLAIMED" || match$9 === "DELETED") {
    switch (match$9) {
      case "INVITED" :
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsxs(MenuItem, {
                        children: [
                          JsxRuntime.jsx(ListItemDecorator, {
                                children: Caml_option.some(JsxRuntime.jsx(Send, {}))
                              }),
                          "Resend New User Invitation"
                        ],
                        onClick: (function (param) {
                            onResendRequest("newpax");
                          })
                      }),
                  inviteeBookingUrl !== undefined ? JsxRuntime.jsxs(MenuItem, {
                          children: [
                            JsxRuntime.jsx(ListItemDecorator, {
                                  children: Caml_option.some(JsxRuntime.jsx(Settings, {}))
                                }),
                            "Manage Booking"
                          ],
                          onClick: (function (param) {
                              RescriptReactRouter.push(inviteeBookingUrl);
                            })
                        }) : null
                ]
              });
          break;
      case "DELETED" :
          tmp = null;
          break;
      default:
        tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(MenuItem, {
                      children: [
                        JsxRuntime.jsx(ListItemDecorator, {
                              children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {}))
                            }),
                        "Edit User Details"
                      ],
                      onClick: (function (param) {
                          onSelectUserToUpdate(user);
                        })
                    }),
                user.accountStatus === "CLAIMED" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsxs(MenuItem, {
                                children: [
                                  JsxRuntime.jsx(ListItemDecorator, {
                                        children: Caml_option.some(JsxRuntime.jsx(ContactMail, {}))
                                      }),
                                  "Change Account Email"
                                ],
                                onClick: (function (param) {
                                    setChangeEmailDialogOpen(function (param) {
                                          return true;
                                        });
                                  })
                              }),
                          JsxRuntime.jsxs(MenuItem, {
                                children: [
                                  JsxRuntime.jsx(ListItemDecorator, {
                                        children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {}))
                                      }),
                                  "Create Connected Account"
                                ],
                                onClick: (function (param) {
                                    setCreateDialogOpen(function (param) {
                                          return true;
                                        });
                                  })
                              })
                        ]
                      }) : (
                    user.accountStatus === "UNCLAIMED" ? JsxRuntime.jsxs(MenuItem, {
                            children: [
                              JsxRuntime.jsx(ListItemDecorator, {
                                    children: Caml_option.some(JsxRuntime.jsx(ArrowOutward, {}))
                                  }),
                              "Transfer Connected Account"
                            ],
                            onClick: (function (param) {
                                setTransferDialogOpen(function (param) {
                                      return true;
                                    });
                              })
                          }) : (
                        user.accountStatus === "TRANSFERRING" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                children: [
                                  JsxRuntime.jsxs(MenuItem, {
                                        children: [
                                          JsxRuntime.jsx(ListItemDecorator, {
                                                children: Caml_option.some(JsxRuntime.jsx(Send, {}))
                                              }),
                                          "Resend Transfer Invitation"
                                        ],
                                        onClick: (function (param) {
                                            onResendRequest("transfer");
                                          })
                                      }),
                                  JsxRuntime.jsxs(MenuItem, {
                                        children: [
                                          JsxRuntime.jsx(ListItemDecorator, {
                                                children: Caml_option.some(JsxRuntime.jsx(PersonAddDisabledOutlined, {}))
                                              }),
                                          "Revoke Transfer Invitation"
                                        ],
                                        color: "danger",
                                        onClick: (function (param) {
                                            setRevokeDialogOpen(function (param) {
                                                  return true;
                                                });
                                          })
                                      })
                                ]
                              }) : null
                      )
                  )
              ]
            });
    }
  } else {
    tmp = null;
  }
  var match$10 = user.email;
  var match$11 = user.accountStatus;
  var match$12 = user.invitationEmail;
  var tmp$1;
  if (match$10 !== undefined) {
    var email$1 = Caml_option.valFromOption(match$10);
    var exit = 0;
    if (match$11 === "UNCLAIMED" || match$11 === "TRANSFERRING" || match$11 === "INVITED" || match$11 === "PREFILLED" || match$11 === "CLAIMED" || match$11 === "DELETED") {
      switch (match$11) {
        case "CLAIMED" :
        case "PREFILLED" :
            exit = 1;
            break;
        default:
          tmp$1 = null;
      }
    } else {
      tmp$1 = null;
    }
    if (exit === 1) {
      tmp$1 = JsxRuntime.jsx(Tooltip, {
            children: JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  noWrap: true,
                  children: Caml_option.some(CS_Emails$Util.Email.toString(email$1))
                }),
            arrow: true,
            size: "sm",
            sx: {
              cursor: "pointer"
            },
            title: Caml_option.some(match$3[0] ? "Copied to clipboard!" : CS_Emails$Util.Email.toString(email$1)),
            onClick: (function (param) {
                return Common$Thick.copyToClipboard(CS_Emails$Util.Email.toString(email$1), setCopied);
              })
          });
    }
    
  } else if (match$12 !== undefined && isInLimbo) {
    var invitee$1 = Caml_option.valFromOption(match$12);
    tmp$1 = JsxRuntime.jsx(Tooltip, {
          children: JsxRuntime.jsx(Typography, {
                startDecorator: Caml_option.some(match$8[0] ? JsxRuntime.jsx(Warning, {
                            color: "danger",
                            fontSize: "md"
                          }) : JsxRuntime.jsx(ScheduleSendOutlined, {
                            fontSize: "md"
                          })),
                level: "body-sm",
                noWrap: true,
                sx: {
                  fontStyle: "italic",
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                },
                children: Caml_option.some(CS_Emails$Util.Email.toString(invitee$1))
              }),
          arrow: true,
          size: "sm",
          sx: {
            cursor: "pointer",
            maxWidth: "15rem"
          },
          title: Caml_option.some(match$8[1](CS_Emails$Util.Email.toString(invitee$1)))
        });
  } else {
    tmp$1 = null;
  }
  var lsInfo = user.loyalty_status;
  var tmp$2;
  if (lsInfo !== undefined) {
    var tier = lsInfo.currentTierTitle;
    if (tier !== undefined) {
      var vsArr = lsInfo.eligibleVoyageSlugs;
      tmp$2 = JsxRuntime.jsx(Tooltip, {
            children: JsxRuntime.jsx(Typography, {
                  level: "body-sm",
                  children: Caml_option.some(CS_NonemptyStrings$Util.NonEmptyString.toString(Caml_option.valFromOption(tier)) + " (" + String(lsInfo.numEligibleVoyages) + ")")
                }),
            arrow: true,
            placement: "top",
            size: "sm",
            sx: {
              justifySelf: "flex-start",
              cursor: "pointer",
              maxWidth: "250px"
            },
            title: Caml_option.some(vsArr !== undefined ? Belt_Array.joinWith(vsArr, ", ", (function (slug) {
                          if (slug !== undefined) {
                            return CS_Slugs$Util.VoyageSlug.toString(Caml_option.valFromOption(slug));
                          } else {
                            return "";
                          }
                        })) : "")
          });
    } else {
      tmp$2 = null;
    }
  } else {
    tmp$2 = null;
  }
  var match$13 = user.accountStatus;
  var tmp$3;
  var exit$1 = 0;
  if (match$13 === "UNCLAIMED" || match$13 === "TRANSFERRING" || match$13 === "INVITED" || match$13 === "PREFILLED" || match$13 === "CLAIMED" || match$13 === "DELETED") {
    switch (match$13) {
      case "INVITED" :
      case "TRANSFERRING" :
          exit$1 = 2;
          break;
      case "CLAIMED" :
      case "UNCLAIMED" :
          exit$1 = 1;
          break;
      default:
        tmp$3 = null;
    }
  } else {
    tmp$3 = null;
  }
  switch (exit$1) {
    case 1 :
        var tmp$4;
        if (user.accountStatus === "CLAIMED") {
          var email$2 = user.email;
          tmp$4 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(CreateLinkedAccountForm$Thick.make, {
                        open_: match[0],
                        onClose: (function () {
                            setCreateDialogOpen(function (param) {
                                  return false;
                                });
                          }),
                        brandFamily: brandFamily,
                        refresh: refresh,
                        custodian: user.fullName,
                        custodianId: user.accountId
                      }),
                  JsxRuntime.jsx(ChangeEmailForm$Thick.make, {
                        open_: match$2[0],
                        onClose: (function () {
                            setChangeEmailDialogOpen(function (param) {
                                  return false;
                                });
                          }),
                        _accountId: user.accountId,
                        fullname: user.fullName,
                        oldEmail: email$2 !== undefined ? CS_Emails$Util.Email.toString(Caml_option.valFromOption(email$2)) : "",
                        refresh: refresh
                      })
                ]
              });
        } else {
          tmp$4 = user.accountStatus === "UNCLAIMED" ? JsxRuntime.jsx(TransferConnectedAccountForm$Thick.make, {
                  fullName: user.fullName,
                  open_: match$1[0],
                  onClose: (function () {
                      setTransferDialogOpen(function (param) {
                            return false;
                          });
                    }),
                  refresh: refresh,
                  accountId: user.accountId,
                  brandFamily: brandFamily
                }) : null;
        }
        tmp$3 = JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp$4)
            });
        break;
    case 2 :
        tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ResendInvitationForm$Thick.make, {
                      fullName: user.fullName,
                      open_: match$4[0],
                      onClose: (function () {
                          setResendDialogOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      accountId: user.accountId,
                      brandFamily: brandFamily,
                      invitee: invitee,
                      targetUserType: match$6[0]
                    }),
                JsxRuntime.jsx(RevokeInvitationForm$Thick.make, {
                      fullName: user.fullName,
                      open_: match$5[0],
                      onClose: (function () {
                          setRevokeDialogOpen(function (param) {
                                return false;
                              });
                        }),
                      refresh: refresh,
                      accountId: user.accountId,
                      brandFamily: brandFamily,
                      invitee: invitee
                    })
              ]
            });
        break;
    
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("tr", {
                      children: [
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Stack, {
                                    direction: "row",
                                    justifyContent: "flex-end",
                                    children: Caml_option.some(JsxRuntime.jsxs(Dropdown, {
                                              children: [
                                                JsxRuntime.jsx(MenuButton, {
                                                      children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                                                      size: "md",
                                                      slotProps: {
                                                        root: {
                                                          color: "neutral"
                                                        }
                                                      },
                                                      slots: {
                                                        root: IconButton
                                                      }
                                                    }),
                                                JsxRuntime.jsx(Menu, {
                                                      children: Caml_option.some(tmp),
                                                      placement: "bottom-start",
                                                      size: "sm"
                                                    })
                                              ]
                                            }))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    children: Caml_option.some(tmp$1)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(user.fullName)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(Common$Thick.AccountStatus.toString(user.accountStatus))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(CS_NonemptyStrings$Util.LoyaltyNumber.toString(user.loyaltyNumber))
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(tmp$2)
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "body-sm",
                                    noWrap: true,
                                    sx: {
                                      fontStyle: isInLimbo ? "italic" : "normal"
                                    },
                                    children: Caml_option.some(custodianName !== undefined ? custodianName : "")
                                  })
                            }),
                        JsxRuntime.jsx("td", {
                              children: Belt_Array.mapWithIndex(user.passenger_record.nodes, (function (i, node) {
                                      var bl = Belt_Array.get(bookingLinks, i);
                                      return JsxRuntime.jsx(Joy$Util.Link.make, {
                                                  children: JsxRuntime.jsx(Typography, {
                                                        color: "primary",
                                                        noWrap: true,
                                                        children: Caml_option.some(CS_NonemptyStrings$Util.BookingId.toString(node.bookingId))
                                                      }),
                                                  href: bl !== undefined ? bl : ""
                                                });
                                    }))
                            })
                      ]
                    }),
                tmp$3
              ]
            });
}

var TableRow = {
  make: CustomerTable$TableRow
};

function CustomerTable(props) {
  var state = props.state;
  var primaryFilter = props.filter;
  var brandFamily = props.brandFamily;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return "all";
      });
  var setAccountSelect = match$1[1];
  var accountSelect = match$1[0];
  var form = CustomersState$Thick.custView(state);
  var updateModalOpen = HopperState$Util.Observable.useComputed(state, (function (s) {
          return Belt_Option.isSome(s.custForm);
        }), undefined, undefined);
  var onCloseUpdateModal = function (param) {
    HopperState$Util.Observable.notify(state, (function (m) {
            return {
                    custForm: undefined,
                    url: m.url
                  };
          }));
  };
  var onSelectUserToUpdate = function (u) {
    HopperState$Util.Observable.notify(form, (function (form) {
            return Belt_Option.getWithDefault(form, CustomersState$Thick.blankCust({
                            TAG: "Edit",
                            _0: u.accountId
                          }));
          }));
  };
  var refetchAll = function (param) {
    return refetch(makeRefetchVariables(undefined, undefined, undefined), "store-and-network", undefined);
  };
  var match$2 = React.useState(function () {
        return primaryFilter;
      });
  var setFilter = match$2[1];
  var filter = match$2[0];
  var match$3 = React.useState(function () {
        return 0;
      });
  var setOffset = match$3[1];
  var offset = match$3[0];
  var match$4 = React.useState(function () {
        return false;
      });
  var setFilterChange = match$4[1];
  var filterChange = match$4[0];
  var refetchPage = function (newOffset) {
    refetch({
          brandFamily: Caml_option.some(brandFamily),
          filter: Caml_option.some(filter),
          offset: Caml_option.some(newOffset)
        }, "store-and-network", undefined);
  };
  var match$5 = data.bfcustUsers;
  var totalCount = match$5 !== undefined ? match$5.totalCount : 0;
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + 50 | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return String(start) + " - " + String(end$1) + " / " + String(t);
    } else {
      return "0";
    }
  };
  var onLoadNext = function (param) {
    var newOffset = offset + 50 | 0;
    setOffset(function (param) {
          return newOffset;
        });
    refetchPage(newOffset);
  };
  var onLoadPrev = function (param) {
    var newOffset = offset + 50 | 0;
    setOffset(function (param) {
          return newOffset;
        });
    refetchPage(newOffset);
  };
  var search = function (t) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [
      {
        fullName: {
          includesInsensitive: t
        }
      },
      {
        emailString: {
          includesInsensitive: t
        }
      }
    ];
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  var clearSearch = function (param) {
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.or = [];
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  var handleAccountSelect = function (s) {
    var arr;
    switch (s) {
      case "claimed" :
          arr = ["CLAIMED"];
          break;
      case "invited" :
          arr = ["INVITED"];
          break;
      case "transferring" :
          arr = ["TRANSFERRING"];
          break;
      case "unclaimed" :
          arr = ["UNCLAIMED"];
          break;
      default:
        arr = [
          "UNCLAIMED",
          "CLAIMED",
          "INVITED",
          "TRANSFERRING"
        ];
    }
    var newrecord = Caml_obj.obj_dup(filter);
    newrecord.accountStatus = {
      in: arr
    };
    setFilter(function (param) {
          return newrecord;
        });
    setFilterChange(function (param) {
          return !filterChange;
        });
  };
  React.useEffect((function () {
          refetch(makeRefetchVariables(undefined, Caml_option.some(filter), undefined), "store-and-network", undefined);
        }), [filterChange]);
  var match$6 = data.bfcustUsers;
  var tmp;
  if (match$6 !== undefined) {
    var edges = match$6.edges;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  searchBar: true,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  state: state
                }),
            edges.length !== 0 ? JsxRuntime.jsxs(Table, {
                    stripe: "odd",
                    hoverRow: true,
                    sx: {
                      captionSide: "top",
                      "& tbody": {
                        bgcolor: "background.surface"
                      }
                    },
                    children: [
                      JsxRuntime.jsx("thead", {
                            children: JsxRuntime.jsxs("tr", {
                                  children: [
                                    JsxRuntime.jsx("th", {
                                          style: {
                                            width: "2rem"
                                          }
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Email"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Name"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Account Status"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Loyalty Number"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Loyalty Tier"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Managed by"
                                        }),
                                    JsxRuntime.jsx("th", {
                                          children: "Active Bookings"
                                        })
                                  ]
                                })
                          }),
                      JsxRuntime.jsx("tbody", {
                            children: Belt_Array.map(edges, (function (edge) {
                                    return JsxRuntime.jsx(CustomerTable$TableRow, {
                                                fragmentRefs: edge.node.fragmentRefs,
                                                refresh: clearSearch,
                                                brandFamily: brandFamily,
                                                countryFrag: data.fragmentRefs,
                                                refetchAll: refetchAll,
                                                onSelectUserToUpdate: onSelectUserToUpdate
                                              }, edge.__id);
                                  }))
                          })
                    ]
                  }) : JsxRuntime.jsx(Stack, {
                    justifyContent: "center",
                    alignItems: "center",
                    sx: {
                      height: "15rem",
                      width: "100%"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              sx: {
                                color: "neutral.500"
                              },
                              children: "No customers found"
                            }))
                  }),
            JsxRuntime.jsx(CustTableToolbar$Thick.make, {
                  totalCount: totalCount,
                  offset: offset,
                  onLoadNext: onLoadNext,
                  onLoadPrev: onLoadPrev,
                  stringFromOffset: stringFromOffset,
                  search: search,
                  clearSearch: clearSearch,
                  handleAccountSelect: handleAccountSelect,
                  accountSelect: accountSelect,
                  setAccountSelect: setAccountSelect,
                  state: state
                })
          ]
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 1,
                      sx: {
                        width: "100%"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Sheet, {
                                variant: "outlined",
                                sx: {
                                  pt: 1,
                                  borderRadius: "sm",
                                  width: "100%"
                                },
                                children: Caml_option.some(tmp)
                              }))
                    }),
                JsxRuntime.jsx(AccountCRUDDrawer$Thick.make, {
                      open_: updateModalOpen,
                      onClose: onCloseUpdateModal,
                      refetch: refetchAll,
                      brandFamily: brandFamily,
                      countryFrag: data.fragmentRefs,
                      form: form
                    })
              ]
            });
}

var make = CustomerTable;

exports.QueryFragment = QueryFragment;
exports.DeprecatedFragmentForUpdateAccountDataMutationFileOnly = DeprecatedFragmentForUpdateAccountDataMutationFileOnly;
exports.RowFragment = RowFragment;
exports.TableRow = TableRow;
exports.make = make;
/* react Not a pure module */
