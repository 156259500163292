// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CS_Slugs$Util = require("util/src/CustomScalars/CS_Slugs.bs.js");
var Spinner$Thick = require("../common/atoms/Spinner.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var RemoteData$Util = require("util/src/RemoteData.bs.js");
var Divider = require("@mui/joy/Divider").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var SharedDrawer$Thick = require("../common/molecules/SharedDrawer.bs.js");
var Typography = require("@mui/joy/Typography").default;
var CustomerForms$Thick = require("../common/molecules/CustomerForms.bs.js");
var VoyageDetails$Thick = require("../common/queries/VoyageDetails.bs.js");
var CustomersState$Thick = require("../../utils/CustomersState.bs.js");
var Locutus$slashphp$slashstrings$slash = require("locutus/php/strings/");
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var WarningAmber = require("@mui/icons-material/WarningAmber").default;

function AccountCRUDDrawer$WithPrefill(props) {
  var form = props.form;
  var fetchKey = HopperState$Util.Observable.useComputed(form, (function (param) {
          var custFormTarget = param.custFormTarget;
          if (typeof custFormTarget !== "object") {
            return "not edit context, no fetch key";
          } else {
            return Locutus$slashphp$slashstrings$slash.md5(CS_NonemptyStrings$Util.AccountId.toString(custFormTarget._0) + String(Belt_Option.getWithDefault(param.fetchKey, 0)));
          }
        }), undefined, undefined);
  var match = CustomersState$Thick.FetchCustomer.use({
        accountId: props.accountId,
        brandFamilySlug: props.brandFamilySlug
      }, "store-and-network", fetchKey, undefined);
  var cfOpt = CustomersState$Thick.CustomerFragment.useOpt(Belt_Option.map(match.bfcustUser, (function (param) {
              return param.fragmentRefs;
            })));
  var vdOpt = VoyageDetails$Thick.PassengersFragment.useOpt(Belt_Option.map(cfOpt, (function (param) {
              return param.passenger_record.fragmentRefs;
            })));
  var doPrefill = function () {
    if (cfOpt !== undefined && vdOpt !== undefined) {
      return CustomersState$Thick.prefill(form, cfOpt, vdOpt);
    }
    
  };
  React.useEffect((function () {
          doPrefill();
        }), [Belt_Option.getWithDefault(JSON.stringify(cfOpt), "")]);
  return null;
}

var WithPrefill = {
  make: AccountCRUDDrawer$WithPrefill
};

function AccountCRUDDrawer$CRUDDialogActions$Impl(props) {
  var isDirty = props.isDirty;
  var loading = props.loading;
  var formHasErrors = props.formHasErrors;
  return JsxRuntime.jsx(DialogActions, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: {
                          xs: "column-reverse",
                          sm: "row"
                        },
                        justifyContent: "space-between",
                        spacing: 1,
                        sx: {
                          width: "100%",
                          p: 2
                        },
                        children: [
                          JsxRuntime.jsx(Tooltip, {
                                children: JsxRuntime.jsx(Button, {
                                      onClick: Caml_option.some(props.cancel),
                                      startDecorator: Caml_option.some(isDirty ? JsxRuntime.jsx(WarningAmber, {
                                                  fontSize: "md"
                                                }) : null),
                                      variant: "outlined",
                                      color: isDirty ? "warning" : "neutral",
                                      children: "Cancel"
                                    }),
                                arrow: true,
                                placement: "top-start",
                                size: "sm",
                                title: Caml_option.some(props.cancelTooltip)
                              }),
                          JsxRuntime.jsx(Button, {
                                loading: loading,
                                onClick: Caml_option.some(props.onClick),
                                startDecorator: Caml_option.some(formHasErrors ? JsxRuntime.jsx(WarningAmber, {
                                            fontSize: "md"
                                          }) : null),
                                variant: formHasErrors ? "outlined" : "solid",
                                color: formHasErrors ? "danger" : "primary",
                                disabled: !isDirty || formHasErrors || loading,
                                children: "Save"
                              })
                        ]
                      }))
            });
}

var Impl = {
  make: AccountCRUDDrawer$CRUDDialogActions$Impl
};

function AccountCRUDDrawer$CRUDDialogActions$Update(props) {
  var form = props.form;
  var validatedForm = HopperState$Util.Observable.useComputed(form, (function (f) {
          return CustomersState$Thick.validateForAccountDataUpdate(f, undefined);
        }), undefined, undefined);
  var isDirty = CustomersState$Thick.useIsDirty(form);
  var formHasErrors = Belt_Result.isError(validatedForm);
  var updateAccountData = RemoteData$Util.Relay.useMutationWithNotify(CustomersState$Thick.UpdateAccountMutation.use, (function (res) {
          var match = res.updateBfcustAccountData;
          if (match === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
          var match$1 = match.boolean;
          if (match$1 !== undefined) {
            if (match$1) {
              return {
                      TAG: "Success",
                      _0: undefined
                    };
            } else {
              return {
                      TAG: "Failure",
                      _0: "We submitted your account update, but it failed."
                    };
            }
          } else {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
        }), (function (mutation) {
          HopperState$Util.Observable.notify(form, (function (form) {
                  var newrecord = Caml_obj.obj_dup(form);
                  newrecord.mutation = mutation;
                  return newrecord;
                }));
        }));
  var onClick = function (e) {
    e.preventDefault();
    if (validatedForm.TAG === "Ok") {
      updateAccountData({
            input: validatedForm._0
          }, undefined, undefined, undefined, undefined);
      return ;
    }
    var error = validatedForm._0;
    HopperState$Util.Observable.notify(form, (function (f) {
            var newrecord = Caml_obj.obj_dup(f);
            newrecord.mutation = {
              TAG: "Failure",
              _0: error
            };
            return newrecord;
          }));
  };
  var cancelTooltip = isDirty ? "You have unsaved changes!" : null;
  return JsxRuntime.jsx(AccountCRUDDrawer$CRUDDialogActions$Impl, {
              formHasErrors: formHasErrors,
              onClick: onClick,
              loading: props.loading,
              cancel: props.cancel,
              isDirty: isDirty,
              cancelTooltip: cancelTooltip
            });
}

var Update = {
  make: AccountCRUDDrawer$CRUDDialogActions$Update
};

function AccountCRUDDrawer$CRUDDialogActions$PersistPrefill(props) {
  var brandFamily = props.brandFamily;
  var form = props.form;
  var validatedForm = HopperState$Util.Observable.useComputed(form, (function (f) {
          return CustomersState$Thick.validateForPrefillAccountCreation(f, brandFamily, undefined);
        }), undefined, undefined);
  var isDirty = CustomersState$Thick.useIsDirty(form);
  var formHasErrors = Belt_Result.isError(validatedForm);
  var createPrefillAccount = RemoteData$Util.Relay.useMutationWithNotify(CustomersState$Thick.PersistPrefillMutation.use, (function (res) {
          var match = res.createPrefilledBfcustUser;
          if (match === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
          var match$1 = match.result;
          if (match$1 === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
          var accountId = match$1.createdAccount;
          if (accountId !== undefined && match$1.nullableError === undefined) {
            return {
                    TAG: "Success",
                    _0: Caml_option.valFromOption(accountId)
                  };
          }
          var match$2 = match$1.nullableError;
          if (match$2 === undefined) {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
          if (!(match$2 === "USER_CANT_BE_CREATED_ASK_SUPPORT" || match$2 === "EMAIL_ALREADY_IN_USE" || match$2 === "BRANDFAM_ACTION_NOT_PERMITTED")) {
            return {
                    TAG: "Failure",
                    _0: "Could not update account data at this time."
                  };
          }
          switch (match$2) {
            case "EMAIL_ALREADY_IN_USE" :
                return {
                        TAG: "Failure",
                        _0: "This email is already in use on a customer account for " + CS_Slugs$Util.BrandFamilySlug.toPrimitive(brandFamily) + "."
                      };
            case "USER_CANT_BE_CREATED_ASK_SUPPORT" :
                return {
                        TAG: "Failure",
                        _0: "This user cannot be created. Please contact support."
                      };
            case "BRANDFAM_ACTION_NOT_PERMITTED" :
                return {
                        TAG: "Failure",
                        _0: "You don't appear to have permission to create this user."
                      };
            
          }
        }), (function (mutation) {
          HopperState$Util.Observable.notify(form, (function (form) {
                  var newrecord = Caml_obj.obj_dup(form);
                  newrecord.mutation = RemoteData$Util.mapSuccess(mutation, (function (param) {
                          
                        }));
                  newrecord.custFormTarget = Belt_Option.mapWithDefault(RemoteData$Util.toOption(mutation), form.custFormTarget, (function (acct) {
                          return {
                                  TAG: "Edit",
                                  _0: acct
                                };
                        }));
                  return newrecord;
                }));
        }));
  var onClick = function (e) {
    e.preventDefault();
    if (validatedForm.TAG === "Ok") {
      createPrefillAccount({
            input: validatedForm._0
          }, undefined, undefined, undefined, undefined);
      return ;
    }
    var error = validatedForm._0;
    HopperState$Util.Observable.notify(form, (function (f) {
            var newrecord = Caml_obj.obj_dup(f);
            newrecord.mutation = {
              TAG: "Failure",
              _0: error
            };
            return newrecord;
          }));
  };
  var cancelTooltip = isDirty ? "You have unsaved changes!" : null;
  return JsxRuntime.jsx(AccountCRUDDrawer$CRUDDialogActions$Impl, {
              formHasErrors: formHasErrors,
              onClick: onClick,
              loading: props.loading,
              cancel: props.cancel,
              isDirty: isDirty,
              cancelTooltip: cancelTooltip
            });
}

var PersistPrefill = {
  make: AccountCRUDDrawer$CRUDDialogActions$PersistPrefill
};

var CRUDDialogActions = {
  Impl: Impl,
  Update: Update,
  PersistPrefill: PersistPrefill
};

function AccountCRUDDrawer$WithSomeForm(props) {
  var onClose = props.onClose;
  var brandFamily = props.brandFamily;
  var refetch = props.refetch;
  var fragmentRefs = props.fragmentRefs;
  var form = props.form;
  var custFormTarget = HopperState$Util.Observable.useComputed(form, (function (param) {
          return param.custFormTarget;
        }), undefined, undefined);
  var match;
  match = typeof custFormTarget !== "object" ? [
      undefined,
      true
    ] : [
      Caml_option.some(custFormTarget._0),
      false
    ];
  var accountId = match[0];
  var closeOnSuccess = HopperState$Util.Observable.useComputed(form, (function (param) {
          return param.formSettings.closeOnSuccess;
        }), undefined, undefined);
  var match$1 = HopperState$Util.Observable.useComputed(form, (function (param) {
          var mutation = param.mutation;
          if (typeof mutation !== "object") {
            if (mutation === "NotAsked") {
              return [
                      undefined,
                      false,
                      false
                    ];
            } else {
              return [
                      undefined,
                      true,
                      false
                    ];
            }
          } else if (mutation.TAG === "Success") {
            return [
                    undefined,
                    false,
                    true
                  ];
          } else {
            return [
                    mutation._0,
                    false,
                    false
                  ];
          }
        }), undefined, undefined);
  var didMutate = match$1[2];
  var isMutating = match$1[1];
  var error = match$1[0];
  var showPrefillLinkManagement = HopperState$Util.Observable.useComputed(form, (function (param) {
          return param.formSettings.showPrefillLinkManagement;
        }), undefined, undefined);
  var clearAll = function () {
    HopperState$Util.Observable.notify(form, (function (f) {
            var newrecord = Caml_obj.obj_dup(f);
            newrecord.showVoyageOptions = false;
            newrecord.mutation = "NotAsked";
            return newrecord;
          }));
  };
  var cancel = function () {
    clearAll();
    onClose();
  };
  React.useEffect((function () {
          if (didMutate) {
            refetch();
            if (closeOnSuccess) {
              onClose();
            }
            
          }
          
        }), [didMutate]);
  var tmp;
  tmp = typeof custFormTarget !== "object" ? JsxRuntime.jsx(AccountCRUDDrawer$CRUDDialogActions$PersistPrefill, {
          form: form,
          brandFamily: brandFamily,
          loading: isMutating,
          cancel: cancel
        }) : JsxRuntime.jsx(AccountCRUDDrawer$CRUDDialogActions$Update, {
          form: form,
          loading: isMutating,
          cancel: cancel
        });
  return JsxRuntime.jsxs(React.Suspense, {
              children: [
                Belt_Option.isSome(accountId) ? JsxRuntime.jsx(AccountCRUDDrawer$WithPrefill, {
                        form: form,
                        brandFamilySlug: brandFamily,
                        accountId: Belt_Option.getExn(accountId)
                      }) : null,
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  px: 2,
                                  py: 3
                                },
                                children: [
                                  match[1] ? JsxRuntime.jsx(CustomerForms$Thick.Form.AccountEmail.make, {
                                          custForm: form,
                                          variant: "Gray"
                                        }) : null,
                                  JsxRuntime.jsx(CustomerForms$Thick.Form.Personal.make, {
                                        fragmentRefs: fragmentRefs,
                                        title: "User Profile",
                                        custForm: form,
                                        variant: "Gray",
                                        noHelperText: true
                                      }),
                                  JsxRuntime.jsx(CustomerForms$Thick.Form.Travel.make, {
                                        fragmentRefs: fragmentRefs,
                                        custForm: form,
                                        variant: "Gray"
                                      }),
                                  JsxRuntime.jsx(CustomerForms$Thick.Form.Voyages.make, {
                                        custForm: form,
                                        display: true
                                      }),
                                  error !== undefined ? JsxRuntime.jsx(Typography, {
                                          color: "danger",
                                          children: Caml_option.some(error)
                                        }) : null,
                                  showPrefillLinkManagement ? JsxRuntime.jsx(CustomerForms$Thick.Form.PrefillLinkManagement.make, {
                                          custForm: form,
                                          brandFamily: brandFamily,
                                          variant: "Gray"
                                        }) : null
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Divider, {}),
                tmp
              ],
              fallback: Caml_option.some(JsxRuntime.jsx(Spinner$Thick.make, {}))
            });
}

var WithSomeForm = {
  make: AccountCRUDDrawer$WithSomeForm
};

function AccountCRUDDrawer(props) {
  var form = props.form;
  var onClose = props.onClose;
  var hasForm = HopperState$Util.Observable.useComputed(form, Belt_Option.isSome, undefined, undefined);
  var title = HopperState$Util.Observable.useComputed(form, (function (o) {
          return Belt_Option.mapWithDefault(o, "", (function (param) {
                        var tmp = param.custFormTarget;
                        if (typeof tmp !== "object") {
                          return "Create Prefilled Passenger";
                        } else {
                          return "Update passenger details";
                        }
                      }));
        }), undefined, undefined);
  return JsxRuntime.jsx(SharedDrawer$Thick.make, {
              open_: props.open_,
              title: title,
              onClose: onClose,
              children: hasForm ? JsxRuntime.jsx(AccountCRUDDrawer$WithSomeForm, {
                      form: CustomersState$Thick.custExn(form),
                      fragmentRefs: props.countryFrag,
                      refetch: props.refetch,
                      brandFamily: props.brandFamily,
                      onClose: onClose
                    }) : null
            });
}

var make = AccountCRUDDrawer;

exports.WithPrefill = WithPrefill;
exports.CRUDDialogActions = CRUDDialogActions;
exports.WithSomeForm = WithSomeForm;
exports.make = make;
/* react Not a pure module */
