// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Joy$Util = require("util/src/Joy.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var $$Option = require("@mui/joy/Option").default;
var SearchBar$Thick = require("../common/molecules/SearchBar.bs.js");
var HopperState$Util = require("util/src/HopperState.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var BookingSession$Thick = require("../../utils/BookingSession.bs.js");
var Add = require("@mui/icons-material/Add").default;
var Search = require("@mui/icons-material/Search").default;
var ChevronLeft = require("@mui/icons-material/ChevronLeft").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function BSTableToolbar$Search(props) {
  var handleSessionCreatorSelect = props.handleSessionCreatorSelect;
  var clearSearch = props.clearSearch;
  var search = props.search;
  var match = React.useState(function () {
        return "";
      });
  var setSearchVal = match[1];
  var searchVal = match[0];
  var onSubmit = function (e) {
    e.preventDefault();
    search(searchVal);
  };
  return JsxRuntime.jsxs(Stack, {
              direction: "row",
              spacing: 1,
              children: [
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      component: "form",
                      spacing: 1,
                      onSubmit: onSubmit,
                      children: [
                        JsxRuntime.jsx(SearchBar$Thick.make, {
                              value: searchVal,
                              onChange: (function (e) {
                                  var val = e.currentTarget.value;
                                  setSearchVal(function (param) {
                                        return val;
                                      });
                                }),
                              placeholder: "Search",
                              onClear: (function (param) {
                                  setSearchVal(function (param) {
                                        return "";
                                      });
                                  clearSearch();
                                }),
                              showClear: searchVal !== "",
                              showDecorator: false
                            }),
                        JsxRuntime.jsx(IconButton, {
                              type: "submit",
                              variant: "solid",
                              color: "primary",
                              disabled: searchVal === "",
                              children: Caml_option.some(JsxRuntime.jsx(Search, {}))
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Joy$Util.JoySelect.make, {
                      children: [
                        JsxRuntime.jsx($$Option, {
                              value: "all",
                              children: "All sessions"
                            }),
                        JsxRuntime.jsx($$Option, {
                              value: "team",
                              children: "Created by team"
                            }),
                        JsxRuntime.jsx($$Option, {
                              value: "customer",
                              children: "Created by customer"
                            })
                      ],
                      onChange: (function (param, val) {
                          if (val === undefined) {
                            return ;
                          }
                          var val$1 = Caml_option.valFromOption(val);
                          if (val$1 !== undefined) {
                            return handleSessionCreatorSelect(val$1);
                          }
                          
                        }),
                      size: "sm",
                      value: props.sessionCreator
                    })
              ]
            });
}

var Search$1 = {
  make: BSTableToolbar$Search
};

function BSTableToolbar$NewButton(props) {
  var form = props.form;
  return JsxRuntime.jsx(Button, {
              onClick: (function (param) {
                  HopperState$Util.Observable.notify(form, (function (param) {
                          return BookingSession$Thick.blankForm("New");
                        }));
                }),
              startDecorator: Caml_option.some(JsxRuntime.jsx(Add, {})),
              variant: "outlined",
              color: "primary",
              size: "sm",
              children: "New Booking Session"
            });
}

var NewButton = {
  make: BSTableToolbar$NewButton
};

function BSTableToolbar(props) {
  var refetch = props.refetch;
  var totalCount = props.totalCount;
  var __showSearch = props.showSearch;
  var filter = props.filter;
  var showSearch = __showSearch !== undefined ? __showSearch : true;
  var match = React.useState(function () {
        return filter;
      });
  var setFilter = match[1];
  var filter$1 = match[0];
  var match$1 = React.useState(function () {
        return "all";
      });
  var setSessionCreator = match$1[1];
  var match$2 = React.useState(function () {
        return 0;
      });
  var setOffset = match$2[1];
  var offset = match$2[0];
  var refetchPage = function (newOffset) {
    refetch(BookingSession$Thick.Query.List.makeRefetchVariables(undefined, undefined, undefined, Caml_option.some(newOffset)), "store-and-network", undefined);
  };
  var stringFromOffset = function (o, t) {
    var start = o + 1 | 0;
    var end = o + 50 | 0;
    var end$1 = end > t ? t : end;
    if (t !== 0) {
      return String(start) + " - " + String(end$1) + " / " + String(t);
    } else {
      return "0";
    }
  };
  var clearSearch = function (param) {
    var newrecord = Caml_obj.obj_dup(filter$1);
    newrecord.or = [];
    setFilter(function (param) {
          return newrecord;
        });
  };
  var search = function (t) {
    var newrecord = Caml_obj.obj_dup(filter$1);
    newrecord.or = [
      {
        billing_contact: {
          or: [
            {
              fullName: {
                includesInsensitive: t
              }
            },
            {
              emailString: {
                includesInsensitive: t
              }
            },
            {
              loyaltyNumString: {
                includesInsensitive: t
              }
            }
          ]
        }
      },
      {
        descriptiveNoteString: {
          includesInsensitive: t
        }
      },
      {
        cabinNumberString: {
          includesInsensitive: t
        }
      },
      {
        passenger: {
          some: {
            or: [
              {
                fullNameString: {
                  includesInsensitive: t
                }
              },
              {
                invitationEmailString: {
                  includesInsensitive: t
                }
              },
              {
                existing_user: {
                  or: [
                    {
                      fullName: {
                        includesInsensitive: t
                      }
                    },
                    {
                      emailString: {
                        includesInsensitive: t
                      }
                    },
                    {
                      loyaltyNumString: {
                        includesInsensitive: t
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      }
    ];
    setFilter(function (param) {
          return newrecord;
        });
  };
  var handleSessionCreatorSelect = function (s) {
    var teamUserCreatorEmail;
    switch (s) {
      case "customer" :
          teamUserCreatorEmail = {
            isNull: true
          };
          break;
      case "team" :
          teamUserCreatorEmail = {
            isNull: false
          };
          break;
      default:
        teamUserCreatorEmail = undefined;
    }
    var newrecord = Caml_obj.obj_dup(filter$1);
    newrecord.teamUserCreatorEmail = teamUserCreatorEmail;
    setFilter(function (param) {
          return newrecord;
        });
    setSessionCreator(function (param) {
          return s;
        });
  };
  React.useEffect((function () {
          refetch(BookingSession$Thick.Query.List.makeRefetchVariables(undefined, undefined, Caml_option.some(filter$1), undefined), "store-and-network", undefined);
        }), [JSON.stringify(filter$1)]);
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        justifyContent: "space-between",
                        children: [
                          showSearch ? JsxRuntime.jsx(BSTableToolbar$Search, {
                                  search: search,
                                  clearSearch: clearSearch,
                                  handleSessionCreatorSelect: handleSessionCreatorSelect,
                                  sessionCreator: match$1[0]
                                }) : JsxRuntime.jsx("div", {}),
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                spacing: 1,
                                children: [
                                  showSearch ? JsxRuntime.jsx(BSTableToolbar$NewButton, {
                                          form: props.form
                                        }) : null,
                                  JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        spacing: 1,
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  children: [
                                                    JsxRuntime.jsx(IconButton, {
                                                          onClick: (function (param) {
                                                              var newOffset = offset + 50 | 0;
                                                              setOffset(function (param) {
                                                                    return newOffset;
                                                                  });
                                                              refetchPage(newOffset);
                                                            }),
                                                          variant: "plain",
                                                          disabled: offset === 0,
                                                          children: Caml_option.some(JsxRuntime.jsx(ChevronLeft, {}))
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-xs",
                                                          children: Caml_option.some(stringFromOffset(offset, totalCount))
                                                        }),
                                                    JsxRuntime.jsx(IconButton, {
                                                          onClick: (function (param) {
                                                              var newOffset = offset + 50 | 0;
                                                              setOffset(function (param) {
                                                                    return newOffset;
                                                                  });
                                                              refetchPage(newOffset);
                                                            }),
                                                          variant: "plain",
                                                          disabled: (offset + 50 | 0) >= totalCount,
                                                          children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {}))
                                                        })
                                                  ]
                                                }))
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var make = BSTableToolbar;

exports.Search = Search$1;
exports.NewButton = NewButton;
exports.make = make;
/* react Not a pure module */
