// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Joy$Util = require("util/src/Joy.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thick = require("../../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var Button = require("@mui/joy/Button").default;
var CS_Decimal$Util = require("util/src/CustomScalars/CS_Decimal.bs.js");
var BillPayInfo$Thick = require("./BillPayInfo.bs.js");
var BookingDiff$Thick = require("../../../utils/BookingDiff.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var Typography = require("@mui/joy/Typography").default;
var CurrencyInput$Thick = require("../../common/molecules/CurrencyInput.bs.js");
var FormControl = require("@mui/joy/FormControl").default;
var DialogActions = require("@mui/joy/DialogActions").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var CS_NonemptyStrings$Util = require("util/src/CustomScalars/CS_NonemptyStrings.bs.js");
var RecordPostCancellationBilling$Thick = require("../mutations/RecordPostCancellationBilling.bs.js");

function BDRecordPostCancellationBillingModal(props) {
  var onClose = props.onClose;
  var match = props.generatePreviewFromDiff(BookingDiff$Thick.emptyDiff);
  var match$1 = React.useState(function () {
        return RecordPostCancellationBilling$Thick.Utils.empty;
      });
  var setUpdate = match$1[1];
  var update = match$1[0];
  var clearAll = function (param) {
    setUpdate(function (param) {
          return RecordPostCancellationBilling$Thick.Utils.empty;
        });
  };
  var cancel = function (param) {
    clearAll();
    onClose();
  };
  var match$2 = RecordPostCancellationBilling$Thick.useMutation(props.brandFamily, match.voyageSlug, match.bookingId, match.bookingVersionNumber, cancel, props.refetchBilling);
  var recordPostCancellationBilling = match$2.recordPostCancellationBilling;
  var error = match$2.error;
  var onSubmit = function (e) {
    e.preventDefault();
    var record = RecordPostCancellationBilling$Thick.Utils.postCancellationBillingIntermToPostCancellationBilling(update);
    if (record.TAG === "Ok") {
      recordPostCancellationBilling(record._0);
      return ;
    }
    console.error("Please fill out all fields.");
  };
  var val = update.description;
  return JsxRuntime.jsxs(Dialog$Thick.make, {
              open_: props.open_,
              onClose: onClose,
              title: "Add Manual Billing Line (Canceled Booking)",
              children: [
                JsxRuntime.jsxs(DialogContent, {
                      children: [
                        JsxRuntime.jsxs(Typography, {
                              level: "title-md",
                              sx: {
                                marginBottom: "1rem"
                              },
                              children: [
                                "Add a manual billing line to this booking.",
                                JsxRuntime.jsx(Typography, {
                                      color: "danger",
                                      level: "inherit",
                                      component: "span",
                                      sx: {
                                        fontStyle: "italic"
                                      },
                                      children: "Note: this action will not be added to a draft but will immediately update booking."
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              spacing: 1,
                              children: [
                                JsxRuntime.jsxs(FormControl, {
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Subtotal Category"
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              children: "Cancellation"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(FormControl, {
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Refundability"
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              children: "None"
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsxs(FormControl, {
                                      error: val !== undefined ? Belt_Result.isError(CS_NonemptyStrings$Util.NonEmptyString.fromPrimitive(val)) : true,
                                      children: [
                                        JsxRuntime.jsx(FormLabel, {
                                              children: "Description"
                                            }),
                                        JsxRuntime.jsx(Joy$Util.Input.make, {
                                              type_: "text",
                                              placeholder: "Add a description",
                                              required: true,
                                              value: Belt_Option.getWithDefault(update.description, ""),
                                              onChange: (function (e) {
                                                  var val = e.currentTarget.value;
                                                  if (val === "") {
                                                    return setUpdate(function (param) {
                                                                return {
                                                                        lineAmount: update.lineAmount,
                                                                        description: undefined
                                                                      };
                                                              });
                                                  } else {
                                                    return setUpdate(function (param) {
                                                                return {
                                                                        lineAmount: update.lineAmount,
                                                                        description: val
                                                                      };
                                                              });
                                                  }
                                                })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(CurrencyInput$Thick.make, {
                                      label: "Net Amount",
                                      error: (function (v) {
                                          if (Belt_Result.isError(CS_Decimal$Util.Decimal10_2.fromPrimitive(BillPayInfo$Thick.Utils.padIfNoDecimal(v)))) {
                                            return true;
                                          } else {
                                            return v === "0.00";
                                          }
                                        }),
                                      onChange: (function (amt) {
                                          setUpdate(function (param) {
                                                return {
                                                        lineAmount: amt,
                                                        description: update.description
                                                      };
                                              });
                                        }),
                                      value: Belt_Option.getWithDefault(update.lineAmount, "0.00")
                                    }),
                                error !== undefined ? JsxRuntime.jsx(Typography, {
                                        color: "danger",
                                        children: Caml_option.some(error)
                                      }) : null
                              ]
                            })
                      ],
                      sx: {
                        maxWidth: "500px"
                      }
                    }),
                JsxRuntime.jsx(DialogActions, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: {
                                  xs: "column-reverse",
                                  sm: "row"
                                },
                                component: "form",
                                justifyContent: "space-between",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                onSubmit: onSubmit,
                                children: [
                                  JsxRuntime.jsx(Button, {
                                        onClick: cancel,
                                        variant: "outlined",
                                        color: "neutral",
                                        children: "Cancel"
                                      }),
                                  JsxRuntime.jsx(Button, {
                                        loading: match$2.isMutating,
                                        type: "submit",
                                        disabled: Belt_Result.isError(RecordPostCancellationBilling$Thick.Utils.postCancellationBillingIntermToPostCancellationBilling(update)),
                                        children: "Record Billing Line"
                                      })
                                ]
                              }))
                    })
              ]
            });
}

var make = BDRecordPostCancellationBillingModal;

exports.make = make;
/* react Not a pure module */
